import React, { useRef, useState, useEffect } from "react";
import logo from "../../images/logo.png";
import logo2 from "../../images/SkillurLogoIcon.png";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { MenuItems, MenuItems2 } from "./MenuItems";
import Dropdown from "./Dropdown";
import profileDP from "./../../images/placeholderdp.jpg";
import Notifications from "./Notifications";
import "./Notifications.css";
import Axios from "axios";
import { addTokenHeader } from '../../utility/tokenUtility'

export default function Navbar(props) {
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [click, setClick] = useState(false);
  const navRef = useRef();
  const img = localStorage.getItem("img");
  const first_login = localStorage.getItem("fLogin");
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationContainerOpen, setNotificationContainerOpen] =useState(false);
  const [unreadCount, setUnreadCount] = useState(localStorage.getItem("unreadCount") || 0);
  const api = process.env.REACT_APP_API_URL;
  const user_id = localStorage.getItem("user_id");

  //// call drop dpwn button tittle, path, class name
  const items = MenuItems;
  const items2 = MenuItems2;

  ///function for mobile navbar
  const handelclick = () => {
    return setClick((prevSetClick) => !prevSetClick);
  };

  const closeMobileMenu = () => setClick(false);

  //**************Notification************** */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUnreadCount = async () => {
    try {
      
      // Make an API request to the backend to get unread count
      const response = await Axios.get(
        `${api}api/notifications/unread-count/${user_id}`,{...addTokenHeader()}
      );
      const data = response.data;
      if (data && data.unreadCount !== undefined) {
        setUnreadCount(data.unreadCount);
        // Update local storage with the new unread count
        localStorage.setItem("unreadCount", data.unreadCount);
      } else {
        console.error("Invalid data structure in API response.");
      }
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  };

  useEffect(
    () => {
      if(user_id){
        fetchUnreadCount();
      }
      
    },
    [fetchUnreadCount],[unreadCount]
  );

  //onClick function when the bell icon is clicked
  const handleClickNotifications = (event) => {
    event.stopPropagation();
    setShowNotifications(!showNotifications);
    setNotificationContainerOpen(!notificationContainerOpen);

    if (!notificationContainerOpen) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleCloseNotifications = (event) => {
      if (event.target.closest(".notification-container")) {
        return;
      }
      setShowNotifications(false);
      setNotificationContainerOpen(false);
    };

    // Add and remove 'no-scroll' class based on the notification container state
    if (notificationContainerOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    window.addEventListener("click", handleCloseNotifications);

    return () => {
      window.removeEventListener("click", handleCloseNotifications);
    };
  }, [notificationContainerOpen]);

  return (
    <>
      <div className="navbar-container">
        {/* navbar logo for mobile */}
        <div className="nav-logo-mobile">
          <Link
            to={
              first_login === "true" ? "/dashboard/landingpage" : "/dashboard"
            }
            onClick={closeMobileMenu}
          >
            <img src={logo2} alt="logo" />
          </Link>
        </div>
        <div className={click ? "nav-head active-nav" : "nav-head"}>
          {/* navbar logo for desktop */}
          <div className="nav-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <nav
            className="nav-link"
            onMouseLeave={() => setDropdown(false) & setDropdown2(false)}
          >
            <ul>
              {props.user && (
                <li>
                  <Link to="/" onClick={closeMobileMenu}>
                    Home
                  </Link>
                </li>
              )}
              <li
                onClick={() => setDropdown(!dropdown)}
                id="display-none-mobile"
              >
                About
                {dropdown && <Dropdown items={items} />}
              </li>
              <li>
                <Link to="/features" onClick={closeMobileMenu}>
                  Competence
                </Link>
              </li>
              <li id="display-none-desktop">
                <Link to="/sustainability" onClick={closeMobileMenu}>
                  Sustainability
                </Link>
              </li>
              <li id="display-none-desktop">
                <Link to="/ourvalues" onClick={closeMobileMenu}>
                  Our values
                </Link>
              </li>
              <li id="display-none-desktop">
                <Link to="/about" onClick={closeMobileMenu}>
                  About us
                </Link>
              </li>
              <li id="display-none-desktop">
                <Link to="/privacypolicy" onClick={closeMobileMenu}>
                  Privacy policy
                </Link>
              </li>
              <li id="display-none-desktop">
                <Link to="/termsOfUse" onClick={closeMobileMenu}>
                  Terms of use
                </Link>
              </li>
              <li>
                <Link to="/pricing" onClick={closeMobileMenu}>
                  Pricing
                </Link>
              </li>
              <li
                onClick={() => setDropdown2(!dropdown2)}
                id="display-none-mobile"
              >
                Terms
                {dropdown2 && <Dropdown items={items2} />}
              </li>
            </ul>
          </nav>
          <div
            className={props.user ? "nav-login active" : "nav-login"}
            ref={navRef}
          >
            <button>
              <Link to="/loginform" onClick={closeMobileMenu}>
                Login
              </Link>
            </button>
          </div>
          {/* navbar 3 icons desktop */}
          <div
            className={props.user ? "nav-right" : "nav-right active"}
            id="display-none-mobile"
          >
            <Link to="Settings">
              <img
                src={img === "null" ? profileDP : img}
                alt=""
                className="profile"
              />
            </Link>
            <FontAwesomeIcon
              className="fontAwesome-icons"
              icon={faBell}
              onClick={handleClickNotifications}
            />
            {showNotifications && (
              <div className="notification-container">
                <Notifications />
              </div>
            )}
            {unreadCount > 0 && <span className="notification-dot"></span>}
            <Link
              to={
                first_login === "true"
                  ? "/dashboard/landingPage"
                  : "/dashboard/profile"
              }
            >
              <img src={logo2} alt="" />
            </Link>
          </div>
        </div>
        <div className="nav-head-nav-mobile">
          <div className="nav-mobile-menu" onClick={handelclick}>
            {click ? (
              <FontAwesomeIcon icon={faXmark} size="3x" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="3x" />
            )}
          </div>
          {/* navbar 3 icons mobile */}
          <div className={props.user ? "nav-right" : "nav-right active"}>
            <Link to="Settings">
              <img
                src={img === "null" ? profileDP : img}
                alt=""
                className="profile"
              />
            </Link>
            <FontAwesomeIcon
              className="fontAwesome-icons"
              icon={faBell}
              onClick={handleClickNotifications}
            />
            {showNotifications && (
              <div className="notification-container">
                <Notifications />
              </div>
            )}
            {unreadCount > 0 && <span className="notification-dot"></span>}
            <Link
              to={
                first_login === "true"
                  ? "/dashboard/landingPage"
                  : "/dashboard/profile"
              }
            >
              <img src={logo2} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
