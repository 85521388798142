import React,{useState} from "react";
import "../pages/style/Home.css";
import CircleIcon from "../images/Circle-with-icons2.png";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import cometenceImages from "../images/9btn.png";
import landingImage from "../images/landing.png";
import profileVideo from "../videos/profileVideo.mp4";
import ScrollToTop from "react-scroll-to-top";
import Modal from 'react-modal';

export default function Home() {

  const [showModalExpand, setShowModalExpand] = useState(false);
  const [showModalExpand2, setShowModalExpand2] = useState(false)


  const handleOpenExpand = () => {
    setShowModalExpand(true);
  };

  const handleCloseExpand = ()=> {
    setShowModalExpand(false)
  }

  const handleOpenExpand2 = () => {
    setShowModalExpand2(true);
  };

  const handleCloseExpand2 = ()=> {
    setShowModalExpand2(false)
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="main-header">
        <div className="main-home">
          <div className="main-left">
            <div className="main-left-text">
              <h1>
              Unlock Your Full Potential with <span>Skillur.</span>{" "}
              <br/>
              </h1>
              <p>
              Are you eager to showcase your skills and make a lasting impression on potential employers? 
              Look no further than Skillur! Our platform is a competence creation portal that empowers individuals 
              like you to craft and amplify your abilities, ensuring you stand out in a competitive job market. 
              </p><p>With Skillur, you not only develop your competence but also get the visibility you deserve. 
              Your dream career is within reach—ignite it with Skillur and let your skills shine in the professional world!
              </p>
            </div>
            <div className="main-left-btn">
              <button
                className="btn-blue"
                onClick={handleOpenExpand}
              >
                Read more
              </button>
              <Link to="/signup">
                <button className="btn-orange">Sign up</button>
              </Link>
            </div>
          </div>
          <div className="main-right">
            <img src={CircleIcon} alt="cirlceIcon" />
          </div>
        </div>
      </div>
      {/* <div className='main-section'></div> */}
      <div className="main-header" id="section1">
        <div className="main-home2">
          <div className="main-left">
            <div className="main-left-text section2">
              <h1>Make your competencies shine like you have never experienced before, and make them count! </h1>
              <p>
                Map your skills and get them organised! <br />
                <p>We have more skills than we normally know of, but we tend to forget about them. 
                  Make them visible! Either to empower yourself or to those looking for skills!</p>
              </p>
            </div>
            <div className="main-left-btn">
              <button
                className="btn-blue"
                onClick={handleOpenExpand2}
              >
                Read more
              </button>
              <Link to="/signup">
                <button className="btn-orange">Sign up</button>
              </Link> 
            </div>
          </div>
          <div className="main-right">
            <img src={cometenceImages} alt="CompetenceImage" />
          </div>
        </div>
      </div>
      <div className="landing" id="section2">
        <img src={landingImage} alt="SkillurLandingImage" />
      </div>
      <div className="section">
        <div className="section-text">
          <h1>Get your competence organised!</h1>
        </div>
        <div className="section-logo">
          <video className="setion-video" autoPlay muted loop>
            <source src={profileVideo} type="video/mp4" />
          </video>
          <Link to="/signup">
            <button className="btn-orange">Sign up</button>
          </Link>
        </div>
      </div>
      <ScrollToTop smooth={true} color="#003399" />
      <Footer />
            
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                isOpen={showModalExpand}
                onRequestClose={handleCloseExpand}
                contentLabel="ReadMore"
                className="modal-ReadMore-Main1"
                overlayClassName="overlay"
                >
                <h2>How our platform helps you get noticed?</h2>

                <p>Are you tired of your skills going unnoticed in the vast sea of talent?
                  Whether you're an aspiring artist, a budding coder, a seasoned writer, or a professional in any field, 
                  getting your skills noticed is crucial for personal and professional growth. 
                  Fortunately, there's a platform designed to help you shine and stand out from the crowd.</p>
                  
                <p>Introducing Skillur, the ultimate for destination for
                  showcasing your talents and skills to a global audience. With Skillur, you'll unlock a world of opportunities to ensure your skills are not just 
                  seen but celebrated.</p>

                  <h2>Here's how we can help you get your skills noticed:</h2>

                  <p>Getting your skills noticed has never been easier than with Skillur. 
                  Don't let your talents go unnoticed in the digital age. Join our thriving community of creators, professionals, 
                  and enthusiasts who are taking their skills to the next level.</p>

                  <p>Start your journey toward recognition and success today. 
                  Sign up for Skillur and let the world see what you're capable of. 
                  Your skills deserve to be noticed, and we're here to make it happen.</p>

                <div className="home-btnModal-close">
                <button  onClick={handleCloseExpand}>Close</button></div>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                isOpen={showModalExpand2}
                onRequestClose={handleCloseExpand2}
                contentLabel="ReadMore"
                className="modal-ReadMore-Main1"
                overlayClassName="overlay"
                >
                <p><span>Elevate Your Talent, Get Noticed! - </span>In today's fast-paced and competitive world, 
                  having exceptional skills and competences is just half the battle. 
                  You need a platform that helps you get your skills and competences noticed. Enter Skillur
                   – the ultimate solution to showcase your talents and stand out in your field</p>
                  
                  <p><span>Unleash Your Potential - </span>Skillur is more than just a platform. 
                    It’s your personal stage to shine. Whether you're a seasoned professional or just starting your journey, 
                    Skillur is designed to help you unlock your potential and get recognized for your skills.</p>

                  <p><span>The Power of Skill Showcase - </span>With Skillur, you can create a stunning and comprehensive profile that highlights your skills,
                     experience, and accomplishments. Showcase your expertise through your profile, 
                     upload your work, projects, and achievements to build a visual portfolio that tells your story. 
                     Share your best pieces and let your work speak for itself.</p>

                  <h2>Join Skillur Today!</h2>
                  <p>Don't let your skills go unnoticed any longer. Join Skillur and embark on a journey to elevate your talents, 
                    build your network, and unlock a world of opportunities. It's time to get noticed and make your mark in your skill journey.</p>
                  <p>Start your Skillur journey today and watch your skills shine brighter than ever before!</p>  

                <div className="home-btnModal-close">
                <button  onClick={handleCloseExpand2}>Close</button></div>
            </Modal>
    </div>
  );
}
