import React from "react";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "../pages/style/Pricing.css"
import { addTokenHeader } from '../utility/tokenUtility'

function PaymentMethod({planId ,title, price, validityDuration, validityName, amount, description, currency, handleCloseModal}) {

    const api = process.env.REACT_APP_API_URL;
    const stipeKeyTest = process.env.REACT_APP_STRIPE_SECRET_TEST;
    const user_id = localStorage.getItem('user_id')
    const [clientSecret, setClientSecret] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [ paymentIntentId, setPaymentIntentId] = useState("")
    const stripePromise = loadStripe(stipeKeyTest);

    
    useEffect(() => {
        // Define a flag to track whether the effect has already been run
        let isMounted = true;

        axios.post(`${api}create-payment-intent`, { title, price, amount, description, currency, user_id },{...addTokenHeader()})
            .then(async (result) => {
                if (isMounted) {
                    var { clientSecret, msg, user_info, paymentIntentID } = await result.data;
                    setClientSecret(clientSecret);
                    setUserInfo(user_info);
                    setPaymentIntentId(paymentIntentID);
                    // console.log('paymentIntentID', paymentIntentID);
                }
            });

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false;
        };
    }, []); // Empty dependency array ensures the effect runs only once on mount

////for Elements options style and secret key 
    const appearance = {
    theme: "flat",
    };
    const options = {
    clientSecret,
    appearance,
    };


return (
    <>
    <div className="PaymentMethod-box">
        {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
            <CheckoutForm 
            userInfo ={userInfo}
            planId = {planId}
            title = {title}
            price = {price}
            validityDuration = {validityDuration}
            validityName = {validityName}
            amount = {amount}
            paymentIntentId = {paymentIntentId}
            
            />
            <button className="PaymentMethod-box-close" onClick={handleCloseModal}>Cancel</button>
        </Elements>
        )}
    </div>
    </>
    );
}

export default PaymentMethod;
