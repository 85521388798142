const sustainabilityOptions = [
    { value: "No poverty", label: "No poverty" },
    { value: "Zero hunger", label: "Zero hunger" },
    {
      value: "Good health and well-being",
      label: "Good health and well-being",
    },
    { value: "Quality education", label: "Quality education" },
    { value: "Gender equality", label: "Gender equality" },
    {
      value: "Clean water and sanitation",
      label: "Clean water and sanitation",
    },
    {
      value: "Affordable and clean energy",
      label: "Affordable and clean energy",
    },
    {
      value: "Decent work and economic growth",
      label: "Decent work and economic growth",
    },
    {
      value: "Industry, innovation and infrastructure",
      label: "Industry, innovation and infrastructure",
    },
    { value: "Reduced inequalities", label: "Reduced inequalities" },
    {
      value: "Sustainable cities and comunities",
      label: "Sustainable cities and comunities",
    },
    {
      value: "Responsible consumtion and production",
      label: "Responsible consumtion and production",
    },
    { value: "Climate action", label: "Climate action" },
    { value: "Life below water", label: "Life below water" },
    { value: "Life on land", label: "Life on land" },
    {
      value: "Peace, justice and strong institutions",
      label: "Peace, justice and strong institutions",
    },
    {
      value: "Partnerships for the goals",
      label: "Partnerships for the goals",
    },
  ];
export default sustainabilityOptions;