import React from "react";
import "./MyTerms.css";
import { useState, useEffect } from "react";
import Axios from "axios";
import Select from "react-select";
import TagsInput from "./TagsInput";
import countriesOptions from "../../../data/countries";
import currencyOptions from "../../../data/currencyList";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import HelpButton from "../../HelpButton/HelpButton";
import { addTokenHeader } from "../../../utility/tokenUtility";

const MyTerms = () => {
  ////set state for all values

  const [myTermsQuestions, setMyTermsQuestions] = useState([]);
  const [mytermsLandingPageQuestions, setMyTermsLandingPageQuestions] =
    useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [show, setShow] = useState(startDate ? true : false);
  const [currency, setCurrency] = useState("");
  const [jobType, setJobType] = useState({
    Employment: false,
    Assignment: false,
    Interim: false,
    Internship: false,
    Volunteer: false,
    None: false,
  });
  const [commuteRemote, setCommuteRemote] = useState({
    Commute_daily: false,
    Remote_work: false,
    Commute_weekly: false,
    Relocate: false,
  });
  const [paymentSalary, setPaymentSalary] = useState({
    Hourly_wage: false,
    Weekly_salary: false,
    Monthly_salary: false,
    Invoice_hour: false,
    Invoice_week: false,
    Invoice_month: false,
  });
  const [amount, setAmount] = useState({
    Amount_hourly_wage: "",
    Amount_weekly_salary: "",
    Amount_monthly_salary: "",
    Amount_invoice_hour: "",
    Amount_invoice_week: "",
    Amount_invoice_month: "",
  });
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorFrom, setErrorFrom] = useState("");
  const [errorTo, setErrorTo] = useState("");
  const [errorCheckbox, setErrorCheckbox] = useState("");
  const location = useLocation();
  const api = process.env.REACT_APP_API_URL;
  const isLoginFirst = location.state?.first_login;
  let navigate = useNavigate();

  ///////function take value from user (checkbox , input )/////////////
  const handelCheckJobType = async (event) => {
    const { name, checked } = event.target;
    setJobType({ ...jobType, [name]: checked });
    setErrorCheckbox("");
  };
  const handelCheckCommuteRemote = async (event) => {
    const { name, checked } = event.target;
    setCommuteRemote({ ...commuteRemote, [name]: checked });
  };

  // for Countreis
  const handleCountriesChange = (selectedOptions) => {
    setCountries(selectedOptions);
  };

  /////////For Date ////////////////////
  function handleStartDateChange(event) {
    const today = new Date();
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);

    // Check if selectedStartDate  is earlier than today
    if (new Date(selectedStartDate) < today) {
      // alert('Earliest start date should be after today"');
      setErrorStartDate("Earliest start date should be after today");
      setStartDate("");
      setShow(false);
      setToDate("");
    } else if (selectedStartDate == "") {
      setShow(false);
    } else {
      setShow(true);
    }
    setFromDate("");
    setToDate("");
  }

  function handleFromDateChange(event) {
    const selectedFromDate = event.target.value;
    if (selectedFromDate) {
      if (selectedFromDate < startDate) {
        setErrorFrom(
          'Please select a "From" date that is after the "Earliest start date'
        );
        setFromDate("");
      } else {
        setFromDate(selectedFromDate);
        setErrorFrom("");
      }
    }
  }

  function handleToDateChange(event) {
    const selectedToDate = event.target.value;
    // Check if selectedToDate is earlier than fromDate
    if (!fromDate) {
      setErrorTo('Please select a "From" date first');
      setToDate("");
    } else {
      if (selectedToDate < fromDate) {
        setErrorTo('Please select a "To" date that is after the "From" date');
        setToDate("");
      } else {
        setToDate(selectedToDate);
        setErrorTo("");
      }
    }
  }
  ///for salary and amount
  const handelCheckPaymentSalary = async (event) => {
    const { name, checked } = event.target;
    setPaymentSalary({ ...paymentSalary, [name]: checked });
  };

  const handleChangeAmount = async (event) => {
    setAmount({
      ...amount,
      [event.target.name]: event.target.value,
    });
  };

  // for Currency
  function handleCurrencyChange(selectedOption) {
    setCurrency(selectedOption.value);
  }

  ///////////Create data object for send to database ///////////////

  // Create form data object
  const selectedJobTypes = Object.keys(jobType).filter((key) => jobType[key]);
  const selectedCommuteRemote = Object.keys(commuteRemote).filter(
    (key) => commuteRemote[key]
  );
  const selectCountry = countries.map((option) => option.value);
  const amountPaymentOption = Object.entries(paymentSalary)
    // eslint-disable-next-line array-callback-return
    .map(([key, value]) => {
      if (value) {
        const amountKey = `Amount_${key.toLowerCase()}`;
        return [key, amount[amountKey]]; // return an array of [key, value]
      }
    })
    .filter((item) => item !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value; // accumulate each [key, value] pair into the object
      return obj;
    }, {});

  /*note:
The selectedJobTypes variable uses the Object.keys method to get all the keys (checkbox names) of
the jobType object where the value is true. This creates an array of selected checkbox names, 
which are then added to a new object called data.   
In the code above, the Object.entries() method is used to convert the amount object to an array of 
key-value pairs, which is then filtered using the Array.prototype.filter() method to remove any 
key-value pairs where the value is an empty string. Finally, the Object.fromEntries() 
method is used to convert the filtered array of key-value pairs back into an object. */

  //////// functions for clear value in amount and salary options
  useEffect(() => {
    if (!jobType.Employment && !jobType.Interim) {
      setPaymentSalary((prevState) => ({
        ...prevState,
        Hourly_wage: false,
        Weekly_salary: false,
        Monthly_salary: false,
      }));
    }
    if (!jobType.Assignment && !jobType.Interim) {
      setPaymentSalary((prevState) => ({
        ...prevState,
        Invoice_hour: false,
        Invoice_week: false,
        Invoice_month: false,
      }));
      if (!jobType.Employment && !jobType.Interim && !jobType.Assignment) {
        setCurrency("");
      }
    }
  }, [jobType]);

  //// for functions amout when the paymen checkbox fasle
  useEffect(() => {
    if (!paymentSalary.Hourly_wage) {
      setAmount((prevState) => ({ ...prevState, Amount_hourly_wage: "" }));
    }
    if (!paymentSalary.Weekly_salary) {
      setAmount((prevState) => ({ ...prevState, Amount_weekly_salary: "" }));
    }
    if (!paymentSalary.Monthly_salary) {
      setAmount((prevState) => ({ ...prevState, Amount_monthly_salary: "" }));
    }
    if (!paymentSalary.Invoice_hour) {
      setAmount((prevState) => ({ ...prevState, Amount_invoice_hour: "" }));
    }
    if (!paymentSalary.Invoice_week) {
      setAmount((prevState) => ({ ...prevState, Amount_invoice_week: "" }));
    }
    if (!paymentSalary.Invoice_month) {
      setAmount((prevState) => ({ ...prevState, Amount_invoice_month: "" }));
    }
  }, [paymentSalary]);

  ///collect data for send to API
  const user_id = localStorage.getItem("user_id");
  const formData = {
    startDate: startDate || startDate !== "" ? startDate : null,
    fromDate: fromDate || fromDate !== "" ? fromDate : null,
    toDate: toDate || toDate !== "" ? toDate : null,
    currency: currency,
    user_id,
    jobType: selectedJobTypes,
    commuteRemote: selectedCommuteRemote,
    countries: selectCountry,
    regions,
    paymentOptions: amountPaymentOption,
  };

  ///////send data to backend in API ////////////
  const sendData = async (event) => {
    event.preventDefault();

    const isAtLeastOneChecked = Object.values(jobType).some(
      (value) => value === true
    );
    if (!isAtLeastOneChecked) {
      setErrorCheckbox(
        "Please select at least one Employment/Assignment checkbox"
      );
      return;
    } else {
      Axios.post(`${api}api/myterms`, formData, {
        ...addTokenHeader(),
      })
        .then(alert("Updating the changes"))
        .catch((err) => console.log(err));
      setErrorCheckbox("");
      if (isLoginFirst === 3) {
        Axios.put(
          `${api}api/updateFirstLogin`,
          {
            user_id: user_id,
            landing_page: "myTerms",
          },
          { ...addTokenHeader() }
        ).then((response) => {
          // console.log("First login updated");
        });
        navigate("/dashboard/landingPage");
      }
    }
  };

  /////// take data form backend /////////////
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    Axios.get(`${api}api/myterms/${user_id}`, {
      ...addTokenHeader(),
    })
      .then((response) => {
        const data = response.data;

        ///for jobtype
        const jobTypeArray = data.job_type;
        const updatedJobType = { ...jobType };
        jobTypeArray.forEach((type) => (updatedJobType[type] = true));
        setJobType(updatedJobType);

        ///for CommuteRemote
        const commuteRemoteArray = data.commute_remote;
        const updatedCommuteRemote = { ...commuteRemote };
        commuteRemoteArray.forEach(
          (type) => (updatedCommuteRemote[type] = true)
        );
        setCommuteRemote(updatedCommuteRemote);

        ///for countries & Regions
        const optionsCountries = data.abroad_countries.map((option) => ({
          label: option,
          value: option,
        }));
        setCountries(optionsCountries);
        const optionsRegions = data.abroad_regions;
        setRegions(optionsRegions);

        ///for date
        const localStartDate = data.start_date
          ? moment
              .utc(data.start_date)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "";
        const localFromDate = data.unavailable_from_date
          ? moment
              .utc(data.unavailable_from_date)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "";
        const localTotDate = data.unavailable_to_date
          ? moment
              .utc(data.unavailable_to_date)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "";
        setStartDate(localStartDate ? localStartDate.slice(0, 10) : "");
        setShow(data.start_date ? true : false);
        setFromDate(localFromDate ? localFromDate.slice(0, 10) : "");
        setToDate(localTotDate ? localTotDate.slice(0, 10) : "");

        ///for payment
        const paymentSalaryArray = data.salary_options;
        const updatedPaymentSalary = { ...paymentSalary };
        for (const [key] of Object.entries(paymentSalaryArray)) {
          updatedPaymentSalary[key] = true;
        }
        setPaymentSalary(updatedPaymentSalary);
        const updatedAmount = { ...amount };
        for (const [key, value] of Object.entries(paymentSalaryArray)) {
          const amountKey = `Amount_${key.toLowerCase()}`;
          updatedAmount[amountKey] = value;
        }
        setAmount(updatedAmount);

        /// for Currency
        setCurrency(data.currency);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    let help_title;
    if (isLoginFirst === 3) {
      help_title = "landing page-myterms";
      Axios.get(`${api}api/questions/${help_title}`, { ...addTokenHeader() })
        .then((response) => {
          setMyTermsLandingPageQuestions(response.data);
        })

        .catch((error) => {
          console.error(error);
        });
    } else {
      help_title = "myterms";
      Axios.get(`${api}api/questions/${help_title}`, { ...addTokenHeader() })
        .then((response) => {
          setMyTermsQuestions(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="myterms">
          <h2>My Terms</h2>
        </div>
        <form
          className={
            jobType.None ? "form-my-terms active-none" : "form-my-terms"
          }
        >
          <div className="myterms-field">
            <label className="myterms-label">Employment/Assignment</label>
            <div className="myterms-checkbox">
              {Object.keys(jobType).map((key) => {
                if (key === "None") {
                  return (
                    <div key={key} id="emAs-input-checkbox">
                      <input
                        type="checkbox"
                        name={key}
                        value=""
                        checked={jobType[key]}
                        onChange={handelCheckJobType}
                        required
                      />
                      <label htmlFor={key}>None</label>
                    </div>
                  );
                } else {
                  return (
                    <div key={key} id="emAs-input-checkbox">
                      <input
                        disabled={jobType.None ? true : false}
                        type="checkbox"
                        name={key}
                        value={key}
                        checked={jobType[key]}
                        onChange={handelCheckJobType}
                      />
                      <label htmlFor={key}>{key}</label>
                    </div>
                  );
                }
              })}
              <p className="error-send-data">{errorCheckbox}</p>
            </div>
          </div>
          <div className="myterms-country">
            <div className="myterms-input-checkbox">
              <label className="myterms-label">Work abroad - Countries</label>

              <div className="dropdown-counries">
                <Select
                  dropdownPosition="auto"
                  searchable="true"
                  options={countriesOptions}
                  value={countries}
                  isMulti
                  isDisabled={jobType.None ? true : false}
                  onChange={handleCountriesChange}
                />
              </div>
            </div>
            <div className="myterms-field">
              <label className="myterms-label">Work abroad - Regions</label>
              <div className="region">
                <TagsInput
                  value={regions}
                  onChange={setRegions}
                  name="regions"
                  placeHolder="Add Regions"
                  disabled={jobType.None ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="myterms-field">
            <label className="myterms-label">Commute /Remote options</label>
            <div className="myterms-checkbox">
              {Object.keys(commuteRemote).map((key) => {
                const label = key.replace(/_([a-z])/g, " $1");
                return (
                  <div key={key} id="commute-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type="checkbox"
                      name={key}
                      value={key}
                      checked={commuteRemote[key]}
                      onChange={handelCheckCommuteRemote}
                    />
                    <label htmlFor={key}>{label}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="myterms-field">
            <div className="myterms-earliest-date">
              <label className="myterms-label">Earliest start date</label>
              <input
                disabled={jobType.None ? true : false}
                type="date"
                value={startDate}
                name="startDate"
                className="myterms-date"
                onChange={handleStartDateChange}
              />
              {errorStartDate && !startDate && (
                <p id="errorMsg-earliest-date">{errorStartDate}</p>
              )}
            </div>
          </div>
          {show && (
            <div className="myterms-field">
              <label className="myterms-label">
                After the earliest start date - enter a period if you are
                occupied
              </label>
              <div className="myterms-date-after">
                <div id="myterms-date-from">
                  <label>From</label>
                  <input
                    disabled={jobType.None ? true : false}
                    type="date"
                    value={fromDate}
                    name="fromDate"
                    className="myterms-date"
                    onChange={handleFromDateChange}
                  />
                  {errorFrom && <p id="errorMsg-date-from">{errorFrom}</p>}
                </div>
                <div id="myterms-date-from">
                  <label>To</label>
                  <input
                    disabled={jobType.None ? true : false}
                    type="date"
                    value={toDate}
                    name="toDate"
                    className="myterms-date"
                    onChange={handleToDateChange}
                  />
                  {errorTo && <p id="errorMsg-date-to">{errorTo}</p>}
                </div>
              </div>
            </div>
          )}
          {(jobType.Employment || jobType.Assignment || jobType.Interim) && (
            <div className="myterms-field">
              <label className="myterms-label">Payment / Salary options</label>
              <div className="myterms-checkbox">
                {(jobType.Employment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Hourly_wage"
                      value=""
                      checked={paymentSalary.Hourly_wage}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Hourly_wage">Hourly wage</label>
                  </div>
                )}
                {(jobType.Employment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Weekly_salary"
                      value=""
                      checked={paymentSalary.Weekly_salary}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Weekly_salary">Weekly salary</label>
                  </div>
                )}
                {(jobType.Employment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Monthly_salary"
                      value=""
                      checked={paymentSalary.Monthly_salary}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Monthly_salary">Monthly salary</label>
                  </div>
                )}
                {(jobType.Assignment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Invoice_hour"
                      value=""
                      checked={paymentSalary.Invoice_hour}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Invoice_hour">Invoice/hour</label>
                  </div>
                )}
                {(jobType.Assignment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Invoice_week"
                      value=""
                      checked={paymentSalary.Invoice_week}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Invoice_week">Invoice/week</label>
                  </div>
                )}
                {(jobType.Assignment || jobType.Interim) && (
                  <div id="payment-input-checkbox">
                    <input
                      disabled={jobType.None ? true : false}
                      type={"checkbox"}
                      name="Invoice_month"
                      value=""
                      checked={paymentSalary.Invoice_month}
                      onChange={handelCheckPaymentSalary}
                    />
                    <label htmlFor="Invoice_month">Invoice/month</label>
                  </div>
                )}
              </div>
            </div>
          )}
          {(jobType.Employment || jobType.Assignment || jobType.Interim) && (
            <div className="myterms-currency">
              <div className="myterms-field">
                <label className="myterms-label">Currency</label>
                <Select
                  className="Currency"
                  options={currencyOptions}
                  isDisabled={jobType.None ? true : false}
                  value={{ value: `${currency}`, label: `${currency}` }}
                  onChange={handleCurrencyChange}
                  menuPlacement="top"
                />
              </div>
              <div className="myterms-amount-show">
                {Object.entries(paymentSalary).map(([key, value]) => {
                  const label = key.replace(/_([a-z])/g, " $1");
                  if (value) {
                    const amountName = `Amount_${key.toLowerCase()}`;
                    return (
                      <div className="myterms-amount" key={key}>
                        <label className="myterms-label">{`Amount-${label}`}</label>
                        <input
                          disabled={jobType.None ? true : false}
                          className="myterms-amount-input"
                          type="number"
                          name={amountName}
                          value={amount[amountName]}
                          onChange={handleChangeAmount}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}
          <div className="savechanges-container">
            <button className="SaveChanges" onClick={sendData}>
              Save Changes
            </button>
          </div>
        </form>
        <div>
          <HelpButton
            questions={
              isLoginFirst === 3
                ? mytermsLandingPageQuestions
                : myTermsQuestions
            }
          />
        </div>
      </div>
    </>
  );
};

export default MyTerms;
