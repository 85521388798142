import React from "react";
import "./DeletePhoto.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

function DeletePhoto() {
  return (
    <div className="popup-background-DeletePhoto">
      <div className="popup-DeletePhoto">
        <div className="popup-inner-DeletePhoto">
          <div>
            <h2>Delete picture?</h2>
          </div>
          <div className="Icon">
            <FontAwesomeIcon icon={faTrashCan} className="LogoutIcon" />
          </div>
          <div>
            <p className="helpText">
              {" "}
              Are you sure you want to delete your profile picture?
            </p>
          </div>
          <Link to="/Settings">
            <button>Delete</button>
          </Link>
          <Link to="/Settings">
            <p>Cancel</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DeletePhoto;
