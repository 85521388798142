import React from 'react'
import PersonDataDetails from '../../PersonalDataComponents/PersonDataDetails';
import { useLocation } from "react-router-dom";

function PersonalData() {
    
    const location = useLocation();
    const isLoginFirst = location.state?.first_login;
    
    return (
    <div className='main-container'>
        <div className='container-text'>
            <h1>Personal Data </h1>
            <PersonDataDetails isLoginFirst={isLoginFirst}/>
            </div>
        </div>
    )
}

export default PersonalData