import { useEffect } from 'react';

//function to handle browser 'Back' button
export function useCustomEffect ()  {
    useEffect(() => {
      // Add a new state to the browser history when the component mounts
      window.history.pushState(null, null, window.location.pathname);
  
      // Add a listener to the popstate event to prevent the user from navigating back
      window.addEventListener('popstate', handlePopstate);
  
      // Clean up the listener when the component unmounts
      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }, []);
  
    function handlePopstate(e) {
      // Prevent the default behavior of the browser's back button
      e.preventDefault();
  
      // Add a new state to the browser history to prevent the user from navigating back
      window.history.pushState(null, null, window.location.pathname);
    }
  
   }