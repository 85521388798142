import React,{useState} from "react";
import "./Account.css";
import "./DeactivateAccount.css";
import { Link, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { addTokenHeader } from '../../utility/tokenUtility'
import Axios from "axios";


function Account({authenticate}) {

  const [checkPassword, setCheckPassword] = useState("");
  const [errCheckPassword, setErrCheckPassword] = useState("");
  const [activeAcount, setActiveAcount] = useState(false);
  const [firstName,seFirstName] = useState("");
  const [email, setEmail] = useState('')

  const api = process.env.REACT_APP_API_URL;
  const user_id = localStorage.getItem("user_id")
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
    authenticate();
  };


  const handleCheckPassword = (e) => {
    e.preventDefault();

    Axios.put(`${api}api/deactivepassword`, {checkPassword, user_id},{...addTokenHeader()}).then( (response) => { 
      
      seFirstName(response.data.first_name)
      setEmail(response.data.email)
      setErrCheckPassword(response.data.message)
      if(response.data.message === "The password is correct") {

        setActiveAcount(true)
        setErrCheckPassword("")
      }
      else {
        setErrCheckPassword(response.data.message)
      }
    })
  }

  const handleDeactiveAcount = (e) => {
    e.preventDefault();
    
    Axios.put(`${api}api/deactiveacount`, {user_id , firstName, email },{...addTokenHeader()}).then((response) => {
      
      if(response) {
        logout();
      }
    });
  };


  return (
    <div className="popup-background-Account">
      { !activeAcount &&
      <div className="popup-Account">
        <div className="popup-inner-Account">
          <div>
            <h2>
              <FontAwesomeIcon icon={faCircleInfo} /> Deactivate account
            </h2>
            <div>
              <label className="label">
                To continue please enter your password
              </label>
            </div>
            <form action=""  onSubmit={handleCheckPassword} >
              <div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  placeholder="Password"
                  value={checkPassword}
                  onChange={(e) => setCheckPassword(e.target.value)}
                ></input>
                <p id="error-account">{errCheckPassword}</p>
              </div>
              <div className="ButtonsRight">
                <Link to="/Settings">
                  <p>Cancel</p>
                </Link>
                <button type="submit" value="submit">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>}
      {activeAcount && 
      <div className="popup-DeactivateAccount">
      <div className="popup-inner-DeactivateAccount">
        <div>
          <h2>
            <FontAwesomeIcon icon={faCircleInfo} /> Deactivate account
          </h2>
          <div>
            <div className="text">
              <p className="boldText">
                Deactivating your account is temporary.
              </p>{" "}
              Your account can be restored within the next 6 months. To
              restore it you will have to login to your account at Skillur. If
              you don’t login during that time your account will be deleted.
            </div>
          </div>
          <div className="ButtonsRight">
            <Link to="/Settings">
              <p className="CancelButton">Cancel</p>
            </Link>
            <button  onClick={handleDeactiveAcount}>
              Deactivate
            </button>
          </div>
        </div>
      </div>
    </div>
      }
    </div>
  );
}

export default Account;
