import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCircleXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import "./Video.css";
import HelpButton from "../../HelpButton/HelpButton";
import Modal from 'react-modal';
import { fetchMyPlanUserInfo } from '../../MyPlanComponents/fetchMyPlanUserInfoHooks'
import { Link } from "react-router-dom";
import { addTokenHeader } from '../../../utility/tokenUtility'


const VideoSection = () => {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [publicIdToDelete, setPublicIdToDelete] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState("");
  const [videoCount, setVideoCount] = useState(0);
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [myPlanInfo, setMyPlanInfo] = useState({})
  const maxFilesize = 100.0; //5MB = 51,200 Kilobytes (KB) , 1mb=1024KB
  const maxVideos = 2; // Maximum number of videos allowed
  const api = process.env.REACT_APP_API_URL;
  const user_id = localStorage.getItem("user_id");
  const [videoQuestions, setvideoQuestions] = useState([]);

  //Getting data from backend
  useEffect(() => {
    const fetchVideoCount = async () => {
      try {
        const [videosResponse, countResponse] = await Promise.all([
          axios.get(`${api}api/videoinfo/${user_id}`, {...addTokenHeader()}),
          axios.get(`${api}api/videoinfo/${user_id}/count`, {...addTokenHeader()}),
        ]);

        const videosData = videosResponse.data.map(
          ({ user_id, public_id, document_url }) => ({
            user_id,
            public_id,
            url: document_url,
          })
        );
        const count = countResponse.data.count;
        setUploadedVideos(videosData);
        setVideoCount(count);
      } catch (error) {
        console.error("Error fetching video information and count", error);
      }
    };
    fetchVideoCount();
  }, []);

  //set the timer for display user alert & error messages

  const showMessageForSeconds = (text, seconds) => {
    setMessage(text);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, seconds * 1000);
  };

  //Handling the darg and drop and select file functionality


  const handleDrop = async (acceptedFiles) => {

    if (myPlanInfo.active === 1) {

    if (uploadedVideos.length >= maxVideos) {
      const dropzone = document.querySelector(".drag-area");
      dropzone.style.pointerEvents = "none";
      dropzone.style.opacity = 0.5;
      showMessageForSeconds(
        `You have reached the maximum number of videos: ${maxVideos}`,
        10
      );
      return;
    }
    const file = acceptedFiles[0];
    const fileExtension = file.name.split(".").pop();
    // Define an array of allowed video extensions
    const allowedVideoExtensions = ["mp4", "mov"];
    if (!allowedVideoExtensions.includes(fileExtension.toLowerCase())) {
      showMessageForSeconds("Please select a [.mp4/.mov] video file ", 5);
      return;
    }
    const selectedFile = file.size / ( 1024 * 1024);
    if (selectedFile > maxFilesize) {
      showMessageForSeconds(
        `File size is greater than 100 MB. The selected file size is: ${selectedFile}`,
        10
      );
    } else {
      const formData = new FormData();
      formData.append("video", acceptedFiles[0]);
      try {
        /// insert video in cloudinary
        const response = await axios.post(`${api}upload-video`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadInProgress(percentCompleted);
          },
        });

        const { url, public_id } = response.data;
        showMessageForSeconds("Video uploaded successfully", 5);
        setUploadedVideos([...uploadedVideos, { url, public_id, user_id }]);
        setUploadInProgress(false);

        //// save video url in database
        await axios.post(`${api}save-video-info`, { url, public_id, user_id },{...addTokenHeader()});
      } catch (error) {
        console.log(error);
        setUploadInProgress(false);
      }
    }
  } else {
    setShowModalPlan(true)
      const dropzone = document.querySelector(".drag-area");
      dropzone.style.pointerEvents = "none";
      dropzone.style.opacity = 0.5;
    
  }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  const handleDeleteModel = (publicId) => {
    setShowDeleteModal(true);
    setPublicIdToDelete(publicId);
  };

  const handleDelete = (publicId) => {
    //// delete video from cloudinary
    axios
      .post(`${api}delete-video/${publicId}`)
      .then((response) => {
        setUploadedVideos(
          uploadedVideos.filter((video) => video.public_id !== publicId)
        );

        //// delete video from Database
        axios
          .post(`${api}delete-video-info`, { public_id: publicId }, {...addTokenHeader()})
          .then(() => {
            setShowDeleteModal(false);
            showMessageForSeconds("Video deleted successfully", 5);

            //enable drag and drop area if the video count less than 2
            if (
              uploadedVideos.filter((video) => video.public_id !== publicId)
                .length < 2
            ) {
              const dropzone = document.querySelector(".drag-area");
              dropzone.style.pointerEvents = "auto";
              dropzone.style.opacity = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
 /** Getting helpbutton data from backend **/
  useEffect(() => {
    const help_title = "videos";
    axios
      .get(`${api}api/questions/${help_title}`,{...addTokenHeader()})
      .then((response) => {
        setvideoQuestions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    // Call the API endpoint
    fetchMyPlanUserInfo(user_id)
    .then(data => {
        setMyPlanInfo(data.myPlanData ? data.myPlanData : "" )

})
    .catch(error => {
        // Handle the error
        console.error(error);
    });
},[] );

const handleCloseModal = ()=> {
  setShowModalPlan(false)
}

const handlepModalPlanActive = ()=> {
  if (myPlanInfo.active !== 1) {
    setShowModalPlan(true)
  }
}


  return (
    <div className="main-container">
      <div className="video-heading">
        <h2>Video</h2>
        <div id="sub-heading">
          <h3 id="video-h3">Upload video</h3>
        </div>

        <form className="form-videoSection">
          {/* *******The Preview Video Section functionality will be available in the Future  *********
              <div>
                  <h3>Preview Video</h3>
                  <video className="video" controls>
                    <source src={skillur_intro} type="video/mp4" />
                      </video> </div> 
            ********************************************************************************************/}


        <div className="drag-area" {...getRootProps()}>
            <input type="file" accept=".mp4, .mov"  {...getInputProps()} />
            <FontAwesomeIcon
              icon={faVideo}
              size="lg"
              style={{
                color: "#8C8888",
                cursor: "pointer",
              }}
            />
            <p>
              OR <br /> Drag & Drop here
            </p>
          </div>

          <div className="user-info-message">
            <p id="max-file-size-message">
              <span>*</span> Maximum file size:100MB/file
            </p>

            {/**Progress Bar */}
            {uploadInProgress ? (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadInProgress}%` }}
                  aria-valuenow={uploadInProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            ) : null}

            {/**User alert and error messages */}
            <>
              {showMessage && (
                <div id="user-alert-messages-video">{message}</div>
              )}
            </>
          </div>

          <h3 id="video-h3">My videos</h3>
          <div className="video-section" onClick={handlepModalPlanActive}>
            {uploadedVideos.length > 0 ? (
              <div className="dummy-video-section">
                {uploadedVideos.map((video) => (
                  <div key={video.public_id}>
                    <video className= "video-play-section" id={(myPlanInfo.active !== 1) ? "noplan" : ""} controls >
                      <source src={video.url} />
                    </video>
                    <FontAwesomeIcon
                      id="delete-icon"
                      icon={faTrash}
                      onClick={() => handleDeleteModel(video.public_id)}
                    />
                    {showDeleteModal && (
                      <div className="modal">
                        <div className="modal-content">
                          <span className="close">
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              size="xl"
                              onClick={() => {
                                setShowDeleteModal(false);
                                setPublicIdToDelete(null);
                              }}
                            />
                          </span>
                          <h4>Delete</h4>
                          <p>Are you sure you want to delete this video?</p>
                          <div className="button-group">
                            <button
                              onClick={() => {
                                handleDelete(publicIdToDelete);
                                setShowDeleteModal(false);
                                setPublicIdToDelete(null);
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="cancel"
                              onClick={() => {
                                setShowDeleteModal(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {uploadedVideos.length === 1 && (
                  <video className="right-dummy-section" controls></video>
                )}
              </div>
            ) : (
              <div className="dummy-video-section">
                <video className="left-dummy-section" controls></video>
                <video className="right-dummy-section" controls></video>
              </div>
            )}
          </div>

          <div>
            <HelpButton questions={videoQuestions} />
          </div>
        </form>
      </div>
        <Modal
                isOpen={showModalPlan}
                onRequestClose={handleCloseModal}
                contentLabel="Video Upload"
                className="modal-video-view"
                overlayClassName="overlay"
                >
                <h2>This is a paid feature. Please explore the plans.</h2>
                <div className="video-btn-modal">
                <Link to="/updateplan"><button  className="video-btn-plan">Explore plans</button></Link>
                <button  className="video-btn-close" onClick={handleCloseModal}>Close</button></div>
        </Modal>
    </div>
  );
};

export default VideoSection;
