import React from 'react'

function Validation() {
  return (
    <div className='main-container'>
      <div className='container-text'>
          <h1> Validations</h1>
          <p>The Validation section will be available later. We will announce more about this feature in the coming months.</p>
        </div>
      </div>
  )
}

export default Validation