import React, { useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { addTokenHeader } from '../../../utility/tokenUtility'

const PDFGeneration = () => {
  const [pdfUrl, setPdfUrl] = useState('');

  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);
  const user_id = decoded["user_id"];
  // console.log(user_id)

  const api = process.env.REACT_APP_API_URL;


  const handleViewPDF = async () => {
    try {
      // Fetch the generated PDF from the backend
      const response = await axios.get(`${api}api/pdf`, {
        params: { user_id },
        ...addTokenHeader(),
        responseType: 'blob', // Set the response type to 'blob' to receive binary data
      });

      // Create a URL object from the binary data and set it to the state
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      setPdfUrl(url);
       // Open the PDF in a new tab
       window.open(url, '_blank');
       
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const handleDownloadPDF = async () => {
    try {

      //const user_id = localStorage.getItem('user_id');
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      const user_id = decoded["user_id"];

      // Fetch the generated PDF from the backend
      const response = await axios.get(`${api}api/pdf`, {
        params: { user_id },
        ...addTokenHeader(),
        responseType: 'blob', // Set the response type to 'blob' to receive binary data
      });


      // axios.get(`${api}api/pdf`, {
      //   params: {
      //     user_id: user_id
      //   },
      // }).then((response) => {
      //   console.log(response);
      // });

      // Create a URL object from the binary data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'generated.pdf'); // Set the download attribute

      // Append the link element to the document body
      document.body.appendChild(link);

      // Simulate a click on the link element to trigger the download
      link.click();

      // Clean up the link element and the URL object
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }

  };

  return (
    <div>
      <button className='pdfGenerateButton'onClick={handleViewPDF}>View PDF</button>

    </div>
  )
}

export default PDFGeneration;