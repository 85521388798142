import React from 'react'

function Personality() {
  return (
    <div className='main-container'>
      <div className='container-text'>
          <h1>Personality </h1>
          <p>The Personality section will be available in the coming months. It will be a tool to analyze your personality.</p>
        </div>
      
      </div>
  )
}

export default Personality