import React, { useState, useMemo, useEffect,useContext } from "react";
import "../pages/style/Intelligencereport.css";
import countryList from "react-select-country-list";
import Select from "react-select";
import Axios from "axios";
import { useNavigate } from "react-router";
import validation from "../formValidation/intelligencereportValidation";
import sustainabilityOptions from "../data/Sustainability";
import { NotificationContext } from "../components/Navbar/NotificationContext";
import { addTokenHeader } from '../utility/tokenUtility'

function IntelligenceReport() {
  let navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const api = process.env.REACT_APP_API_URL;
  const [errors, setErrors] = useState({});
  const [showhide, setShowhide] = useState("");
  const [reportAboutCharCount, setReportAboutCharCount] = useState(0);
  const [suggestionsCharCount, setSuggestionsCharCount] = useState(0);
  const [reasonCharCount, setReasonCharCount] = useState(0);
  const [reasonError, setReasonError] = useState("reson is requried");
  const {addNotification } = useContext(NotificationContext);
  const MAX_CHARACTERS = 1000;

  //Form data details
  const [values, setValues] = useState({
    acceptance: "",
    reason: "",
    first_name: "",
    last_name: "",
    email: "",
    citizenship: "",
    suggestions: "",
    report_about: "",
    third_party_access: "",
    UN_Goals: [],
    validationpassed: "",
  });

  // function for countrylist
  const optionsList = useMemo(() => countryList().getData(), []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the current value exceeds the maximum character limit
    if (value.length <= MAX_CHARACTERS) {
      setValues({
        ...values,
        [name]: value,
      });
      // Update the corresponding character count state variable
      if (name === "report_about") {
        setReportAboutCharCount(value.length);
      } else if (name === "suggestions") {
        setSuggestionsCharCount(value.length);
      } else if (name === "reason") {
        setReasonCharCount(value.length);
      }
    }
  };

  //function for show and hide forms
  const handleshowhide = (e) => {
    const getuser = e.target.value;
    setShowhide(getuser);
    setValues({
      ...values,
      acceptance: getuser === "1" ? "Yes" : "No", // Update the accept field based on the selected value
      reason: getuser === "1" ? "" : values.reason,
    });
    // Reset reason error and character count when switching options
    setReasonError("");
    setReasonCharCount(0);
  };

  //for sustainability drop down input
  const handleDrop = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setValues({
      ...values,
      UN_Goals: selectedValues,
    });
  };

  ///for  citizenship dropdown
  const handleDropdown = (event) => {
    setValues({
      ...values,
      citizenship: event.label,
    });
  };

  // send form data to backend

  const DataForm = async () => {
    try {

      if (values.acceptance === "No") {
          await Axios.post(`${api}api/intelligencereport`, {
          acceptance: values.acceptance,
          reason: values.reason,
        });
        navigate("/");

      } else {
        let requestData = {
          acceptance: values.acceptance,
          reason: values.reason,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          citizenship: values.citizenship,
          suggestions: values.suggestions,
          report_about: values.report_about,
          third_party_access: values.third_party_access,
          UN_Goals: values.UN_Goals,
        };

        const response = await Axios.post(`${api}api/intelligencereport`,
          requestData
        );

        if (response.data === "Form created") {
          navigate("/intelligencethankscard");
            // Add notification 
            if (user_id) {
            addNotification({  
              message: "Intelligence report submitted successfully."
         })}
        } 
        else {
          alert("Error send data")
        }
      }
    } catch (error) {
      console.error("Error inserting data into the database:", error);
    }
  };

  const createForm = (event) => {
    event.preventDefault();
    const formValidation = validation(values);
    setErrors(formValidation);
    if (formValidation.validationpassed === "Yes") {
      if (values.acceptance === "No" && values.reason.trim() === "") {
        setReasonError("Reason is required when acceptance is 'No'.");
      } else {
        DataForm();
      }
    }
  };

  /* fatching data from backend */

  useEffect(() => {
    if(user_id) {

      Axios.get(`${api}api/intelligence`, { params: { user_id: user_id },...addTokenHeader() })
      .then((response) => {
        if (response.data.respMessage) {
          
          setValues({
            first_name: response.data.respMessage.first_name,
            last_name: response.data.respMessage.last_name,
            email: response.data.respMessage.email
                  });
        } 
      })
      .catch((error) => {
        console.log(error);
        setValues({});
      });
    }
    
  }, []);

  return (
    <div>
      <div className="ir-main">
        <div id="main-text">
          <center>Intelligence Report</center>
        </div>
        <div className="intelligence-p">
          <h4>What is an Intelligence Report?</h4>
          <p>
          The "Intelligence Report” refer to a compilation of observations, insights, 
          and suggestions related to identifying competence gaps and other deficiencies that need to be addressed for achieving sustainable development goals. 
          This report could serve as a foundation for directing efforts and resources towards areas where improvements are needed.
          </p>
        </div>
        <div className="form-card">
          <div className="card">
            <label className="card-content">
              Accept <span>*</span>
              <p>
                By clicking "Yes" below, you will create an Intelligence Report
                and agree to Skillur's Terms of Use and Privacy Policy.
              </p>
              </label>
            <div className="yesno-container">
              <input
                className="radio-input1"
                type="radio"
                name="isyes"
                value="1"
                onChange={(e) => handleshowhide(e)}
              />
              <label className="radio1">Yes</label>
              <input
                className="radio-input1"
                type="radio"
                name="isyes"
                value="0"
                onChange={(e) => handleshowhide(e)}
              />
              <label className="radio1">No</label>
            </div>
          </div>
        </div>

        {showhide === "1" && (
          <div className="sub-form">
            <div className="container-card-form">
              <div className="in-report"></div>

              <div className="form-section-top">
                <div className="form-details">
                  <label className="labeldata-name">First Name</label>
                  <div>
                    <input
                      placeholder="Enter First name"
                      className="input-data"
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      required
                    />
                    {errors.first_name && (
                      <p className="error-report">{errors.first_name}</p>
                    )}
                    <div></div>
                  </div>
                </div>
                <div className="form-details-form">
                  <label className="labeldata-name-form">Last Name</label>
                  <div>
                    <input
                      placeholder="Enter Last name "
                      className="input-data"
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      required
                    />
                    {errors.last_name && (
                      <p className="error-report">{errors.last_name}</p>
                    )}
                  </div>
                </div>
                <div className="form-details">
                  <label className="labeldata-name">
                    <span>*</span>Email Address
                  </label>
                  <div>
                    <input
                      placeholder=" Enter Email Address"
                      className="input-data"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && (
                      <p className="error-report"> {errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="form-details-form">
                  <label className="labeldata-name-form">
                    Your Citizenship
                  </label>
                  <div>
                    <Select
                      className="dropdown-list1"
                      options={optionsList}
                      placeholder={values.citizenship}
                      value={values.citizenship}
                      onChange={handleDropdown}
                      name="citizenship"
                      type="select"
                    />
                    {errors.citizenship && (
                      <p className="error-report">{errors.citizenship}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-details">
                <label className="labeldata-name">
                  <span>*</span> What is your report about? Use your own words.
                </label>
                <div>
                  <textarea
                    type="textarea"
                    name="report_about"
                    value={values.report_about}
                    onChange={handleChange}
                    maxLength="1000"
                    minLength="50"
                    required
                  />
                  <div className="character-count-report">
                    {`${reportAboutCharCount}/${MAX_CHARACTERS}`}
                  </div>

                  <p className="maximum-charactertext">
                    Maximum 1,000 characters.
                  </p>
                  {errors.report_about && (
                    <p className="error-report">{errors.report_about}</p>
                  )}
                </div>
              </div>

              <div className="form-details-data">
                <label className="labeldata-name-data">
                <span>*</span> Which of the United Nations 17 Sustainable Development Goals
                  (SDG) are applicable?
                  <p>Select all that apply.</p>
                </label>

                <div className="drop-down-section">
                  <Select
                    className="dropdown-list"
                    isMulti={true}
                    isSearchable={true}
                    options={sustainabilityOptions}
                    onChange={handleDrop}
                    placeholder={values.UN_Goals}
                    name="UN_Goals"
                    type="select"
                    required
                  />
                  {errors.UN_Goals && (
                    <p className="error-report"> {errors.UN_Goals}</p>
                  )}
                </div>
              </div>

              <div className="form-details">
                <label className="labeldata-name">
                  <span>*</span>What suggestions for action do you see?
                </label>
                <div>
                  <textarea
                    type="textarea"
                    name="suggestions"
                    value={values.suggestions}
                    onChange={handleChange}
                    maxLength="1000"
                    minLength="50"
                    required
                  />
                  <div className="character-count-report">
                    {`${suggestionsCharCount}/${MAX_CHARACTERS}`}
                  </div>

                  <p className="maximum-charactertext">
                    Maximum 1,000 characters.
                  </p>
                  {errors.suggestions && (
                    <p className="error-report">{errors.suggestions}</p>
                  )}
                </div>
              </div>

              <div className="form-details">
                <label className="labeldata-name">
                  Do you accept that Skillur uses your report in its
                  communication and with third parties?*
                </label>
                <label className="radio-button">Yes</label>
                <input
                  className="radio-input"
                  type="radio"
                  value="Yes"
                  name="third_party_access"
                  onChange={handleChange}
                  required
                />

                <label className="radio-button">No</label>
                <input
                  className="radio-input"
                  type="radio"
                  value="No"
                  name="third_party_access"
                  onChange={handleChange}
                  required
                />
                {errors.third_party_access && (
                  <p id="radio-error-report">{errors.third_party_access}</p>
                )}
              </div>
              <button
                className="btn-orange-ir"
                type="submit"
                onClick={createForm}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {showhide === "0" && (
          <div className="sub-form1">
            <div className="container-card-form">
              <div className="container-card">
                <label className="labeldata-name">
                  You have not accepted Skillur's terms.
                </label>
                <textarea
                  type="textarea"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  maxLength="1000"
                  minLength="50"
                  required
                />
                {reasonError && <p className="error-report">{reasonError}</p>}
                <div className="character-count-report">
                  {`${reasonCharCount}/${MAX_CHARACTERS}`}
                </div>
                <p>
                  We are very grateful if you can describe the reason why you do
                  not complete your Intelligence report.
                </p>
              </div>
              <button
                className="btn-orange-ir"
                type="submit"
                onClick={() => {
                  DataForm();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IntelligenceReport;
