import React, { useState } from "react";
import Faq from "../components/Faq/FaqList";
import "../components/Faq/Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Modal from "react-modal";
import { Link } from "react-router-dom";

function Help() {
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [Emailerror, setEmailError] = useState("");
  const [subjectCharCount, setSubjectCharCount] = useState(0);
  const [messageCharCount, setMessageCharCount] = useState(0);
  const [emailValid, setEmailValid] = useState(false);
  const [subjectValid, setSubjectValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [nonRegisterUserModal, setNonRegisterUserModal] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const user = localStorage.getItem("user");
  const api = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const MAX_CHARACTERS_Subject = 100;
  const MAX_CHARACTERS_Message = 1000;

  // Error messages for subject and message length
  const subjectErrorMessage = "Subject should be 100 characters or less";
  const messageErrorMessage = "Message should be 1000 characters or less";
  const emailErrorMessage = "Please enter a valid email address";

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const isValid =
      e.target.validity.valid && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
    // Set the email error state based on validation result
    if (!isValid) {
      setEmailError(emailErrorMessage);
    } else {
      setEmailError(""); // Clear the error message if email is valid
    }
    setEmailValid(isValid);
    setValues((prevValues) => ({ ...prevValues, email: emailValue }));
    updateFormValidity();
  };

  const handleSubjectChange = (e) => {
    const subjectValue = e.target.value;
    const isValid = e.target.validity.valid;
    setSubjectValid(isValid);
    setValues((prevValues) => ({ ...prevValues, subject: subjectValue }));
    setSubjectCharCount(subjectValue.length);
    updateFormValidity();
  };

  const handleMessageChange = (e) => {
    const messageValue = e.target.value;
    const isValid = e.target.validity.valid;
    setMessageValid(isValid);
    setValues((prevValues) => ({ ...prevValues, message: messageValue }));
    setMessageCharCount(messageValue.length);
    updateFormValidity();
  };

  const updateFormValidity = () => {
    setFormValid(emailValid && subjectValid && messageValid);
  };

  const handleContactUsClick = () => {
    if (user) {
      setContactModalOpen(true);
    } else {
      setNonRegisterUserModal(true); //modal for non-register users
    }
  };

  const handleCloseModal = () => {
    setContactModalOpen(false);
  };

  /** function for handlesubmit form  */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formValid) {
      return;
    }

    try {
      const response = await Axios.post(`${api}api/submit-form`, values);

      if (response.status === 200) {
        console.log("Form data submitted successfully");
        setValues({ email: "", subject: "", message: "" });
        setShowModalPlan(true);
        setSubjectCharCount(0);
        setMessageCharCount(0);
        setFormSubmitted(true);
      } else {
        console.log("Form data not submitted successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleCloseModalContactus = () => {
    setShowModalPlan(false);
    setNonRegisterUserModal(false);
  };

  return (
    <div className="faq-main-container">
      <div className="faq-inner-container">
        <div className="faq-heading-section">
          <h1> Frequently Asked Questions </h1>
          <button
            className="faq-contactUs-button"
            onClick={handleContactUsClick}
          >
            Contact Us
          </button>
        </div>
        <div className="faq-section">
          <h2>Account</h2>
          <Faq group_title="account" />
          <h2>Subscription </h2>
          <Faq group_title="subscription" />
          <h2>Billing</h2>
          <Faq group_title="billing" />
        </div>
      </div>
      {contactModalOpen && (
        <div className="faq-custom-modal">
          <div className="faq-modal">
            <div className="faq-modal-content">
              <div className="faq-modal-header">
                <h2>Contact Us</h2>
                <span className="faq-close-modal">
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="xl"
                    onClick={handleCloseModal}
                  />
                </span>
              </div>
              <form className="faq-form" onSubmit={handleFormSubmit}>
                <div className="faq-form-label">
                  <label htmlFor="email">
                    Your email<span className="required-field">*</span>
                  </label>
                  <input
                    type="email"
                    value={values.email}
                    name="email"
                    placeholder="email-address@domain.com"
                    required
                    onChange={handleEmailChange}
                  />
                  {Emailerror && (
                    <span className="error-message">{Emailerror}</span>
                  )}
                </div>
                <div className="faq-form-label">
                  <label htmlFor="subject">
                    Your subject<span className="required-field">*</span>
                  </label>
                  <input
                    type="text"
                    value={values.subject}
                    placeholder="Type your subject here..."
                    name="subject"
                    required
                    onChange={handleSubjectChange}
                  />
                  <div className="contactUs-character-count">
                    {`${subjectCharCount}/${MAX_CHARACTERS_Subject}`}
                  </div>
                  {values.subject.length > MAX_CHARACTERS_Subject && (
                    <span className="error-message">{subjectErrorMessage}</span>
                  )}
                </div>
                <div className="faq-form-message">
                  <label htmlFor="message">
                    Your message<span className="required-field">*</span>
                  </label>
                  <textarea
                    type="text"
                    value={values.message}
                    placeholder="Type your message here..."
                    name="message"
                    rows="4"
                    required
                    onChange={handleMessageChange}
                  ></textarea>
                  <div className="contactUs-character-count">
                    {`${messageCharCount}/${MAX_CHARACTERS_Message}`}
                  </div>
                  {values.message.length > MAX_CHARACTERS_Message && (
                    <span className="error-message">{messageErrorMessage}</span>
                  )}
                </div>
                <div className="form-button">
                  <button
                    type="submit"
                    disabled={!formValid || formSubmitted}
                    onClick={handleFormSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </form>
              <Modal
                isOpen={showModalPlan}
                contentLabel="Proof Upload"
                className="contectUs-modal"
                overlayClassName="overlay"
              >
                <h2>Thanks for reaching out to Skillur support team</h2>
                <div className="contactUs-modal-btn">
                  {/* <Link to="/updateplan"><button  className="competence-btn-plan">Explore plans</button></Link> */}
                  <button
                    className="contactUs-close-btn1"
                    onClick={handleCloseModalContactus}
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}

      {/* Modal for non-resister user  */}
      <Modal
        isOpen={nonRegisterUserModal}
        contentLabel="Proof Upload"
        className="contectUs-modal"
        overlayClassName="overlay"
      >
        <h2>Please sign up to contact us.</h2>
        <div className="contactUs-modal-btn" id="signup-btn">
          <Link to="/signup">
            <button className="contactUs-signup-btn">Sign up</button>
          </Link>
          <button
            className="contactUs-close-btn"
            onClick={handleCloseModalContactus}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Help;
