import React, { useState, useEffect, useRef } from "react";
import { useElementHeight } from "./useElementHeight";
import "./Notifications.css";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import NoNotificationBell from "./../../images/iconsDashboard/No_notification_bell.svg";
import { addTokenHeader } from '../../utility/tokenUtility'

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const api = process.env.REACT_APP_API_URL;
  const listRef = useRef(null);
  const notificationHeight = 100;
  const notificationsPerPage = Math.floor(
    useElementHeight(listRef) / notificationHeight + 1
  );

  useEffect(() => {
    fetchNotifications();
    const updateNotificationsPerPage = () => {
      setCurrentPage(1); // Reset the current page when the number of notifications per page changes
    };
    window.addEventListener("resize", updateNotificationsPerPage);
    return () => {
      window.removeEventListener("resize", updateNotificationsPerPage);
    };
  }, []);

  const fetchNotifications = () => {
    const user_id = localStorage.getItem("user_id");
    Axios.get(`${api}api/notificationinfo/${user_id}`,{...addTokenHeader()})
      .then((response) => {
        setNotifications(response.data);
        localStorage.setItem(
          "unreadCount",
          response.data.filter((item) => item.read_status === 0).length
        );
      })
      .catch((error) => {
        console.error("Failed to fetch notifications:", error);
      });
  };

  const toggleSelectMessage = (messageId, readStatus) => {
    setSelectedMessageId(messageId);
  
    if (readStatus === 0) {
      Axios.put(`${api}api/notifications/read/${messageId}`)
        .then(() => {
          // Update the read status in the frontend data
          const updatedNotifications = notifications.map((notification) => {
            if (notification.notification_id === messageId) {
              return { ...notification, read_status: 1 };
            }
            return notification;
          });
          setNotifications(updatedNotifications);

          // Add the message ID to readNotifications
          setReadNotifications([...readNotifications, messageId]);
          let newCount = localStorage.getItem("unreadCount") - 1;
          localStorage.setItem("unreadCount", newCount);
        })
        .catch((error) => {
          console.error("Failed to update read status:", error);
        });
    }
  };

  const DeleteNotificationMessage = (notificationId) => {
    setReadNotifications([...readNotifications, notificationId]);
    Axios.delete(`${api}api/notifications/delete/${notificationId}`,{...addTokenHeader()})
      .then(() => {
        setNotifications(
          notifications.filter(
            (notification) => notification.notification_id !== notificationId
          )
        );
        setSelectedMessageId(null);
      })
      .catch((error) => {
        console.error("Failed to delete notification:", error);
      });
  };

  const renderNotification = (notification) => {
    const isRead = readNotifications.includes(notification.notification_id);
    const isSelected = selectedMessageId === notification.notification_id;
    const createdAt = new Date(notification.created_at);

    // Format the date and time without year and seconds
    const formattedDate = createdAt.toLocaleString(undefined, {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    });

    // Define a style object to control the font-weight
    const messageStyle = {
      fontWeight: notification.read_status === 0 ? "bold" : "normal",
    };

    return (
      <li
        className={`notification ${isRead ? "read" : "unread"} }`}
        key={notification.notification_id}
        onClick={() =>
          toggleSelectMessage(
            notification.notification_id,
            notification.read_status
          )
        }
      >
        <div>
          <div className="notification-circle" />
          <div
            className={`notification-message ${
              isSelected ? "selected" : "unselected"
            }`}
            style={messageStyle}
          >
            {notification.message}
            <div className="notification-date">{formattedDate}</div>
            {isSelected && (
              <div
                className="delete-icon"
                onClick={(event) => {
                  event.stopPropagation();
                  DeleteNotificationMessage(notification.notification_id);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            )}
          </div>
        </div>
      </li>
    );
  };

  const sortedNotifications = notifications.sort(
    (a, b) => b.notification_id - a.notification_id
  );

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;

  const currentNotifications = sortedNotifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const totalPages = Math.ceil(
    sortedNotifications.length / notificationsPerPage
  );

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const generateDots = () => {
    const dots = [];
    for (let i = 1; i <= totalPages; i++) {
      dots.push(
        <span
          key={i}
          className={`dot ${currentPage === i ? "active-dot" : ""}`}
          onClick={() => paginate(i)}
        ></span>
      );
    }
    return dots;
  };
  /** Functionality for Pagnitation dot when user has more than 6 pages notification */

  // const generateDots = () => {
  //   const dots = [];
  
  //   if (totalPages <= 6) {
  //     for (let i = 1; i <= totalPages; i++) {
  //       dots.push(
  //         <span
  //           key={i}
  //           className={`dot ${currentPage === i ? "active-dot" : ""}`}
  //           onClick={() => paginate(i)}
  //         ></span>
  //       );
  //     }
  //   } else {
  //     const startPage = Math.max(1, currentPage - 2);
  //     const endPage = Math.min(totalPages, startPage + 4);
  
  //     if (startPage > 1) {
  //       dots.push(
  //         <span key="prev-dots" className="dot">
  //           ...
  //         </span>
  //       );
  //     }
  
  //     const middlePage = Math.floor((startPage + endPage) / 2);
  //     for (let i = startPage; i <= endPage; i++) {
  //       dots.push(
  //         <span
  //           key={i}
  //           className={`dot ${currentPage === i ? "active-dot" : ""}`}
  //           onClick={() => paginate(i)}
  //         ></span>
  //       );
  //       if (i === middlePage && endPage < totalPages) {
  //         dots.push(
  //           <span key="next-dots" className="dot">
  //             ...
  //           </span>
  //         );
  //       }
  //     }
  //   }
  
  //   return dots;
  // };
  
  
  return (
    <>
      <ul className="notification-list" ref={listRef}>
        {notifications.length === 0 ? ( // Check if notifications array is empty
          <div className="empty-notification-message">
            {" "}
            <img src={NoNotificationBell} alt="No_Notification" />
            <br />
            <p> You have no notification.</p>
          </div>
        ) : (
          <>
            {currentNotifications.map((notification) =>
              renderNotification(notification)
            )}
          </>
        )}
      </ul>
      <div className="pagination-dots">{generateDots()}</div>
    </>
  );
}

export default Notifications;
