import React from "react";
import "./Logout.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Logout({ authenticate }) {
  /////logut user and clear data storge
  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
    authenticate();
  };
  return (
    <div className="popup-background-Logout">
      <div className="popup-Logout">
        <div className="popup-inner-Logout">
          <div>
            <h2>Log out?</h2>
          </div>
          <div className="Icon-logout">
            <FontAwesomeIcon icon={faRightFromBracket} className="LogoutIcon" />
          </div>
          <div>
            <p className="helpText">Are you sure you want to log out?</p>
          </div>
          <Link to="/" onClick={logout}>
            <button type="submit" value="submit">
              Log out
            </button>
          </Link>
          <Link to="/Settings">
            <p>Cancel</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Logout;
