const validation = (values) => {
  let errors = {};
  errors.validationpassed = "No";
  if (!values.first_name) {
    errors.first_name = "First Name is required.";
  } else if (
    !/^[a-zA-ZÄÅÖäåöÉéÇçÑñØøÆæÊêÈèÜüËëĘęŘřÝýŸÿŪūÙùÚúÛûÎîÍíÌìÏïŌōÕõÒòÔôÓóŒœÀàÂâĄąÃãÁáŚśŠšŞşŻżŹźŽžČčĆćŇňŃń!@#$%^&*()+={}[\]:;"'<>,.?/~_\-|`\\]+$/.test(
      values.first_name
    )
  ) {
    errors.first_name = "Only alphabets and special characters are allowed.";
  } else if (!values.last_name) {
    errors.last_name = "Last Name is required.";
  } else if (
    !/^[a-zA-ZÄÅÖäåöÉéÇçÑñØøÆæÊêÈèÜüËëĘęŘřÝýŸÿŪūÙùÚúÛûÎîÍíÌìÏïŌōÕõÒòÔôÓóŒœÀàÂâĄąÃãÁáŚśŠšŞşŻżŹźŽžČčĆćŇňŃń!@#$%^&*()+={}[\]:;"'<>,.?/~_\-|`\\]+$/.test(
      values.last_name
    )
  ) {
    errors.last_name = "Only alphabets and special characters are allowed.";
  } else if (!values.email) {
    errors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is Invalid.";
  } else if (!values.citizenship) {
    errors.citizenship = "Citizenship is required.";
  } else if (!values.report_about) {
    errors.report_about = "This field is mandatory.";
  } else if (values.report_about.length > 1000) {
    errors.report_about = "report_about must be less than 1000 characters.";
  } else if (!values.UN_Goals || values.UN_Goals.length === 0) {
    errors.UN_Goals = "This field is mandatory.";
  } else if (!values.suggestions) {
    errors.suggestions = "This field is mandatory.";
  } else if (values.suggestions.length > 1000) {
    errors.suggestions = "Suggestions must be less than 1000 characters.";
  } else if (!values.third_party_access) {
    errors.third_party_access = "This field is mandatory.";
  } else {
    errors.validationpassed = "Yes";
  }
  return errors;
};

export default validation;
