import React from 'react'

function References() {
  return (
    <div className='main-container'>
      <div className='container-text'>
          <h1> References</h1>
          <p>The Reference section will be available in the coming months. It will enable reference taking and ratings of organizations.</p>
        </div>
      </div>
  )
}

export default References