import React from "react";
import "./style/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div>
      <h1 id="main-text">Privacy policy</h1>
      <div className="privacy">
        <div className="privacy-text-left">
          <div className="privacy-text">
            <p>
              We always process the information that our customers entrust to us
              in a careful and responsible manner. In order for you to have
              services with us, we need to obtain, use and save certain
              information about you.
              <br />
              The Data Protection Regulation, GDPR, applies as law in all EU
              countries. All companies, organizations and authorities must
              follow the rules. The main purpose of the Data Protection
              Regulation is to improve the protection for you. A personal data
              is information that can be directly or indirectly linked to a
              living person. These are, for example, names and social security
              numbers, but also information such as your IP address or your
              recorded voice can be personal information. Some personal data is
              considered sensitive and subject to special rules. Skillur does
              not collect sensitive information.
            </p>
          </div>
          <div className="privacy-text">
            <p>
              We collect information about you who have entered into or want to
              enter into an agreement with us. Skillur only collects information
              that you provide to us. Information about you that you yourself
              directly or indirectly provide to us can, for example, be
              information about name, social security number and contact
              information. We also collect identity documentation, such as
              photocopies of your passport, driver’s license or other
              documentation.
            </p>
          </div>
          <div className="privacy-text">
            <p>
              We can save information that emerges or arises when you have
              contact with us, e.g. communication via e-mail. In addition, we
              may store information from any of our Internet services. This can
              be information about how you use our services, your purchases and
              payments, your IP address or your geographical location.
            </p>
          </div>
          <div className="privacy-text">
            <p>
              We only process your personal data for certain specific purposes
              and when we have a legal basis to do so. The most common purpose
              for processing your personal data is to document, administer and
              fulfill agreements that we have with you.
            </p>
          </div>
        </div>
        <div className="privacy-text-right">
          <div className="privacy-text">
            <h3>For example, it could be for:</h3>
            <ul>
              <li>to provide information to your own competence profile</li>
              <li>
                to introduce you to organizations that are looking for skills
                (when you have chosen to be searchable).
              </li>
              <li>
                to, with anonymous data, create statistics and analysis customer
                service during the contract period.
              </li>
              <li>to comply with legislation relating to payment services.</li>
            </ul>
            <p>
              According to data protection legislation, you have the right to
              control your own personal data and receive information about how
              we process data about you. You can contact us if you want to
              exercise any of your rights:
            </p>
            <ul>
              <li>
                Request a register extract with personal data. This register
                extract is usually identical to the information contained in
                your personal competence profile (CP).
              </li>
              <li>
                Correct incorrect or incomplete information. If it turns out
                that we process personal data about you that is incorrect, you
                have the right to request that the data be corrected. You can
                also request that an incomplete information about you be
                completed.
              </li>
              <li>
                You have the right to have any or all of your personal
                information deleted. It is sometimes called the right to be
                forgotten. In some cases, we can not delete all the data. This
                is because the data are still necessary for the original purpose
                and we still have a legal basis for processing them.
              </li>
            </ul>
          </div>
          <div className="privacy-text">
            <h4>
              If you have a complaint about how we have processed your personal
              data, you can contact the supervisory authority. In Sweden, it is
              the Swedish Authority for Privacy Protection, IMY.
            </h4>
            <h4>
              Our contact information is: info@skillur.com or Skillur AB, Östra
              Tallgatan 9, 252 41 Helsingborg, Sweden.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
