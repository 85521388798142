import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

function ResetPasswordAuthenticate() {
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const token = searchParams.get("token");
  const api = process.env.REACT_APP_API_URL

  useEffect(() => {
    Axios.post(`${api}resetPasswordAuthenticate`, {
      user_id,
      token,
    })
      .then((response) => {
        if (response) {
          console.log(response.data.message);
          if (response.data.message === "Verified") {
            setMessage("Re-directing...");
            navigate("/RetrievePassword" , { state: { user_id: user_id }});
            localStorage.setItem("user_id", user_id);
          } else {
            navigate("/");
          }
        } else {
          console.log("No response from reset");
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Link is invalid");
      });
  }, [api, navigate, token, user_id]);

  return <div>{message}</div>;
}

export default ResetPasswordAuthenticate;
