import React, { useState,useContext} from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import "./CheckoutForm.css";
import {NotificationContext} from "../components/Navbar/NotificationContext";
import { addTokenHeader } from '../utility/tokenUtility'



export default function CheckoutForm({ planId, userInfo, title, price, validityDuration, validityName, amount, paymentIntentId }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const api = process.env.REACT_APP_API_URL;
  const { addNotification } = useContext(NotificationContext);
  

  const sendData = async () => {
    try {
      
      const { _commonOptions: { clientSecret: { id } } } = elements;
      
      // console.log('paypaymentIntentId',paymentIntentId)
      await axios.post(`${api}confirmPayment`, {
        user_id: userInfo.user_id,
        email: userInfo.email,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        planId: planId,
        title: title,
        price: price,
        validityDuration: validityDuration,
        validityName: validityName,
        amount: amount,
        paymentIntentId: paymentIntentId,
      },{...addTokenHeader()}).then (Response => {
        console.log(Response.data)
        if (Response.data === "payment succeeded") {
            addNotification({  
            message: "Payment successfully received."
          });
        }
      });
      ;
    } catch (error) {
      console.error('Error saving user ID:', error);
    }
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          payment_method_data: {
            billing_details: {
              // line1:"",
              // city: "Malmo",
              // state:"",
              // country:"",
              // postal_code: 12345,
            },
          },
          function : sendData(),
          return_url: `${window.location.origin}/thankscardpayment?success=true&planId=${planId}&title=${title}&email=${userInfo.email}&price=${price}&validityDuration=${validityDuration}&validityName=${validityName}&amount=${amount}&paymentIntentId=${paymentIntentId}`,
          
        },
      });

      if (error && (error.type === "card_error" || error.type === "validation_error")) {
        setMessage(error.message);
      } else {
        // Payment succeeded, call sendData function
        await sendData();
        setMessage("Payment was successful!");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };
  


  return (
    <div className="main-checkout">
      <form className="payment-form1" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing..." : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
