const validation = (values) => {
  let errors = {};

  errors.validationpassed = "No";

  if (!values.first_name) {
    errors.first_name = "First Name is required.";
  } else if (
    !/^[a-zA-ZÄÅÖäåöÉéÇçÑñØøÆæÊêÈèÜüËëĘęŘřÝýŸÿŪūÙùÚúÛûÎîÍíÌìÏïŌōÕõÒòÔôÓóŒœÀàÂâĄąÃãÁáŚśŠšŞşŻżŹźŽžČčĆćŇňŃń!@#$%^&*()+={}[\]:;"'<>,.?/~_\-|`\\]+$/.test(
      values.first_name
    )
  ) {
    errors.first_name = "Only alphabets and special characters are allowed.";
  } else if (!values.last_name) {
    errors.last_name = "Last Name is required.";
  } else if (
    !/^[a-zA-ZÄÅÖäåöÉéÇçÑñØøÆæÊêÈèÜüËëĘęŘřÝýŸÿŪūÙùÚúÛûÎîÍíÌìÏïŌōÕõÒòÔôÓóŒœÀàÂâĄąÃãÁáŚśŠšŞşŻżŹźŽžČčĆćŇňŃń!@#$%^&*()+={}[\]:;"'<>,.?/~_\-|`\\]+$/.test(
      values.last_name
    )
  ) {
    errors.last_name = "Only alphabets and special characters are allowed.";
  } else if (!values.email) {
    errors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is Invalid.";
  } else if (!values.password) {
    errors.password = "Password is required.";
  } else if (values.password.length <= 7) {
    errors.password = "Password must be atleast 8 characters.";
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = "Password should have atleast one Upper Character.";
  } else if (!/[0-9]/.test(values.password)) {
    errors.password = "Password should have atleast one Number.";
  } else if (!/[!@#$%?~^*&]/.test(values.password)) {
    errors.password = "Password should have atleast one Special Character.";
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = "Password should have atleast one Lower Character.";
  } else if (values.confirmpassword !== values.password) {
    errors.confirmpassword = "Password not matched.";
  } else if (!values.confirmpassword) {
    errors.confirmpassword = "Confirm password is required.";
  } else if (values.condition === false) {
    errors.condition = "Please accept terms and condions.";
  } else {
    errors.validationpassed = "Yes";
  }

  return errors;
};

export default validation;
