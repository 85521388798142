import "./MyPlan.css";
import { Link } from 'react-router-dom'
import moment from 'moment';


function MyPlan({ myPlanInfo}) {


  return (
    <div>
      <div className="myPlan-text">
        {/* this for adding a plan , uncomment this div and remove div free plan below  */}
        {/* <div className="myPlan-text-heading"><p>Current Plan </p> <p id="myplan-info">{(myPlanInfo !== "") ? `${(myPlanInfo?.myplan_title )} - ${(myPlanInfo?.validity_duration )}` : "Free" }</p>  </div> */}
        <div className="myPlan-text-heading"><p>Current Plan </p> <p id="myplan-info"> Free Plan</p>  </div>
        <div className="myPlan-text-heading"><p>Start Date </p> <p id="myplan-info">{(myPlanInfo !== "") ? moment(myPlanInfo?.plan_start_date).format('YYYY-MM-DD') :" No Plan"}</p> </div>
        {/* <div className="myPlan-text-heading"><p>End Date </p> <p id="myplan-info">{(myPlanInfo !== "") ? moment(myPlanInfo?.plan_disabled_date).format('YYYY-MM-DD') : "No Plan"}</p></div> */}
      </div>
      {/* <div className="btn-myplan">
        <Link to="/updateplan" ><button>Explore Plans</button></Link>
      </div> */}
    </div>
  );
}

export default MyPlan;
