import logo from "../../images/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./password.css"


const Forgotpassword = () => {

  let navigate = useNavigate();
  const [emailData, setEmailData] = useState("");
  const [errorMsg, setErrorMsg] = useState ("")
  const api = process.env.REACT_APP_API_URL

  ///function  click button forgotpassword
  const forgotPassword = (e) => {
    e.preventDefault();

    if (emailData === "") {
      setErrorMsg("Please enter your e-mail to proceed");
    } else {
      Axios.post(`${api}forgotPassword`, {
        emailData: emailData,
      }).then((response) => {
        console.log(response.data); //console logs the token if there is one

        if (response.data.message === "User does not exist") {
          setErrorMsg(
            "The email or username you entered does not exist. Please check that you have typed your email or username correctly."
          );
          setEmailData("");
        } else {
          alert(
            `We’ve sent an email to ${emailData} with the instructions to change your password.`
          );
          navigate("/");
        }
      });
    }
  };
  

  return (
    <div className='forgot-password-container'>
      <div>
        <div className='logo-img'>
            <img src={logo} alt='logo of Skillur' className='skillur-logo-img' />
        </div>
        <form className='forgotpasswordpage' onSubmit={forgotPassword}>
          <div className='forgot-password-form'>
            <label className='label2'>Email</label>
            <div>
              <input
                type='email'
                name='email'
                placeholder='Enter your email here'
                id='email'
                autoComplete='off'
                className='forgot-input'
                value={emailData}
                onChange={(e) => setEmailData(e.target.value)}
              />
            </div>
          </div>
          {errorMsg  && <p id="error-msg">{errorMsg}</p>}
          <div className='forgotpassword'>
            <div>
              <button className='reset-btn' type='submit' value='submit'>
                Reset password
              </button>
            </div>
            <div className='forgot-link'id="forgot-link">
              <Link to='/loginform'>Login</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgotpassword;
