import logo from "../images/logo.png";
import Preloader from "../videos/Preloader.mp4";
import React, { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import usePasswordToggle from "../hooks/usePaswwordToggle";
import Axios from "axios";
import "./Dashboard";
import jwt_decode from "jwt-decode";
import "../pages/style/Loginform.css";
import { addTokenHeader } from '../utility/tokenUtility'

const Loginform = ({ authenticate }) => {
  const api = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [values, setValues] = useState({
    email: "",
    password: "",
    validationpassed: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const checkaccount = async (e1) => {
    await Axios.post(`${api}api/checkaccount`, values).then((response) => {
      if (response.data.first_login === 10) {
        setMsg("This account is deactivated");
        setIsActive(true);
      } else if (response.data.respMessage === "Successfully Logged In") {
        ////set id , img, token, user in local storage--------
        localStorage.setItem("img", response.data.img);
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("email", response.data.email);
        const token = response.data.accessToken;
        const decoded = jwt_decode(token);
        localStorage.setItem("user_id", decoded["user_id"]);
        localStorage.setItem("publicId", response.data.publicId);
        localStorage.setItem("user", true);

        if (response.data.first_login === 0) {
          navigate("dashboard/profile");
          authenticate();
          setMsg(response.data.respMessage);
        } else {
          authenticate();
          localStorage.setItem("fLogin", true);
          navigate("dashboard/landingPage");
        }
      } else {
        setMsg(response.data.respMessage);
      }
    });
  };
  /// Icon eye to show password in input field
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  /// when user login
  const submitForm = (e) => {
    e.preventDefault();
    if (values.email && values.password) {
      checkaccount();
    } else {
      setMsg("Please enter your email and password");
    }
  };

  const sendActivationEmail = async (e) => {
    e.preventDefault();

    await Axios.post(`${api}api/activeaccount`, { values }).then((response) => {
      if (response) {
        setIsActive(false);
        setMsg("");
      }
    });
  };

  return (
    <div>
      <div className="main">
        <video autoPlay muted loop className="login-video">
          <source src={Preloader} type="video/mp4" />
        </video>
        <div className="sub-main">
          <div>
            <div className="logo-img">
              <img
                src={logo}
                alt="logo of Skillur"
                className="skillur-logo-img"
              />
            </div>
            <form className="formlogin" action="" onSubmit={submitForm}>
              <div>
                <div className="login-email">
                  <label className="login-label-email">Email</label>
                  <div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email here"
                      id="email"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      className="name"
                    />
                  </div>
                </div>
              </div>
              <div className="login-password">
                <label className="login-label-password">Password</label>
                <div>
                  <input
                    type={PasswordInputType}
                    name="password"
                    placeholder="Enter your password"
                    id="password"
                    autoComplete="off"
                    values={values.password}
                    onChange={handleChange}
                    className="name"
                  />
                  <span className="password-toggle-icon">{ToggleIcon}</span>
                </div>
                <div>
                  {msg !== "Successfully Logged In" && (
                    <p className="login-error"> {msg}</p>
                  )}
                </div>

                <div className="forgotpasswordlink">
                  <Link to="/forgotpassword">Forgot Password ?</Link>
                </div>
              </div>
              <div>
                <button className="loginbtn" type="submit" value="submit">
                  Login
                </button>

                <div className="signup-link">
                  <p>
                    Don't have an account ? <Link to="/signup">Sign-up</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Outlet />
      </div>
      {isActive && (
        <div className="overlay">
          <div className="popup-window">
            <p>Please activate your account by clicking the button below:</p>
            <div className="deactive-btn">
              <button
                onClick={() => setIsActive(false)}
                className="close-button"
              >
                Close
              </button>
              <button className="close-button" onClick={sendActivationEmail}>
                Send Activation Email
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Loginform;
