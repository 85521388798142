import React from 'react'
import '../pages/style/Pricing.css'
import Updateplan from '../components/MyPlanComponents/Updateplan'


export default function Pricing() {

  
  return (
    <>
    <Updateplan />
    </>
  )
}
