import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Competencies.css";
import Select from "react-select";
import InformModal from './InformModal';
import tickIcon from "./../../../images/imagesCompetence/Tick mark.svg";
import xIcon from "./../../../images/imagesCompetence/X mark.svg";
import { useNavigate } from "react-router-dom";
import { addTokenHeader } from '../../../utility/tokenUtility'

const CompetenceMapping = () => {
  let navigate = useNavigate();

  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLicenses, setSelectedLicenses] = useState([]);

  const [skills, setSkills] = useState([]);
  const [compoptions, setCompoptions] = useState([]);
  const [langoptions, setLangoptions] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);
  const licenseoptionslist = [
    { value: "No License", label: "No License" },
    { value: "Motorcycle", label: "Motorcycle" },
    { value: "Passenger vehicle", label: "Passenger vehicle" },
    { value: "Large goods vehicle", label: "Large goods vehicle" },
    { value: "Bus", label: "Bus" },
    {
      value: "International driving permit",
      label: "International driving permit",
    },
  ];

  // Pagination for Competence options
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(500); // Adjust the items per page as needed
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [addToList, setAddToList] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const [currentSection, setCurrentSection] = useState("Competences");
  const [btnText, setBtnText] = useState("Next");
  const [showModal, setShowModal] = useState(false);

  const user_id = localStorage.getItem("user_id");
  const api = process.env.REACT_APP_API_URL;
 
  
  useEffect(() => {
    fillOptions(currentPage, searchValue); // function call to populate the competence dropdown
  }, [currentPage], [searchValue]);

  useEffect(() => {
    // this hook will get called every time states has changed
    // console.log("selectedCompetences - Updated State");
  }, [selectedCompetences], [selectedLanguages], [selectedLicenses], [compoptions] );

  // Get languages list from the taxonomy table 'languages'
  const fillLanguageOptions = async (event) => {
    setSelectionChanged(true);

    Axios.get(`${api}api/getlanguageoptions`, {...addTokenHeader()}, langoptions).then(
      (response) => {
        if (response.data.respMessage) {
          setLangoptions(response.data.respMessage);
        }
      }
    );
  };

  // Get licenses list
  const fillLicenseOptions = async (event) => {
    setSelectionChanged(true);
    setLicenseOptions(licenseoptionslist);
  };

  const fillOptions = async (page, searchParam) => {
    try {
      const response = await Axios.get(
        `${api}api/getcompetencetxmy?page=${page}&pageSize=${itemsPerPage}&searchText=${searchParam}`,
        {...addTokenHeader()},
        compoptions
      );
      if (page === 1 || searchParam !== "") {
          setCompoptions(response.data.respMessage);
      } 
      else {
        setCompoptions((prevOptions) => [...prevOptions, ...response.data.respMessage]);
      };
      setTotalItems(response.data.totalItems); // Update the total number of items
    } catch (error) {
      console.log("Failed to fetch competence list", error);
    }
  };

  const handleSearchValue = (event) => {
    setSearchValue(event);
    loadFilteredPage(event);
  };

  const loadFilteredPage =  async (searchValue1) => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }
  
    setIsLoading(true);
    fillOptions(currentPage, searchValue1 )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load page", error);
        setIsLoading(false);
      });
  };

  const handleScrollDown = () => {
    loadNextPage();
  };

  const handleScrollUp = () => {
//      loadPrevPage();
  };

  const loadNextPage = () => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }
  
    setIsLoading(true);
    const nextPage = currentPage + 1;
    fillOptions(nextPage, searchValue )
      .then(() => {
        setCurrentPage(nextPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load next page", error);
        setIsLoading(false);
      });
  };

  const loadPrevPage = () => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }
  
    setIsLoading(true);
    const nextPage = currentPage - 1;
    fillOptions(nextPage, searchValue)
      .then(() => {
        setCurrentPage(nextPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load previous page", error);
        setIsLoading(false);
      });
  };

  // Get the choices of subcategories from txmy_skill_link table
  const listSubCategories = async (event) => {
    setSelectionChanged(true);
    setSelectedRole(event);
    setShowNextBtn(false);

    Axios.get(
      `${api}api/getskillstxmy`,
      { params: event,...addTokenHeader()},
      skills
    )
      .then((response) => setSkills(response.data.respMessage))
      .catch((error) => {
        setSelectionChanged(false);
        console.log(error);
      });
  };

  // Route Save function to the respective section
  const completedSection = async (event) => {
    setAddToList(true);
    switch (currentSection) {
      case "Competences":
        resetCompetence();
        setCurrentSection("Languages");
        fillLanguageOptions();
        break;
      case "Languages":
        setCurrentSection("Licenses");
        setBtnText("Save");
        fillLicenseOptions();
        break;
      case "Licenses":
        saveCompetence();
        exittolanding();
        break;
      default:
        break;
    }
    setSelectionChanged(false);
    setShowNextBtn(false);
  };

  // Save competence details in table 'competence'
  const saveCompetence = async (event) => {
    Axios.post(`${api}api/insertcompdetails`, {
      params: {
        user_id: user_id,
        section: "Competences",
        complist: selectedCompetences,
        existing_count: 0
      },
    },{...addTokenHeader()}
    ).then((response) => {
      // console.log("post saveCompetence call", response.data);
      if (response.data === "Competences details saved") {
        saveLanguage();
        saveLicense();
      }
    });
  };

  // Save language details in table 'competence'
  const saveLanguage = (event) => {
    Axios.post(`${api}api/insertcompdetails`, {
      params: {
        user_id: user_id,
        section: "Languages",
        complist: selectedLanguages,
        existing_count: 0
      },
    },{...addTokenHeader()}
    ).then((response) => {
      // console.log("Post Save Language call ", response.data);
    });
  };

  // Save license details in table 'competence'
  const saveLicense = (event) => {
    Axios.post(`${api}api/insertcompdetails`, {
      params: {
        user_id: user_id,
        section: "Licenses",
        complist: selectedLicenses,
        existing_count: 0
      },
    },{...addTokenHeader()}
    ).then((response) => {
      // console.log("Post Save License call ", response.data);
    });
  };

  const exittolanding = (event) => {
    // navigate('/landingPage', {state: {isClicked:true}},{state: {imageSrc:{TickSign}}});
    Axios.put(`${api}api/updateFirstLogin`,{
      user_id: user_id,
      landing_page : "competence"
    },{...addTokenHeader()}).then((response) => {
      // console.log("First login updated");
    })
    navigate('/dashboard/LandingPage');
  };
  
  // When Back button is clicked navigate to the Landing Page
  const btnbackClick = (event) => {
    navigate("/dashboard/landingPage");
  };

  // Add the selected main and sub competences to the selected list
  const getNext = (event) => {
    const skillsSubset = [...skills.filter((skill) => skill.checked)];

    selectedCompetences.push(selectedRole);
    if (skillsSubset.length > 0) {
      selectedCompetences.push(...skillsSubset);
    }

    // Remove duplicates in the selection
    setSelectedCompetences([
      ...new Map(
        selectedCompetences.map((temp) => [temp.label.toLowerCase(), temp])
      ).values(),
    ]);
    resetCompetence();
  };

  const resetCompetence = async (event) => {
    setAddToList(true);
    setSelectionChanged(false);
    setSelectedRole("");
    setSkills([]);
    if (selectedCompetences.length > 0) {
      setShowNextBtn(true);
    }
    else {
      setSelectionChanged(false);
    }
  };

  // event handler for language dropdown
  const handleLanguage = async (event) => {
    setSelectedLanguages(event);
    if (event.length > 0) {
      setSelectionChanged(true);
      setShowNextBtn(true);
    }
    else {
      setSelectionChanged(false);
      setShowNextBtn(false);
    }
  };

  // event handler for license dropdown
  const handleLicense = async (event) => {
    setSelectionChanged(true);
    setShowNextBtn(true);
    if (event.length > 1) {
      let tempSelection = [...event.filter(item => (item.label !== "No License"))];
      setSelectedLicenses(tempSelection);
      let tempOptions = [...licenseOptions.filter(item => (item.label !== "No License"))];
      setLicenseOptions(tempOptions);
    }
    else if (event.length === 1) {
      let tempOptions = [...licenseOptions.filter(item => (item.label !== "No License"))];
      setLicenseOptions(tempOptions);
      setSelectedLicenses(event);
    }
    else if (event.length === 0) {
      setSelectedLicenses(event);
      setLicenseOptions(licenseoptionslist);
      setSelectionChanged(false);
      setShowNextBtn(false);
    };
  };

  // event handler for subcategory checkboxes
  const handleChecked = async (event) => {
    setSkills(
      skills.map((skill) =>
        skill.value === event.target.value
          ? { ...skill, checked: event.target.checked }
          : skill
      )
    );
  };

  // After form submit stay in same page
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleInformClick = () => {
    setShowModal(true);
  };

  // console.log("Competences Mapping function");

  return (
    <>
      <div className="mainContainerMapping">
        <div>
        </div>
        <div className="main-container">
          <div className="competence">
            <h2>Competence</h2>
          </div>
            <form className="form-competenceDetails" onSubmit={handleSubmit}>
              {/* License Section */}
              {currentSection === "Licenses" && (
                <div className="text-competence" id="competence-mapping">
                  <label className="label-competence">
                    Select driving license
                  </label>
                  <div className="container-group" >
                    <Select
                      options={licenseOptions}
                      onChange={handleLicense}
                      className="select-box"
                      placeholder="Enter driving licenses you hold"
                      value={selectedLicenses}
                      isMulti={true}
                    />
                  </div>
                </div>
              )}

              {/* Languages Section */}
              {currentSection === "Languages" && (
                <div className="text-competence" id="competence-mapping">
                  <label className="label-competence">
                    Select language - minimum one
                  </label>
                  <div className="container-group">
                    <Select
                      options={langoptions}
                      onChange={handleLanguage}
                      className="select-box"
                      placeholder="Enter language you can handle when working"
                      isMulti={true}
                    />
                  </div>
                </div>
              )}

              {currentSection === "Competences" && (
                <div className="text-competence" id="competence-mapping">
                  <label className="label-competence">Select Competence</label>
                  <div className="container-group">
                    <Select
                      id="role"
                      value={selectedRole}
                      onChange={listSubCategories}
                      options={compoptions}
                      className="select-box"
                      placeholder="Enter your search"
                      onInputChange={handleSearchValue}
                      onMenuScrollToBottom={handleScrollDown}
                      noOptionsMessage={() =>
                        "Please check spelling or try alternate competences!"
                      }
//                        onMenuScrollToTop={handleScrollUp}
                    isMulti={false}
                    />

                    <div className="container-TickX">
                      <button
                        className="button-icon"
                        id="button-mapping"
                        onClick={getNext}
                        style={{
                          visibility: selectionChanged ? "visible" : "hidden",
                        }}
                      >
                        <img src={tickIcon} alt="Next" />
                      </button>
                      <button
                        id="button-mapping" 
                        className="button-icon"
//                        onClick={XClick}
                        style={{
                          visibility: selectionChanged ? "visible" : "hidden",
                        }}
                      >
                        <img src={xIcon}  onClick={resetCompetence} alt="Cancel" />
                      </button>
                    </div>

                  </div>
                  <button
                      className={selectedRole === "" ? "inform-modal" : "inform-modal-inactive"}
                      onClick={handleInformClick}
                    >
                      If your can't find your competence in the list, <br />
                      please click here to inform us!
                    </button>
                    {showModal && (
                      <InformModal showModal={showModal} closeModal={() => setShowModal(false)}/>
                    )}
                </div>
              )}
              {{ selectionChanged: true } && (
                <div className="text-competence-sub" >
                  {skills.map((eachSkill, index) => (
                    <div className="container-checkbox" key={index}>
                      <input
                        className="checkbox-label"
                        type="checkbox"
                        value={eachSkill.value}
                        checked={eachSkill.checked}
                        onChange={handleChecked}
                      />
                      <label className="checkbox-label">
                        {eachSkill.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </form>

          {/* Display the selected items - license, language and competence */}
          { addToList &&
            selectedLicenses.length > 0 && (
              <div className="selected-list-container">
                Selected Driving License(s)
                <div className="selected-items-container">
                  {selectedLicenses.map((eachCompetence, index) => (
                    <div key={index}>
                      <div className="selected-list-item" >
                        {eachCompetence.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          { addToList &&
            selectedLanguages.length > 0 && (
              <div className="selected-list-container">
                Selected Language(s)
                <div className="selected-items-container">
                  {selectedLanguages.map((eachCompetence, index) => (
                    <div key={index}>
                      <div className="selected-list-item" >
                        {eachCompetence.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          { addToList &&
            selectedCompetences.length > 0 && (
              <div className="selected-list-container">
                Selected Competences
                <div className="selected-items-container">
                  {selectedCompetences.map((eachCompetence, index) => (
                    <div key={index}>
                      <div className="selected-list-item">
                        {eachCompetence.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          <div className="container-btn">
            <button className="btn-back" onClick={btnbackClick}>Back</button>
            <button
              style={{ visibility: showNextBtn ? "visible" : "hidden" }}
              className="btn-back"
              onClick={completedSection}
            >
              {btnText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetenceMapping;
