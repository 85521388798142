// This is the custom hook to get the height of the '.notification-list' element.
import { useState, useEffect } from "react";

export function useElementHeight(ref) {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { height } = entries[0].contentRect;
                setHeight(height);
            }
        });

        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref]);

    return height;
}
