import axios from 'axios';

import { addTokenHeader } from '../../utility/tokenUtility'
const api = process.env.REACT_APP_API_URL;


export const fetchMyPlanUserInfo = async (user_id) => {
  try {
    
    const response = await axios.get(`${api}api/myplanUserInfo`, {
      params: { user_id: user_id },
      ...addTokenHeader(),
    });
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error to handle it in the components where the function is used.
  }
};
