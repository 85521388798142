import React, { useEffect, useState, useContext } from "react";
import "./TheFuture.css";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router";
import HelpButton from "../../HelpButton/HelpButton";
import { NotificationContext } from "../../Navbar/NotificationContext";
import { addTokenHeader } from '../../../utility/tokenUtility'

const TheFuture = () => {
  let navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const isLoginFirst = location.state?.first_login;
  const user_id = localStorage.getItem("user_id");
  const [message, setMessage] = useState("");
  const [assignmentInterestCount, setAssignmentInterestCount] = useState(0);
  const [compFutureAssignmentCount, setCompFutureAssignmentCount] = useState(0);
  const [rolesToReachCount, setRolesToReachCount] = useState(0);
  const [contextOrgToAvoidCount, setContextOrgToAvoidCount] = useState(0);
  const [futureQuestions, setfutureQuestions] = useState([]);
  const [futureLandingPageQuestions, setfutureLandingPageQuestions] = useState(
    []
  );
  const { addNotification } = useContext(NotificationContext);
  const MAX_CHARACTERS = 500;
  const [values, setValues] = useState({
    assignment_interest: "",
    comp_future_assignment: "",
    roles_to_reach: "",
    context_org_to_avoid: "",
  });

  const onchangeTextarea = (e) => {
    const { name, value } = e.target;
    // Check if the current value exceeds the maximum character limit
    if (value.length <= MAX_CHARACTERS) {
      setValues({
        ...values,
        [name]: value,
      });

      // Update the corresponding character count state variable
      if (name === "assignment_interest") {
        setAssignmentInterestCount(value.length);
      } else if (name === "comp_future_assignment") {
        setCompFutureAssignmentCount(value.length);
      } else if (name === "roles_to_reach") {
        setRolesToReachCount(value.length);
      } else if (name === "context_org_to_avoid") {
        setContextOrgToAvoidCount(value.length);
      }
    }
  };

  /* Function which calls API for database entry */
  const insertFuturePlanRecord = async () => {
    try {
      await Axios.post(`${api}api/futureplaninfo`, { ...values, user_id},{...addTokenHeader()});
    } catch (error) {}
  };

  /* Handle submit button */

  const submitHandlerFuturePlans = (e) => {
    insertFuturePlanRecord();

    if (isLoginFirst === 4) {
      Axios.put(`${api}api/updateFirstLogin`, {
        user_id: user_id,
        landing_page: "mappingCompleted",
      },{...addTokenHeader()}).then((response) => {
        console.log("First login updated");
        // Add notification
        addNotification({
          message: "You have completed the mapping successfully.",
        });
      });

      navigate("/landingThanksModal", { state: { first_login: true } });
      localStorage.setItem("fLogin", false);
    }
    showMessageForSeconds(`Information is updated successfully`, 5);
  };

  /* fetching data form backend */

  useEffect(() => {
    Axios.get(`${api}api/futureplaninfo/${user_id}`,{...addTokenHeader()}).then((response) => {
      const data = response.data[0];
      const updatValues = {
        assignment_interest: data ? data.assignment_interest : '',
        comp_future_assignment: data ? data.comp_future_assignment : '',
        roles_to_reach: data ? data.roles_to_reach : '',
        context_org_to_avoid: data ? data.context_org_to_avoid : '',
      };
      setValues(updatValues);
      setAssignmentInterestCount( data ? data.assignment_interest?.length : 0);
      setCompFutureAssignmentCount( data ? data.comp_future_assignment?.length : 0);
      setRolesToReachCount( data ? data.roles_to_reach?.length : 0);
      setContextOrgToAvoidCount( data ? data.context_org_to_avoid?.length : 0);
    });
  }, []);

  /** Getting helpbutton data from backend **/
  useEffect(() => {
    let help_title;
    if (isLoginFirst === 4) {
      help_title = "landing page-future";
      Axios.get(`${api}api/questions/${help_title}`,{...addTokenHeader()})
        .then((response) => {
          setfutureLandingPageQuestions(response.data);
          
        })

        .catch((error) => {
          console.error(error);
        });
    } else {
      help_title = "future";
      Axios.get(`${api}api/questions/${help_title}`,{...addTokenHeader()})
        .then((response) => {
          setfutureQuestions(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  /*set the timer for display user alert & error messages*/

  const showMessageForSeconds = (text, seconds) => {
    setMessage(text);
    setTimeout(() => {
      setMessage("");
    }, seconds * 1000);
  };

  return (
    <>
      <div className="main-container">
        <div className="future-plan-heading">
          <h2> The Future </h2>
        </div>
        <form className="form-FuturePlanDetails">
          <div className="textbox-group">
            <label className="future-label-Data">
              What job or assignment are you interested in?
            </label>
            <textarea
              name="assignment_interest"
              type="text"
              value={values.assignment_interest}
              onChange={onchangeTextarea}
              required
            />

            <div className="character-count">
              {`${assignmentInterestCount}/${MAX_CHARACTERS} `}
            </div>
          </div>
          <div className="textbox-group">
            <label className="future-label-Data">
              Which of the competences you have specified are in line with the
              next work/assignment?
            </label>
            <textarea
              name="comp_future_assignment"
              type="text"
              value={values.comp_future_assignment}
              onChange={onchangeTextarea}
              required
            />
            <div className="character-count">
              {`${compFutureAssignmentCount}/${MAX_CHARACTERS} `}
            </div>
          </div>
          <div className="textbox-group">
            <label className="future-label-Data">
              What competences and roles do you want to achieve in the future?
            </label>
            <textarea
              name="roles_to_reach"
              type="text"
              value={values.roles_to_reach}
              onChange={onchangeTextarea}
              required
            />
            <div className="character-count">
              {`${rolesToReachCount}/${MAX_CHARACTERS}`}
            </div>
          </div>
          <div className="textbox-group">
            <label className="future-label-Data">
              Are there any areas you don't want to have a role in?
            </label>
            <textarea
              name="context_org_to_avoid"
              type="text"
              value={values.context_org_to_avoid}
              onChange={onchangeTextarea}
              required
            />
            <div className="character-count">
              {`${contextOrgToAvoidCount}/${MAX_CHARACTERS}`}
            </div>
          </div>
        </form>
        <div className="button-container">
          <button
            className="btn-FuturePlanDetails"
            onClick={submitHandlerFuturePlans}
            type="button"
          >
            Save
          </button>
          <p id="user-alert-messages">{message}</p>
        </div>
        <div>
          <HelpButton
            questions={
              isLoginFirst === 4 ? futureLandingPageQuestions : futureQuestions
            }
          />
        </div>
      </div>
    </>
  );
};

export default TheFuture;
