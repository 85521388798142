import React, { useState,useEffect } from "react";
import validation from "../formValidation/signupValidation";
import profileVideo from "../videos/profileVideo.mp4";
import { Link } from "react-router-dom";
import AccountVerification from "../modals/AccountVerification";
import Axios from "axios";
import ExistingEmail from "../modals/ExistingEmail";
import "../pages/style/Signupform.css"


const Signupform = () => {

  const api = process.env.REACT_APP_API_URL
  const [modalOpen, setModalOpen] = useState(false);
  
  const [modalOpenexist, setModalOpenexist] = useState(false);
  const [errors, setErrors] = useState({});
  const [trialPlans, setTrialPlans] = useState([]);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    condition: false,
    validationpassed: "",
  });
  const isClicked = true; 
  

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    
    if (type === 'checkbox') {
      setValues({
        ...values,
        [name]: checked,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  /*Function which calls API for database entry*/
  const InsertRec = async (e1) => {
      await Axios.post(`${api}api/insert`, values).then((response) => {
      
      if (response.data === "Email id already exist") {
        setModalOpenexist(true);
      } else {
        setModalOpen(true);
      }
    });
  };

  /*handle submit button*/
  const createAccount = (event) => {
    event.preventDefault();

    if (validation(values).validationpassed === "No") {
      setErrors(validation(values));
    } else {
      InsertRec();
    }
  };


  return (
    <>
      <div className="signup-container">
        <video className="signup-video" autoPlay muted>
          <source src={profileVideo} type="video/mp4" />
        </video>
        <div className="app-wrapper">
          <div>
            <h1 className="signup-title">Create new account</h1>
          </div>
          <form className="form-wrapper">
            <div className="Name">
              <label className="signup-label">First Name</label>
              <div>
                <input
                  type="text"
                  name="first_name"
                  placeholder="Enter your first name"
                  className="signup-input"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <p className="signup-error"> {errors.first_name}</p>
                )}
              </div>
            </div>
            <div className="Name">
              <label className="signup-label">Last Name</label>
              <div>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Enter your last name"
                  className="signup-input"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <p className="signup-error"> {errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="email">
              <label className="signup-label">Email</label>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email here"
                  className="signup-input"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="signup-error"> {errors.email}</p>}
              </div>
            </div>
            <div className="password">
              <label className="signup-label">Password</label>
              <div>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password here"
                  className="signup-input"
                  values={values.password}
                  onChange={handleChange}
                />
                <p className="passWord">
                  Password should contain atleast 8 characters,one Uppercase,
                  <br></br>
                  one Lowercase,one Number and one Special character.
                </p>
                {errors.password && <p className="signup-error"> {errors.password}</p>}
              </div>
            </div>

            <div className="confirmpassword">
              <label className="signup-label">Confirm Password</label>
              <div>
                <input
                  type="password"
                  name="confirmpassword"
                  placeholder="Enter your confirm password here"
                  className="signup-input"
                  values={values.confirmpassword}
                  onChange={handleChange}
                />

                {errors.confirmpassword && (
                  <p className="signup-error"> {errors.confirmpassword}</p>
                )}
              </div>
              <div className="signup-checkboxinput">
              <input
                  className="igree-checkbox"
                  type="checkbox"
                  name="condition"
                  checked={values.condition}
                  onChange={handleChange}
                />
                <div className="termsOfUse">
                <span>I agree to</span>
                <Link  to="/termsOfUse"
                        state={{ isClicked} }
                        >
                  <span> Skillur's terms and conditions.</span>
                </Link>
              </div>
              </div>
              
              {errors.condition && <p className="signup-error"> {errors.condition}</p>}
            </div>

            <div>
              <button className="create-account" onClick={createAccount}>
                Create Account
              </button>
             
            </div>
            <div className="signup-link">
              <p>
                Already have an account ?<Link to="/loginform">Login</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {modalOpen && <AccountVerification setOpenModal={setModalOpen} />}
              {modalOpenexist && (
                <ExistingEmail setValuesmodal={setModalOpenexist} />
              )}
    </>
  );
};

export default Signupform;
