import React from "react";
import logo from "../../images/logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useRef } from "react";
import youtube from "../../images/footer/YouTube - Original.svg";
import tiktok from "../../images/footer/TikTok - Original.svg";
import instagram from "../../images/footer/Instagram - Original.svg";
import linkdin from "../../images/footer/LinkedIn - Original.svg";
import twitter from "../../images/footer/Twitter - Original.svg";
import facebook from "../../images/footer/Facebook - Original.svg";

function Footer() {
  const myRef = useRef(null);

  //for scroll arrow to up
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // Calculate the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-main">
        <div className="footer-logo">
          <img src={logo} alt="logo" ref={myRef} onClick={scrollToTop} />
          <h2>
            Skillur's mission is to strengthen talents for tomorrow's challenges
          </h2>
        </div>
        <div className="footer-links">
          <div className="links">
            <ul className="links-ul">
              <p>EXPLORE</p>
              <li>
                <Link to="/pricing">Pricing</Link>{" "}
              </li>
              <li>
                <Link to="/features">Competence</Link>{" "}
              </li>
              <li>
                <Link to="/sustainability">Sustainability</Link>{" "}
              </li>
              <li>
                <Link to="/ourvalues">Our Values</Link>{" "}
              </li>
            </ul>
          </div>
          <div className="links">
            <ul>
              <p>RESOURCES</p>
              <li>
                <Link to="/about">Find your Competence</Link>{" "}
              </li>
              <li>
                <Link to="/help">Help/FAQ</Link>{" "}
              </li>
              <li>
                <Link to="/about">Video Guides</Link>{" "}
              </li>
            </ul>
          </div>
          <div className="links">
            <ul>
              <p>LEGAL</p>
              <li>
                <Link to="/termsOfUse">Terms of Use</Link>{" "}
              </li>
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="socialMedia">
        <ul>
          <li>
            <a href="https://www.youtube.com/@skillurCP">
              <img src={youtube} alt="" target="_blank" />{" "}
            </a>{" "}
          </li>
          {/* <li>
            <a href="https://www.tiktok.com/">
              <img src={tiktok} alt="" />{" "}
            </a>{" "}
          </li> */}
          <li>
            <a href="https://www.instagram.com/skillurcp/">
              <img src={instagram} alt="" target="_blank" />{" "}
            </a>{" "}
          </li>
          <li>
            <a href="https://www.linkedin.com/company/skillur/">
              <img src={linkdin} alt="" target="_blank" />{" "}
            </a>{" "}
          </li>
          {/* <li>
            <a href="https://www.twitter.com/">
              <img src={twitter} alt="" />{" "}
            </a>{" "}
          </li> */}
          <li>
            <a href="https://www.facebook.com/search/top?q=skillur">
              <img src={facebook} alt="" target="_blank" />{" "}
            </a>{" "}
          </li>
        </ul>
      </div>
      <div className="footer-text">
        <h3>@ {currentYear} Skillur. All rights reserved.</h3>
      </div>
    </footer>
  );
}

export default Footer;
