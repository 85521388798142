import React, { useState } from "react";
import "./Settings.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import profileDP from "./../../images/placeholderdp.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as RiIcons from "react-icons/ri";
import { addTokenHeader } from '../../utility/tokenUtility'

function Settings() {
  
  const img = localStorage.getItem("img");
  const user_id = localStorage.getItem("user_id");
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState(
    img === "null" ? profileDP : img
  );
  const [selectedFile, setSelectedFile] = useState();
  const [imageUploadMessage, setImageUploadMessage] = useState("");
  const [msgSuccess, setMsgSuccess] = useState(false);
  const api = process.env.REACT_APP_API_URL;
  const first_login = localStorage.getItem('fLogin');

  //functions for image upload
  const handleFileInputChange = (e) => {
    const maxFileSize = 1024;
    const file = e.target.files[0];
    const selectedFileSize = file.size / 1024;

    if (selectedFileSize > maxFileSize) {
      setImageUploadMessage(
        "File size is greater than maximum limit. Please choose a file less than 2MB."
      );
    } else {
      previewFile(file);
      setSelectedFile(file);
      setFileInputState(e.target.value);
      setImageUploadMessage("");
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };
  const handleImageUpload = (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setImageUploadMessage("Please choose a file");
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
    setImageUploadMessage("");
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  };

  const uploadImage = (base64EncodedImage) => {
    Axios.post(
      `${api}updateImage`,
      {
        user_id: user_id,
        img: base64EncodedImage,
        public_id: localStorage.getItem("publicId"),
      },
      {
        ...addTokenHeader()
      }
    )
      .then((response) => {
        if (response) {
          
          localStorage.removeItem("img");
          localStorage.setItem("img", response.data.msg);
          localStorage.setItem("publicId", response.data.public_id);
          setImageUploadMessage(
            "Image has been uploaded , please refresh page"
          );
          setMsgSuccess(true);
        } else {
          alert("Unauthorized");
        }
      })
      .catch((error) => {
        setImageUploadMessage("not upload , some error here");
        setMsgSuccess(false);
      });
  };
  //functions for delete image
  const deleteImage = () => {
    if (localStorage.getItem("img") === "null") {
      setImageUploadMessage(" No image to be deleted");
      setMsgSuccess(false);
    } else {
      Axios.post(
        `${api}updateImage`,
        {
          user_id: user_id,
          img: null,
          public_id: localStorage.getItem("publicId"),
        },
        {
          ...addTokenHeader()
        }
      )
        .then((response) => {
          if (response) {
            localStorage.removeItem("img");
            localStorage.setItem("img", "null");
            setImageUploadMessage(
              "Image has been deleted, please refresh page"
            );
          } else {
          }
        })
        .catch((error) => {
          alert();
          //"Your session has expired. Please login again to continue working."
          localStorage.clear();
        });
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-inner">
          <div>
            <h2>Profile Settings</h2>

            <img
              src={previewSource}
              alt="uploadpic"
              className="profile-settings"
            />
            <input
              id="file-input"
              type="file"
              name="image"
              value={fileInputState}
              onChange={handleFileInputChange}
              accept="image/*"
              size={""}
              style={{
                display: "none",
              }}
            />
            <button
              className="ChangePhoto"
              onClick={() => {
                document.getElementById("file-input").click();
              }}
            >
              Change photo
            </button>
            <button
              className="upload-btn"
              title="upload your image"
              onClick={handleImageUpload}
            >
              <RiIcons.RiUpload2Fill size="20" color="#ffffff"  />
            </button>
            <Link to="" className="link">
              <button onClick={deleteImage} title="delete your image">
                Delete photo
              </button>
            </Link>
          </div>
          {msgSuccess && <p id="msg-success">{imageUploadMessage}</p>}
          {!msgSuccess && <p id="msg-delete-photo">{imageUploadMessage}</p>}
          <Link to="/resetpassword" className="link">
            <button>
              Password{" "}
              <FontAwesomeIcon icon={faChevronRight} className="icon" />
            </button>
          </Link>
          {/* <Link to="PromotionCode" className="link">
          <button>
              Promotion Code <FontAwesomeIcon icon={faChevronRight} className="icon" />
            </button>
            </Link> */}
          <Link to="Logout" className="link">
            <button>
              Log out <FontAwesomeIcon icon={faChevronRight} className="icon" />
            </button>
          </Link>
          <Link to="Account" className="link-text">
            <button>Deactivate account</button>
          </Link>
        </div>
        <Link to={(first_login === "true") ?"/dashboard/landingpage" : "/dashboard/profile"} ><button className="setting-close-btn" >Close</button></Link>
      </div>
    </div>
  );
}
export default Settings;
