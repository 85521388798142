import React from 'react'
import { Link } from 'react-router-dom'
import '../pages/style/Features.css'
export default function Features() {
  return (
    <div>
      <h1 id='main-text'>Competence</h1>
    <div className='features'>
      <div className='features-text'>
      <p>Recognizing competence isn't always straight forward. Skills can be elusive, 
        challenging to identify or verify. At times, we overlook our own abilities. 
        Is successfully raising children as a single parent a form of competence?
      </p>
      <p>
      At Skillur, we believe competencies can be documented and validated. When you join us, 
      we guide you in mapping your competence. Begin with the evident aspects and gradually uncover those you might not initially notice. 
      Keep building on your skills as you progress through life.
      </p>
        <p>
        Skillur equips you with tools to showcase your competence, making it understandable to others. 
        We also simplify transitioning to new industries, whether it's by choice or necessity. 
        Your competence deserves recognition and growth, and Skillur is here to make that journey seamless.
        </p>
        </div>
        <div className='features-text-btn'>
          <h2 id='main-text'>Your competence is unique</h2>
          <Link to="/signup" ><button>find your competence</button></Link>
        </div>
        </div>
    </div>
  )
}
