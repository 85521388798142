import React from 'react'
import "./style/TermsOfUse.css"
import { Link, useLocation} from "react-router-dom";

function TermsOfUse(props) {

  const { isClicked } = useLocation().state || {};

  // const [backBtn,setBackbtn] = useState(false)
  const user= localStorage.getItem("user")
  
  return (
    <div>
    <h1 id='main-text'>Terms of use</h1>
    <div className='terms'>
    <div className='terms-text-left'>
      <div className='terms-text'>
      <p>By accessing and communicating with Skillur through this web site, Skillur’s Competence mapping,
        registering online or otherwise using the information available through this site, you are agreeing to the terms and conditions contained herein.
      </p>
      </div>
      <div className='terms-text'>
        <h3>Copyright</h3>
        <p>All content included on this site, such as text, graphics, logos, information or software, and any compilation (arrangement or assembly) or use thereof, 
          is the property of Skillur or content providers and is protected by applicable copyright laws.
          Except as expressly authorized, any use, including the reproduction, modification, distribution, transmission, republication, display or performance of the content on this site is strictly prohibited.
        </p>
      </div>
      <div className='terms-text'>
        <h3>Acceptable Use</h3>
        <p>
        This web site or e-mail services, may be used for communication with Skillur regarding Skillur‘s business or the terms and conditions of your relationship with Skillur, 
        for completion or performance of your duties and responsibilities as part of an assignment established through Skillur or required by an agreement with Skillur, 
        and for such other uses as Skillur may from time to time expressly allow.
        </p>
      </div>
      <div className='terms-text'>
        <h3>Competence Owner</h3>
        <p>
        If you are seeking to become a Skillur Competence Owner and completing the registration form or Competence mapping contained on the site,
        this does not create any contract of employment or other relationship between you and Skillur. 
        To become a Skillur Competence Owner you must complete the Competence mapping.
        </p>
      </div>
      
    </div>
    <div className='terms-text-right'>
    <div className='terms-text'>
      <h3>Prohibited Uses</h3>
      <p>
      This web site or email services, shall not be used for any other purpose other than those set forth above. Skillur expressly prohibits, without limitation: (1) the transmission of any threatening, obscene or harassing material;
      (2) the transmission of any unauthorized or unsolicited advertising; (3) the corruption, theft, misappropriation or destruction of any data or any action which could impede Skillur‘s or another authorized user’s legitimate access to data, 
      including the launching of any virus, worms or any other unauthorized software into the network or any computer or system connected to the network; (4) the use of the site or the network to further or conduct any illegal activity according to any federal law or the law of any state from which the site can be accessed; 
      (5) allowing any third party not an employee, client or member of Skillur access to the site, or any sharing of passwords or access codes; (6) any other act expressly prohibited by Skillur from time to time.
      </p>
    </div>
    <div className='terms-text'>
      <h3>Privacy and Security</h3>
      <p>
      Skillur reserves the right to access and disclose information collected from you to comply with applicable laws, and lawful government requests, 
      to operate its systems properly or to protect itself or its users. Please also see our Privacy Policy located on the web site.
      </p>
    </div>
    <div className='terms-text'>
      <p>
      Despite our efforts, the services available to you is subject to periods of interruption, system failure and delay and any information you provide may be lost, 
      corrupted, stolen or destroyed. Skillur shall not be liable for any loss or damage, loss of privacy or any other loss or damage that you may suffer from use of its services or through the loss, 
      involuntary disclosure, theft or corruption of any information you provide to Skillur online or through the Internet.
      </p>
    </div>
    </div>
    </div>
    <div className='terms-accept'><Link to="/signup"><button className={ user || !isClicked ? 'acceptbtn active' : 'acceptbtn'}> Back</button></Link></div>
    </div>
    
  )
}

export default TermsOfUse