export const MenuItems = [
    {
        title: 'Sustainability',
        path: '/sustainability',
        cName: 'dropdown-link'
    },
    {
        title: 'Our Values',
        path: '/ourvalues',
        cName: 'dropdown-link'
    },
    {
        title: 'About us',
        path: '/about',
        cName: 'dropdown-link'
    }
    
];
export const MenuItems2 =[
{
    title: 'Privacy Policy',
    path: '/privacypolicy',
    cName: 'dropdown-link'
},
{
    title: 'Terms Of Use',
    path: '/termsofuse',
    cName: 'dropdown-link'
}
];