import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

function ExistingEmail({ setValuesModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="logo-img">
          <img src={logo} alt="logo of Skillur" className="skillur-logo-img" />
        </div>
        <div className="modal-box">
          <p>
            This email already exist.
            <br />
            Please enter new email address to create a Skillur Account.
            <br />
            <Link to="/forgotpassword">Forgotpassword ??</Link>
          </p>
        </div>
        <div className="OK-btn">
          <Link to="/loginform">
            <button>OK </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ExistingEmail;
