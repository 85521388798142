
import React, { useEffect, useState } from 'react';
import '../pages/style/Dashboard.css';
import './Loginform';
import CompetenceIcons from '../components/CompetenceIcons/CompetenceIcons';
import CompetenceIconsLandingPage from '../components/CompetenceIcons/CompetenceIconsLandingPage';
import Axios from "axios";
import { addTokenHeader } from '../utility/tokenUtility'




export default function Dashboard({authenticate}) {

        const [firstLogin ,setFirstLogin] = useState();
        const api = process.env.REACT_APP_API_URL
        const user_id = localStorage.getItem("user_id")
        const fLogin = localStorage.getItem("fLogin")


        useEffect(() => {
                // Call the API endpoint
                Axios.get(`${api}api/datauserinfo`,{ params: { user_id: user_id }, ...addTokenHeader() })
                .then(response => {
                    setFirstLogin(response.data.first_login)
            })
                .catch(error => {
                    // Handle the error
                    console.error(error);
                });
            }, []);
        

        return (
            <>
            <div className='competence-header'>
                {( firstLogin  === 0 ) || !fLogin ? <CompetenceIcons/> : <CompetenceIconsLandingPage/> }

        </div>
        </>
    )
    }
