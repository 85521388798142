import React, { useState, useEffect, useCallback } from "react";
import "./HelpButton.css";

function HelpButton({ questions }) {
  const [currentPage, setCurrentPage] = useState(1);

  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);
  // console.log(deviceHeight, "deviceHeight");
  const heightTable = {
    5: [711, 765, 657, 739, 859, 1209],
    6: [601, 670, 743, 847, 899, 917, 919, 1182, 1370],
    7: [722, 884, 931, 938, 983],
    8: [1005, 1026],
  };

  let itemsPerPage = 5;

  for (const [key, values] of Object.entries(heightTable)) {
    if (values.includes(deviceHeight)) {
      itemsPerPage = parseInt(key);
      break;
    }
  }

  // console.log(itemsPerPage, "itemsPerPage");

  const updateDeviceHeight = useCallback(() => {
    setDeviceHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    updateDeviceHeight();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setDeviceHeight(window.innerHeight);
  };

  // State variable to manage the visibility
  const [modal, setModal] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setModal(!modal);
  };

  // Calculate the index range of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = questions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(questions.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const generateDots = () => {
    const dots = [];

    for (let i = 1; i <= totalPages; i++) {
      dots.push(
        <span
          key={i}
          className={`dot ${currentPage === i ? "active-dot" : ""}`}
          onClick={() => paginate(i)}
        ></span>
      );
    }

    return dots;
  };

  return (
    <div>
      <button
        className="help-btn"
        title="Help & Support"
        onClick={toggleModal}
        type="button"
      >
        <span className="hv-bf">?</span>
      </button>
      {modal && (
        <div className="help-container">
          <ul>
            {currentItems.map((question, index) => (
              <li className="help-messageslist" key={index}>
                {question.description}
              </li>
            ))}
          </ul>
          <div className="help-pagination">{generateDots()}</div>
        </div>
      )}
    </div>
  );
}

export default HelpButton;
