import React, { useEffect, useState } from "react";
import Axios from "axios";
import Select from "react-select";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import AlertConfirmModal from "./AlertConfirmModal";

import "react-datepicker/dist/react-datepicker.css";
import "./Competencies.css";

import AttachDoc from "./../../../images/imagesCompetence/Attachment-Icon.svg";
import Delete from "./../../../images/imagesCompetence/Delete.svg";
import { fetchMyPlanUserInfo } from "../../MyPlanComponents/fetchMyPlanUserInfoHooks";
import { addTokenHeader } from '../../../utility/tokenUtility'

const ProofSection = ({competence_id, compProofIP, onAnswer}) => {
  const doc_default = { doc_name: "", doc_public_id: "", doc_url: "" };
  const [filesToUpload, setFilesToUpload] = useState({ doc1: "", doc2: "" });
  const [compProof, setCompProof] = useState(compProofIP);
  const [hideAttachment, setHideAttachment] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const [canDelete1, setCanDelete1] = useState(false);
  const [canDelete2, setCanDelete2] = useState(false);
  const [modalObj, setModalObj] = useState({});
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);

  const [attachOpen, setAttachOpen] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState("");
  const maxFilesize = 51.2; //5MB = 51,200 Kilobytes (KB) , 1mb=1024KB

  ///FOR user have a plan
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [myPlanInfo, setMyPlanInfo] = useState({});

  const user_id = localStorage.getItem("user_id");
  const api = process.env.REACT_APP_API_URL;

  // Define an array of allowed file extensions
  const allowedFileExtensions = ["pdf", "doc", "docx", "jpg", "txt", "png", "pptx" ];
  const RESOURCE_TYPES = ["image", "raw", "raw", "image", "raw", "image", "raw" ];

  const validationoptions = [{ value: "0", label: "Not validated" },{ value: "1", label: "Document is available" },{ value: "2", label: "Work experience" },
    { value: "3", label: "Other experience" }, { value: "4", label: "Credential ID" }, { value: "5", label: "Credential URL" },
    { value: "6", label: "Blockchain" },  { value: "7", label: "Another way" }];
  const altvalidationoptions = ["Credential ID","Credential URL","Blockchain" ];
  const expvalidationoptions = ["Work experience", "Other experience"];

  const deleteAttach = {
    module: "deleteAttach",
    title: "Delete Confirmation",
    button1: "Yes",
    button2: "Cancel",
    text: "Are you sure to remove this attachment?\nThis can not be undone!",
    param1: "",
    param2: ""
  };

  const noAttach = {
    module: "noAttach",
    title: "Proceed without attachments",
    button1: "Yes",
    button2: "Cancel",
    text: "Are you sure you want to proceed without attaching the documents?",
    param1: "",
    param2: ""
  };

  const saveAlert = {
      module: "saveAlert",
      title: "Save Alert",
      button1: "OK",
      button2: "",
      text: "",
      param1: ""
    };

    const customStyles = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      // width: "300px",
      minHeight: "1px",
      // height: "28px",
      textAlign: "left",
      border: "none",
      lineHeight: "normal",
    }),
    control: (provided) => ({
      ...provided,
      alignItems: "self-start",
      border: "1px solid #D4D4D4",
      borderRadius: "5px",
      minHeight: "1px",
      height: "28px",
      color: "#D4D4D4",
      margin: "5px",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingTop: "0",
      paddingBottom: "0",
      color: "#D4D4D4",
      padding: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // width: "27px",
      transition: "none",
      height: "25px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: "0px",
      transition: "none",
      width: "20px",
      height: "20px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: "1px",
      marginTop: "3px",
      height: "21px",
      transition: "none",
    }),
    Svg: (provided) => ({
      ...provided,
      width: "10px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      // minHeight: "1px",
      // height: "25px",
      paddingLeft: "5px",
      paddingTop: "0",
      paddingBottom: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingBottom: "1px",
      color: "#808080",
      marginLeft: "0px",
    }),
  };

  const formatDate = (inputDate) => {
    const originalDate = new Date(inputDate);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = originalDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    // this hook will get called every time states has changed
    setHideAttachment(altvalidationoptions.includes(compProof?.validation_option));
    setShowExperience(expvalidationoptions.includes(compProof?.validation_option));
    setCanDelete1((typeof(compProof?.proof_id) !== 'undefined' && compProof?.doc1?.doc_name !== ""));
    setCanDelete2((typeof(compProof?.proof_id) !== 'undefined' && compProof?.doc2?.doc_name !== ""));
  }, [compProof]);

  const saveInCloud = async (docToSave) => {
    const formData = new FormData();
    formData.append("auto", docToSave);
    try {
      /// insert document in cloudinary
      const response = await Axios.post(
        `${api}api/upload-documents`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadInProgress(percentCompleted);
          },
        }
      );
      showMessageForSeconds("Document uploaded successfully", 5);
      setUploadInProgress(false);
      setAttachOpen("");
      return response.data;
    } catch (error) {
      console.log(error);
      setUploadInProgress(false);
      return "Failure";
    }
  };

  // Save proof details for competences
  const saveProof = async (competenceID) => {
    if (compProof.description === "" || compProof.validation_option === "") {
      return "Please fill in the data!";
    }
    if (myPlanInfo.active === 1 && compProof.validation_option === "Document is available" && compProof.doc1.doc_name === "" && compProof.doc2.doc_name === "") {
      if (window.confirm("Are you sure you want to proceed without attaching the documents?") === false) {
        return "Resume";
      }
    }

    if (expvalidationoptions.includes(compProof.validation_option)) {
      if (compProof.experience_years === "") {
        compProof.experience_years = 0;
      }
      if (compProof.experience_months === "") {
        compProof.experience_months = 0;
      }
      if ((compProof.experience_years * 12 + compProof.experience_months) === 0) {
        return "Please fill in the length of your experience!";
      }
      if ([null, ""].includes(compProof.last_work_year)) {
        return "Please fill in the year you last worked with this experience!";
      }
      else {
        compProof.last_work_year = formatDate(compProof.last_work_year);
      }
      compProof.description2 = "";
    }
    else {
      compProof.experience_years = 0;
      compProof.experience_months = 0;
      compProof.last_work_year = null;
      if (!altvalidationoptions.includes(compProof.validation_option)) {
        compProof.description2 = "";
      }
    };
    setAttachOpen("Save");

    // Upload documents into cloudinary and update the proof details
    let ret_data = "";
    let doc_proof1 = doc_default;
    let doc_proof2 = doc_default;

    if (!hideAttachment) {
      let tempState = { ...compProof };
      if (filesToUpload.doc1 !== "") {
        let ret_data = await saveInCloud(filesToUpload.doc1);
        if (ret_data === "Failure") {
          return "Upload into cloud failed";
        }
        doc_proof1 = {
          doc_name: filesToUpload.doc1.name,
          doc_url: ret_data.url,
          doc_public_id: ret_data.public_id,
        };
        tempState.doc1 = doc_proof1;
      }

      if (filesToUpload.doc2 !== "") {
        ret_data = await saveInCloud(filesToUpload.doc2);
        if (ret_data === "Failure") {
          return "Upload into cloud failed";
        }
        doc_proof2 = {
          doc_name: filesToUpload.doc2.name,
          doc_url: ret_data.url,
          doc_public_id: ret_data.public_id,
        };
        tempState.doc2 = doc_proof2;
      }
      setCompProof({ ...tempState });
    }
    let msg;

    // Save - if new proof
    if (!compProof.proof_id)
    {
      //Save proof descriptions in competence_proof table along with cloudinary link
      await Axios.post(`${api}api/insertproof`, {compProof,  doc_proof1,  doc_proof2,  competenceID  },{...addTokenHeader()})
      .then((response) => {
        if (response.data === "Proof saved") {
          msg = "Saved";
          console.log("Proof Saved");
        } else {
          msg = "Failed";
          console.log("Proof Save failed");
        }
      })
      .catch((error) => {
        console.log(error);
        msg = "Saving the proof failed";
      });
    }
    else // Update existing proof
    {
      //Save proof descriptions in competence_proof table along with cloudinary link
      await Axios.post(`${api}api/updateproof`, {compProof,  doc_proof1,  doc_proof2,  competenceID  },{...addTokenHeader()})
      .then((response) => {
        if (response.data === "Proof Updated") {
          msg = "Updated";
          console.log(response.data);
        } else {
          msg = "Failed";
          console.log("Proof Update failed");
        }
      })
      .catch((error) => {
        console.log(error);
        msg = "Updating the proof failed";
      });
    }
    return msg;
  };

  const resetProof = (event) => {
    setFilesToUpload({ doc1: "", doc2: "" });
    setAttachOpen("");
    setHideAttachment(false);
    setShowExperience(false);
    setCanDelete1(false);
    setCanDelete2(false);
  };

  // event handler for Proof Description
  const handleProofDesc = (event) => {
    setCompProof({...compProof,  [event.target.name]: event.target.value });
  };

  const numberInputKeyDown = (event) => {
    // Allow numeric input (0-9) and specific control keys (e.g., Backspace, Arrow keys)
    const allowedKeys = [ "0","1","2","3","4","5","6","7","8","9","Backspace","ArrowLeft","ArrowRight","Home","End"];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleYear = (event) => {
    const year_value = event.target.value.slice(0, 2);
    setCompProof({...compProof,[event.target.name]: year_value});
  };

  const handleMonth = (event) => {
    let month_value = event.target.value.slice(0, 2);
    if (month_value > 11) {
      month_value = 11;
    }
    setCompProof({...compProof,  [event.target.name]: month_value });
  };

  const handleYearPicked = (event) => {
    setCompProof((prevState) => ({...prevState,last_work_year: event}));
  };

  const handleValidOption = (selectedOption) => {
    let tempState = { ...compProof };
    tempState.validation_option = selectedOption ? selectedOption.label : "Validation Option";
    setHideAttachment(altvalidationoptions.includes(selectedOption.label));

    if (expvalidationoptions.includes(selectedOption.label)) {
      setShowExperience(true);
    } else {
      tempState.experience_months = 0;
      tempState.experience_years = 0;
      tempState.last_work_year = new Date();
      setShowExperience(false);
    }
    setCompProof(tempState);
  };

  // When 'Attach Proof'button is clicked, open modal for attaching files
  const attachProof = (docname) => async (event) => {
    if (myPlanInfo.active === 1) {
      setAttachOpen(docname);
    } else {
      setShowModalPlan(true);
    }
  };

  // When 'Attach Proof'button is clicked, open modal for attaching files
  const attachRemove = async (publicId, proofID, docSection, file_name) => {
    //// delete document from cloudinary
    let file_type = RESOURCE_TYPES[allowedFileExtensions.indexOf(file_name.split(".").pop())];

    Axios.post(`${api}api/deleteCloudDocs`, {publicId, file_type})
      .then((response1) => {
      console.log("Deleted document in cloudinary with the Public ID ", publicId);
      Axios.post(`${api}api/removeDocument`, {proofID,  docSection })
      .then((response) => {
        if (response.data === "Removed Document Info") {
          let tempProof = compProof;
          if (docSection === "doc1") {
            tempProof.doc1 = doc_default;
            setFilesToUpload({ ...filesToUpload, doc1: "" });
            setCanDelete1(false);
          }
          else if(docSection === "doc2") {
            tempProof.doc2 = doc_default;
            setFilesToUpload({ ...filesToUpload, doc2: "" });
            setCanDelete2(false);
          }
          setCompProof(tempProof);
          console.log(response.data);
          return "Success";
        } else {
          console.log("Remove Document failed");
          return "Failure";
        }
      })
    })
    .catch((error1) => {
      return "Failure";
    });
  };

  //set the timer for display user alert & error messages
  const showMessageForSeconds = (text, seconds) => {
    setMessage(text);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, seconds * 1000);
  };

  //Handling the drag and drop and select file functionality
  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileSize = file.size / (1024 * 1024);
    const fileExtension = file.name.split(".").pop();

    if (!allowedFileExtensions.includes(fileExtension.toLowerCase())) {
      showMessageForSeconds( "Please attach files of type " + allowedFileExtensions, 5 );
      return;
    }
    if (fileSize > maxFilesize) {
      showMessageForSeconds(`The selected file size ${fileSize} MB is greater than the limit ${maxFilesize}. `, 10 );
      return;
    }
    setFilesToUpload({ ...filesToUpload, [[attachOpen]]: acceptedFiles[0] });
    let tempState = compProof;
    if (attachOpen === "doc1") {
      tempState.doc1.doc_name = file.name;
    } else if (attachOpen === "doc2") {
      tempState.doc2.doc_name = file.name;
    }
    setCompProof(tempState);
    setAttachOpen("");
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  ////functions call plan
  useEffect(() => {
    // Call the API endpoint
    fetchMyPlanUserInfo(user_id)
      .then((data) => {
        setMyPlanInfo(data.myPlanData ? data.myPlanData : "");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCloseModal = () => {
    setShowModalPlan(false);
  };

  return (
      <div className="proof-container" id="compproof-active">
        <textarea
          className="proof-description"
          value={compProof?.description}
          name="description"
          onChange={handleProofDesc}
          placeholder="Description (Max 200 characters)"
          required
          type="text"
          maxLength={200}
        />
        <Select
          id="proof-container-select"
          styles={customStyles}
          required
          value={compProof?.validation_option
              ? validationoptions.find((option) => option.label === compProof?.validation_option)
              : null
          }
          options={validationoptions}
          name="validation_option"
          placeholder="Validation Option"
          onChange={handleValidOption}
          theme={(theme) => ({...theme, colors: {...theme.colors, primary25: "#F7942C", primary: "#003399"}})}
        />
        {showExperience && (
          <span>
            <div className="Exp-Duration-Years">
              <span className="year-Duration-span">Duration - Years</span>
              <input
                className="year-month"
                name="experience_years"
                value={compProof?.experience_years}
                onChange={handleYear}
                type="number"
                min="0"
                max="99"
                onKeyDown={numberInputKeyDown}
              />
            </div>
            <div className="experience-container">
              <div className="Exp-Year-last">
                <span className="year-picker-span">
                  Year last applied
                </span>
                <DatePicker
                  className="year-picker"
                  value={new Date(compProof?.last_work_year)}
                  selected={new Date(compProof?.last_work_year)}
                  onChange={handleYearPicked}
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={new Date()}
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 100
                  )}
                  onKeyDown={numberInputKeyDown}
                />
              </div>
              <div className="Exp-Year-month">
                <span className="year-month-span">Months</span>
                <input
                  className="year-month"
                  name="experience_months"
                  value={compProof?.experience_months}
                  onChange={handleMonth}
                  type="number"
                  min="0"
                  max="999"
                />
              </div>
            </div>
          </span>
        )}

        {!hideAttachment && (
          <div>
            <div className="proof-attach-container">
{/*              <input
                className="proof-attach"
                placeholder="Attachment"
                name="doc_name"
                value={compProof?.doc1?.doc_name}
                onChange={handleProofDesc}
              />
 */}
              <input
                placeholder="Attachment"
                name="doc_name"
                value={compProof?.doc1?.doc_name}
                className={compProof?.doc1?.doc_url === "" ? "proof-empty" : "proof-attach"}
                readOnly
                onClick={() => {
                  if (compProof?.doc1?.doc_url !== "") {
                    const fileExtension = compProof?.doc1?.doc_name.split(".").pop();
                    if (["pdf", "jpg", "png"].includes(fileExtension.toLowerCase())) {
                      window.open(compProof?.doc1?.doc_url);
                    } else {
                      window.open(`https://docs.google.com/viewer?embedded=true&url=${compProof?.doc1?.doc_url}`);
                    }
                }}}
              />
{/*                 {compProof?.doc1?.doc_name}
              </button>
 */}
               {!canDelete1 && (
                <button className="button-attach">
                  <img
                    className="img-sizing"
                    src={AttachDoc}
                    alt="Attachment"
                    onClick={attachProof("doc1")}
                  />
                </button>
              )}
              {canDelete1 && (
                <button className="button-attach">
                  <img
                    className="img-sizing"
                    src={Delete}
                    alt="Attachment"
                    onClick={() => {
                      setModalObj({...deleteAttach, param1: "doc1"});
                      setShowAlertConfirmModal(true);
                    }}
                  />
                </button>
              )}
              <button
                className="proof-button"
                onClick={() => {
                  let promiseObject = Promise.resolve(saveProof(competence_id));
                  promiseObject.then((answer) => {
                    if (["Saved", "Updated"].includes(answer)) {
                      resetProof();
                      onAnswer({answer: answer, objProof: compProof});
                    } else if (!(["Resume"].includes(answer))) {
                      onAnswer({answer: answer, objProof: compProof});
                      setModalObj({...saveAlert, text: answer});
                      setShowAlertConfirmModal(true);
                    }
                  });
                }}
              >
                Save
              </button>
            </div>
            <div className="proof-attach-container">
{/*               <input
                className="proof-attach"
                placeholder="Attachment"
                name="doc_name2"
                value={compProof?.doc2?.doc_name}
                onChange={handleProofDesc}
              />
 */}
              <input
                placeholder="Attachment"
                name="doc_name"
                value={compProof?.doc2?.doc_name}
                className={compProof?.doc2?.doc_url === "" ? "proof-empty" : "proof-attach"}
                readOnly
                onClick={() => {
                  if (compProof?.doc2?.doc_url !== "") {
                    const fileExtension = compProof?.doc2?.doc_name.split(".").pop();
                    if (["pdf", "jpg", "png"].includes(fileExtension.toLowerCase())) {
                      window.open(compProof?.doc2?.doc_url);
                    } else {
                      window.open(`https://docs.google.com/viewer?embedded=true&url=${compProof?.doc2?.doc_url}`);
                    }
                }}}
              />

              {!canDelete2 && (
                <button className="button-attach">
                  <img
                    className="img-sizing"
                    src={AttachDoc}
                    alt="Attachment"
                    onClick={attachProof("doc2")}
                  />
                </button>
              )}
              {canDelete2 && (
                <button className="button-attach">
                  <img
                    className="img-sizing"
                    src={Delete}
                    alt="Attachment"
                    onClick={() => {
                      setModalObj({...deleteAttach, param1: "doc2"});
                      setShowAlertConfirmModal(true);
                    }}
                  />
                </button>
              )}
              <button
                className="proof-button"
                onClick={() => {
                  resetProof();
                  onAnswer({answer: "Cancel", objProof: compProofIP});
                }}
              >
                Cancel
              </button>
            </div>

            {(attachOpen === "doc1" || attachOpen === "doc2") && (
              <div className="proof-drag-area" {...getRootProps()}>
                <input
                  type="file"
                  accept={allowedFileExtensions}
                  {...getInputProps()}
                />
                <img
                  src={AttachDoc}
                  alt="Attachment"
                  className="img-sizing-drag"
                  style={{ color: "#8C8888", cursor: "pointer" }}
                />
                <p>
                  OR <br /> Drag & Drop here
                </p>
                <div className="filesize-message">
                  <span>*</span> Maximum file size:50MB/file
                </div>
              </div>
            )}

            {attachOpen === "Save" && (
              <div className="proof-drag-area">
                {/**Progress Bar */}
                {uploadInProgress ? (
                  <div className="upload-progress">
                    <div
                      className="upload-progress-bar"
                      role="progressbar"
                      style={{ width: `${uploadInProgress}%`}}
                      aria-valuenow={uploadInProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                ) : null}

              </div>
            )}
            {/**User alert and error messages */}
            <>
              {showMessage && (
                <div id="competence-alert-messages">{message}</div>
              )}
            </>
          </div>
        )}
        {hideAttachment && (
          <div>
            <div className="proof-attach-container">
              <input
                className="proof-attach"
                value={compProof?.description2}
                name="description2"
                onChange={handleProofDesc}
                maxLength={100}
              />
              <button
                className="proof-button"
                onClick={() => {
                  let promiseObject = Promise.resolve(saveProof(competence_id));
                  promiseObject.then((answer) => {
                    if (["Saved", "Updated"].includes(answer)) {
                      resetProof();
                      onAnswer({answer: answer, objProof: compProof});
                    } else {
                      onAnswer({answer: answer, objProof: compProof});
                      setModalObj({...saveAlert, text: answer});
                      setShowAlertConfirmModal(true);                    }
                  });
                }}
              >
                Save
              </button>
              <button
                className="proof-button"
                onClick={() => {
                  resetProof();
                  onAnswer({answer: "Cancel", objProof: compProofIP});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      <Modal
        isOpen={showModalPlan}
        onRequestClose={handleCloseModal}
        contentLabel="Proof Upload"
        className="modal-competence-view"
        overlayClassName="overlay"
      >
        <h2>This is a paid feature. Please explore the plans.</h2>
        <div className="competence-btn-modal" id="btn-modal-plan">
          <Link to="/updateplan">
            <button className="competence-btn-plan">Explore plans</button>
          </Link>
          <button className="competence-btn-close" onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </Modal>

      {showAlertConfirmModal && (
        <AlertConfirmModal
          messageObj={modalObj}
          onButton1={() => {
            setShowAlertConfirmModal(false);
            if (modalObj.module === "deleteAttach") { 
              if (modalObj.param1 === "doc1") {
                attachRemove(compProof?.doc1?.doc_public_id, compProof?.proof_id, modalObj.param1, compProof?.doc1?.doc_name);
              }
              else if (modalObj.param1 === "doc2") {
                attachRemove(compProof?.doc2?.doc_public_id, compProof?.proof_id, modalObj.param1, compProof?.doc2?.doc_name);
              }
            };
            setModalObj({});
          }}
          onButton2={() => {
            setShowAlertConfirmModal(false);
            setModalObj({});
          }}
        />
      )}
    </div>
  );
};

export default ProofSection;
