import React from 'react'
import '../pages/style/Sustainability.css'
import {Link} from "react-router-dom";
import image_28 from "../images/image_28old.png"

export default function Sustainability() {
  return (
    <div>
      <div className='s-text'>
      <h1 > Sustainability </h1>
      <p> Skillur is committed to engaging with all 17 Sustainable Development Goals (SDGs) set by the United Nations. Categorizing these goals into four different 
        perspectives – Life essential, Life potential, Life relational, and Life economical – shows a comprehensive approach to addressing the various aspects of sustainability.
      Look West, North, East, and South to identify basic skills gaps in communication, problem-solving, and technical abilities essential for survival and functioning.
      </p>
      <p>Examine areas lacking in creativity, innovation, leadership, and advanced technical skills, focusing on unlocking growth and development potential.
      Seek gaps in teamwork, empathy, cross-cultural communication, and conflict resolution by looking across regions known for strong relational competencies.
      </p>
      <p>
      Analyze financial literacy, business acumen, and market awareness gaps across different directions for achieving economic growth and stability.
      Skillur's role as a hub to connect and direct competence towards these gaps is crucial. By facilitating knowledge sharing, collaboration, and actionable initiatives. 
      It's essential to not only identify the gaps but also develop strategies to bridge them, whether through education, technology, policy advocacy, or community engagement.
      </p>
      </div>
      <div className='s-btn'>
      <button><Link to="/intellgencereport">Intelligence Report </Link></button>
      </div>
      <div className='s-img'>
        <img src={image_28} alt="" />
      </div>
    </div>
  )
}
