import React, { useState, useEffect } from "react";
import "./PersonDataDetails.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Select from "react-select";
import countriesOptions from "../../../src/data/countries";
import citizenshipOptions from "../../../src/data/countriesCitizenship";
import moment from "moment";
import HelpButton from "../HelpButton/HelpButton";
import { addTokenHeader } from "../../utility/tokenUtility";

const PersonDataDetails = ({ isLoginFirst }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    errorMessages: {
      date_of_birth: "",
    },
    city: "",
    postal_code: "",
    country: null,
    mobile_number: "",
    citizenship: [],
    gender: "",
    work_permit: [],
  });
  // to check sanitized phone number input
  const [sanitizedPhoneNumber, setSanitizedPhoneNumber] = useState("");
  //variable to keep track of the selected citizenship option.
  const [selectedCitizenshipOption, setSelectedCitizenshipOption] =
    useState(null);

  const user_id = localStorage.getItem("user_id");
  const api = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await Axios.get(`${api}api/personaldata/${user_id}`, {
        ...addTokenHeader(),
      });
      const userData = response.data;

      const dateOfBirth = userData.date_of_birth
        ? //using moment convert UTC date to local time
          moment.utc(userData.date_of_birth).local().format("YYYY-MM-DD")
        : null;

      setFormData((prevData) => ({
        ...prevData,
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        email: userData.email || "",
        date_of_birth: dateOfBirth || null,
        city: userData.city || "",
        postal_code: userData.postal_code || "",
        country: userData.country || "",
        mobile_number: userData.mobile_number || "",
        citizenship: userData.citizenship || [],
        gender: userData.gender || "",
        work_permit: userData.work_permit || [],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Date validation

  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 10); // Minimum date: 10 years ago

    if (selectedDate > currentDate) {
      return "Date of birth cannot be a future date.";
    }

    if (selectedDate > minDate) {
      return "Please select a date that is at least 10 years ago.";
    }

    return "";
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateDate(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errorMessages: {
        ...prevData.errorMessages,
        [name]: errorMessage,
      },
    }));
  };

  const handleClickSave = async (e) => {
    e.preventDefault();
    if (formData.first_name === "") {
      alert("First Name is required");
      return;
    }

    // Define field name mapping
    const fieldNames = {
      date_of_birth: "Date of Birth",
      city: "City",
      postal_code: "Postal/Zip code",
      mobile_number: "Phone number",
      gender: "Gender",
      country: "Country",
      citizenship: "Citizenship",
      work_permit: "Work Permits",
    };

    // Check if other required fields are empty
    const mandatoryFields = Object.keys(fieldNames).filter(
      (field) =>
        field !== "country" &&
        field !== "citizenship" &&
        field !== "work_permit"
    );

    for (const field of mandatoryFields) {
      if (formData[field] === "" || formData[field] === null) {
        alert(`${fieldNames[field]} is required`);
        return;
      }
    }

    // Check if country is empty
    if (formData.country.length === 0) {
      alert(`${fieldNames.country} is required`);
      return;
    }

    // Check if citizenship is empty
    if (formData.citizenship.length === 0) {
      alert(`${fieldNames.citizenship} is required`);
      return;
    }

    // Check if work permit is empty
    // if (formData.work_permit.length === 0) {
    //   alert(`${fieldNames.work_permit} is required`);
    //   return;
    // }

    //new Check if date of birth is valid before making the API call
    const dateErrorMessage = validateDate(formData.date_of_birth);
    if (dateErrorMessage) {
      alert(dateErrorMessage);
      return;
    }

    // Check if citizenship exceeds 10 values
    if (formData.citizenship.length > 10) {
      alert("Your citizenship can only take a maximum of 10 values.");

      return;
    }

    try {
      await Axios.post(
        `${api}api/personaldata`,
        {
          ...formData,
          user_id: user_id,
          //using moment convert to convert data format
          date_of_birth: moment
            .utc(formData.date_of_birth)
            .format("YYYY-MM-DD"),
          //
        },
        { ...addTokenHeader() }
      ).then(alert("Updating the changes"));

      if (isLoginFirst === 2) {
        await Axios.put(
          `${api}api/updateFirstLogin`,
          {
            user_id: user_id,
            landing_page: "personalData",
          },
          { ...addTokenHeader() }
        ).then((response) => {
          // console.log("First login updated");
        });
        navigate("/dashboard/landingPage");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  // ...

  const handleCitizenshipChange = (selectedOptions) => {
    // Check if "Travel Document Only" or "No Citizenship" is selected
    const isTravelDocumentOnlySelected = selectedOptions.some(
      (option) => option.value === "Travel Document only"
    );
    const isNoCitizenshipSelected = selectedOptions.some(
      (option) => option.value === "No Citizenship"
    );

    // If either "Travel Document Only" or "No Citizenship" is selected, allow them to be selected but clear any other options
    if (isTravelDocumentOnlySelected || isNoCitizenshipSelected) {
      // Clear the "Travel Document Only" or "No Citizenship" options if they were previously selected
      const updatedSelectedOptions = selectedOptions.filter(
        (option) =>
          option.value === "Travel Document only" ||
          option.value === "No Citizenship"
      );
      setFormData({
        ...formData,
        citizenship: updatedSelectedOptions,
      });
    } else {
      // If other options are selected, allow adding them up to a maximum of 10 values
      if (selectedOptions.length > 10) {
        alert("Your citizenship can only take a maximum of 10 values.");
        return;
      }
      setFormData({
        ...formData,
        citizenship: selectedOptions,
      });
    }
  };

  //
  //
  // const handleCitizenshipChange = (selectedOptions) => {
  //   //check if the number of selected citizenship values exceeds 10
  //   if (selectedOptions.length > 10) {
  //     alert("Your citizenship can only take a maximum of 10 values.");
  //     return;
  //   }

  //   const selectedCitizenshipValues = selectedOptions.map((option) => ({
  //     value: option.value,
  //     label: option.label,
  //   }));
  //   setFormData({
  //     ...formData,
  //     citizenship: selectedCitizenshipValues,
  //   });
  // };

  // handle phone number input changes and sanitize the input
  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const sanitizedInput = inputPhoneNumber.replace(/[^+0-9]/g, ""); // input only "+" and "0-9"
    setSanitizedPhoneNumber(sanitizedInput);
    setFormData((prevData) => ({
      ...prevData,
      mobile_number: sanitizedInput,
    }));
    //check to set the mobile_number to an empty string if the sanitizedInput is empty
    if (sanitizedInput === "") {
      setFormData((prevData) => ({
        ...prevData,
        mobile_number: "",
      }));
    }
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      country: e.label,
    });
  };

  const handleWorkPermitChange = (selectedOptions) => {
    const selectedWorkPermitValues = selectedOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    setFormData({
      ...formData,
      work_permit: selectedWorkPermitValues,
    });
  };

  // const handleWorkPermitChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     work_permit: e.label,
  //   });
  // };

  const handleGenderChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      gender: event.target.value,
    }));
  };
  /** Getting helpbutton data from backend **/
  const [
    personaldataLandingpageQuestions,
    setpersonaldataLandingpageQuestions,
  ] = useState([]);
  useEffect(() => {
    let help_title;
    if (isLoginFirst === 2) {
      help_title = "landing page-personaldata";
      Axios.get(`${api}api/questions/${help_title}`, { ...addTokenHeader() })
        .then((response) => {
          setpersonaldataLandingpageQuestions(response.data);
        })

        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div className="PersonDataDetails">
      <form className="form-PersonDataDetails">
        <div className="firstName">
          <label className="label-Data">
            First name <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="first_name"
              placeholder=""
              className="input"
              defaultValue={formData.first_name}
              disabled
            />
          </div>
        </div>
        <div className="lastName">
          <label className="label-Data">
            Last name <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="last_name"
              placeholder=""
              className="input"
              defaultValue={formData.last_name}
              disabled
            />
          </div>
        </div>
        <div className="emailAdress">
          <label className="label-Data">
            Email address <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="email"
              placeholder=""
              className="input"
              defaultValue={formData.email}
              disabled
            />
          </div>
        </div>
        <div className="dateOfBirth">
          <label className="label-Data">
            Date of birth <span id="label-mandatory">*</span>
          </label>
          <div className="dob-pd-dtpkr">
            <input
              type="date"
              name="date_of_birth"
              className="input"
              value={formData.date_of_birth}
              onChange={handleChange}
              required
              onBlur={handleBlur}
              pattern="^(?!Invalid Date)(.*)$"
              title={formData.errorMessages.date_of_birth}
            />
          </div>
          {formData.errorMessages.date_of_birth && (
            <div className="error-message-personalData">
              {formData.errorMessages.date_of_birth}
            </div>
          )}
        </div>
        <div className="city">
          <label className="label-Data">
            City/Town <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="city"
              placeholder=""
              className="input"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="postalCode">
          <label className="label-Data">
            Postal/Zip code <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="postal_code"
              placeholder=""
              className="input"
              value={formData.postal_code}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="ppd-select">
          <label className="label-Data">
            Country <span id="label-mandatory">*</span>
          </label>
          <div>
            <Select
              className="custom-select"
              id="pd-opt-inp"
              name="country"
              dropdownPosition="auto"
              searchable={true}
              options={countriesOptions}
              required
              placeholder={formData.country}
              value={formData.country}
              onChange={handleCountryChange}
              type="select"
            />
          </div>
        </div>
        <div className="mobileNumber">
          <label className="label-Data">
            Phone number <span id="label-mandatory">*</span>
          </label>
          <div>
            <input
              type="text"
              name="mobile_number"
              placeholder=""
              className="input"
              value={formData.mobile_number}
              onChange={handlePhoneNumberChange}
              required
              style={{ WebkitAppearance: "none" }}
            />
          </div>
        </div>
        <div className="ppd-select-ctz">
          <label className="label-Data">
            Your citizenship <span id="label-mandatory">*</span>
          </label>

          <Select
            className="custom-select"
            id="pd-opt-inp"
            dropdownPosition="auto"
            searchable={true}
            options={citizenshipOptions}
            value={formData.citizenship}
            name="citizenship"
            isMulti
            onChange={handleCitizenshipChange}
            required
            isClearable={true}
          />
        </div>
        <div className="ppd-select">
          <label className="label-Data">
            Work Permits
            {/* <span id="label-mandatory">*</span> */}
          </label>
          <Select
            className="custom-select"
            id="pd-opt-inp"
            dropdownPosition="auto"
            searchable={true}
            options={countriesOptions}
            // placeholder={formData.work_permit}
            value={formData.work_permit}
            onChange={handleWorkPermitChange}
            type="select"
            isMulti
            // required
            // type="select"
            name="work_permit"
          />
        </div>
        <div className="gender-pp">
          <label className="label-Data">
            Gender <span id="label-mandatory">*</span>
          </label>
          <div className="gender-inp">
            <input
              type="radio"
              id="man"
              name="gender"
              value="man"
              checked={formData.gender === "man"}
              onChange={handleGenderChange}
            />
            <label htmlFor="man">Man</label>

            <input
              type="radio"
              id="woman"
              name="gender"
              value="woman"
              checked={formData.gender === "woman"}
              onChange={handleGenderChange}
            />
            <label htmlFor="woman">Woman</label>

            <input
              type="radio"
              id="not specified"
              name="gender"
              value="not specified"
              checked={formData.gender === "not specified"}
              onChange={handleGenderChange}
            />
            <label htmlFor="not specified">Not specified</label>
          </div>
        </div>

        <button className="btn-PersonDataDetails" onClick={handleClickSave}>
          Save changes
        </button>
        <div>
          {isLoginFirst === 2 && (
            <HelpButton questions={personaldataLandingpageQuestions} />
          )}
        </div>
      </form>
    </div>
  );
};

export default PersonDataDetails;
