import React, { createContext, useState } from "react";
import Axios from "axios";
import { addTokenHeader } from '../../utility/tokenUtility'

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const api = process.env.REACT_APP_API_URL;
  const [notifications, setNotifications] = useState([]);
  const [hasNotifications, setHasNotifications] = useState(false);

  // Function to add a new notification
  const addNotification = (notification) => {
    setNotifications([...notifications, notification]);
    setHasNotifications(true);
    localStorage.setItem("notificationsChecked", "true");
    storeNotification(notification.message);
  };

  /* Function which calls API for database entry */

  const storeNotification = async (message) => {
    try {
      const user_id = localStorage.getItem("user_id");
      await Axios.post(`${api}api/notifications`, {
        message: message,
        read_status: false,
        user_id: user_id,
      },{...addTokenHeader()}).then((response)=>{

      })

    } catch (error) {
      console.error("Failed to store notification:", error);
    }
  };

  return (
    <NotificationContext.Provider
      value={{ hasNotifications, setHasNotifications, addNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
