import React from "react";

const AlertConfirmModal = ({ messageObj, onButton1, onButton2 }) => {
  return (
    <div className="modal">
      <div className="modal-delete-confirm">
        <h2>{messageObj.title}</h2>
        <p className="modal-text">{messageObj.text}</p>
        {messageObj.button2 === "" && (
          <button className="modal-button one-button" onClick={onButton1}>{messageObj.button1}</button>
        )}
        {messageObj.button2 !== "" && (
          <div className="modal-button-container">
            <button className="modal-button" onClick={onButton1}>{messageObj.button1}</button>
            <button className="modal-button" onClick={onButton2}>{messageObj.button2}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertConfirmModal;
