import React from "react";
import "../pages/style/IntelligenceThankscard.css";
import { useNavigate } from "react-router";


function IntelligenceThankscard() {
  let navigate = useNavigate();

  const handleData = () => {
    navigate("/");
  };
  return (
    <div className="container-intelligenceThankscard">
      <h1 className="heading-intelligenceThankscard">Thank you!</h1>
      <p className="message-intelligenceThankscard"> Report submitted successfully</p>
      <button className="button-intelligenceThankscard" onClick={handleData}>
        OK
      </button>
    </div>
  );
};



export default IntelligenceThankscard;
