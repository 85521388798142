import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PaymentMethod from "../../stripe/PaymentMethod";
import "../../pages/style/Pricing.css";
import free from "../../images/plan/free_moon.svg";
import startPlanMonth from "../../images/plan/platinum_moon.svg";
import startPlanYear from "../../images/plan/Premium_moon.svg";

Modal.setAppElement("#root");

const Plan = ({
  id,
  title,
  price,
  validityDuration,
  validityName,
  amount,
  image,
  description,
  currency,
  banner,
  isSelected,
  handleClick,
  myPlanActive,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalActive, setShowModalActive] = useState(false);
  const user = localStorage.getItem("user");

  const getImageByDatabaseName = (image) => {
    switch (image) {
      case "free":
        return free;
      case "startPlanMonth":
        return startPlanMonth;
      case "startPlanYear":
        return startPlanYear;
      default:
        return null; // Return a default image or handle missing cases
    }
  };

  const handleButtonClick = () => {
    // need check here if my plan active or not
    if (myPlanActive === 1) {
      setShowModalActive(true);
    } else {
      setShowModal(true);
    }
  };

  //// close modal msg
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalActive = () => {
    setShowModalActive(false);
  };

  const handleClickInternal = () => {
    if (id !== 1) {
      handleClick(id);
    }
  };

  return (
    <div
      className={`plan-type ${isSelected && user ? "active-select" : ""}`}
      onClick={handleClickInternal}
    >
      <div className="plan-icon">
        <img src={getImageByDatabaseName(image)} alt="" />
        {banner !== "" && <div className="plan-save-banner">{banner}</div>}
      </div>
      <div className="plan-price">
        {title} {validityName}
      </div>
      <div className="plan-features">
        <ul>
          {description &&
            description.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      </div>
      {/* <div className='plan-name'><p>{title}</p></div> */}
      {user === "true" && id !== 1 && (
        <button className="btn-plan" onClick={handleButtonClick}>
          SELECT{" "}
        </button>
      )}
      {user !== "true" && id !== 1 && (
        <Link to="/loginform">
          <button className="btn-plan"> Login </button>
        </Link>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Payment Method"
        className="modal"
        overlayClassName="overlay"
      >
        <PaymentMethod
          planId={id}
          title={title}
          price={price}
          validityDuration={validityDuration}
          validityName={validityName}
          amount={amount}
          description={description}
          currency={currency}
          handleCloseModal={handleCloseModal}
        />
      </Modal>

      <Modal
        contentLabel="active"
        className="modal-plan-active"
        overlayClassName="overlay"
        isOpen={showModalActive}
        onRequestClose={handleCloseModal}
      >
        <h2>
          "Oops! You already have this plan active. You can't purchase the same
          plan twice."{" "}
        </h2>
        <button
          className="modal-plan-active-close"
          onClick={handleCloseModalActive}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Plan;
