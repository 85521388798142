import React from "react";
import "./DeactivateAccount.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function DeactivateAccount() {
  return (
    <div className="popup-background-DeactivateAccount">
      <div className="popup-DeactivateAccount">
        <div className="popup-inner-DeactivateAccount">
          <div>
            <h2>
              <FontAwesomeIcon icon={faCircleInfo} /> Deactivate account
            </h2>
            <div>
              <div className="text">
                <p className="boldText">
                  Deactivating your account is temporary.
                </p>{" "}
                Your account can be restored within the next 6 months. To
                restore it you will have to login to your account at Skillur. If
                you don’t login during that time your account will be deleted.
              </div>
            </div>
            <div className="ButtonsRight">
              <Link to="/Settings">
                <p className="CancelButton">Cancel</p>
              </Link>
              <button type="submit" value="submit">
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeactivateAccount;
