import React, { useEffect, useState} from "react";
import "./LandingPage.css";
import CompetencyLandingPage from "../../../images/Icons_Landingpage/competency_landingPage.svg";
import PersonalDetailsLandingPage from "../../../images/Icons_Landingpage/personalDetails_landingPage.svg";
import YourTermsLandingPage from "../../../images/Icons_Landingpage/myTerms_landingPage.svg";
import FutureGoalsLandingPage from "../../../images/Icons_Landingpage/futureGoals_landingPage.svg";
import PersonalDetailsOrangeFaded3 from "../../../images/Icons_Landingpage/PersonalDetailsOrangeFaded3.svg";
import MyTermsOrangeFaded from "../../../images/Icons_Landingpage/MyTermsOrangeFaded.svg";
import FutureOrangeFaded from "../../../images/Icons_Landingpage/FutureOrangeFaded.svg";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useCustomEffect } from "./CustomHook";
import HelpButton from "../../HelpButton/HelpButton";
import { addTokenHeader } from '../../../utility/tokenUtility'

const LandingPage = () => {
  const [firstLogin, setFirstLogin] = useState(0);
  let navigate = useNavigate();
  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);
  const firstName = decoded["first_name"];
  const user_id = decoded["user_id"];
  const api = process.env.REACT_APP_API_URL;
  const [landingpageQuestions, setlandingpageQuestions] = useState([]);
  useEffect(() => {
    // Call the API endpoint
    Axios.get(`${api}api/datauserinfo`, { params: { user_id: user_id }, ...addTokenHeader() })
      .then((response) => {
        setFirstLogin(response.data.first_login);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }, []);
  /** Getting helpbutton data from backend **/
  useEffect(() => {
    const help_title = "landing Page";
    Axios.get(`${api}api/questions/${help_title}`,{...addTokenHeader()})
      .then((response) => {
        setlandingpageQuestions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //onClick function when the 'Competencies' box/button on the right side is clicked.
  const handleClickCompetence = (e) => {
    e.preventDefault();

    // setActiveCompetence(true);
    navigate("/dashboard/competenceMapping", {
      state: { first_login: firstLogin },
    });
  };

  //onClick function when the 'Personal Data' box/button on the right side is clicked.
  const handleClickProfile = (e) => {
    e.preventDefault();
    // setActiveProfile(true);
    navigate("/dashboard/personalData", { state: { first_login: firstLogin } });
  };

  //onClick function when the 'My Terms' box/button on the right side is clicked, also sending in the first_login.
  const handleClickMyTerms = (e) => {
    e.preventDefault();
    // setActiveMyTerms(true);
    navigate("/dashboard/myterms", { state: { first_login: firstLogin } });
  };

  //onClick function when the 'The Future' box/button on the right side is clicked.
  const handleClickFuture = (e) => {
    e.preventDefault();
    navigate("/dashboard/theFuture", { state: { first_login: firstLogin } });
  };

  //Calling the function from CustomHook.js to handle browser's 'Back' button.
  useCustomEffect();

  return (
    <>
      <div className="main-container">
        <div className="greetingTextContainer">
          <section className="welcomeMessage">
            Hi <strong>{firstName}!</strong>
            <br />
            <br />
            Welcome to new opportunities for your skills! These are the four
            steps towards your own Competence profile. The more areas you map,
            the better it reflects your competence.{" "}
          </section>
        </div>

        {/* The styling of the 4 buttons are based on the changes in the state of the 'Save' button from its respective pages */}

        <div
          className={
            [2, 3, 4, 5].includes(firstLogin)
              ? "landingPageContainers3"
              : "landingPageContainers"
          }
          onClick={handleClickCompetence}
        >
          <div className="iconAndNameContainer">
            <img className="imgIcons" src={CompetencyLandingPage} alt="" />
            <div className="titleLandingPage">Competencies</div>
          </div>
          <div
            className={
              [2, 3, 4, 5].includes(firstLogin) ? "sidebar3" : "sidebar2"
            }
          ></div>
        </div>

        <div
          className={
            [3, 4, 5].includes(firstLogin)
              ? "landingPageContainers3"
              : firstLogin === 2
              ? "landingPageContainers"
              : "landingPageContainers2"
          }
          onClick={handleClickProfile}
        >
          <div className="iconAndNameContainer">
            {[2, 3, 4, 5].includes(firstLogin) ? (
              <img
                className="imgIcons"
                src={PersonalDetailsLandingPage}
                alt=""
              />
            ) : firstLogin === 3 ? (
              <img
                className="imgIcons"
                src={PersonalDetailsLandingPage}
                alt=""
              />
            ) : (
              <img
                className="imgIcons_PD"
                src={PersonalDetailsOrangeFaded3}
                alt=""
              />
            )}
            <div
              className={
                [2, 3, 4, 5].includes(firstLogin)
                  ? "titleLandingPage"
                  : "titleLandingPageInactive"
              }
            >
              Personal Data
            </div>
          </div>
          <div
            className={
              [3, 4, 5].includes(firstLogin)
                ? "sidebar3"
                : firstLogin === 2
                ? "sidebar2"
                : "sidebar1"
            }
          ></div>
        </div>

        <div
          className={
            [4, 5].includes(firstLogin)
              ? "landingPageContainers3"
              : firstLogin === 3
              ? "landingPageContainers"
              : "landingPageContainers2"
          }
          onClick={handleClickMyTerms}
        >
          <div className="iconAndNameContainer">
            {[1, 2].includes(firstLogin) ? (
              <img className="imgIcons_MT" src={MyTermsOrangeFaded} alt="" />
            ) : (
              <img className="imgIcons" src={YourTermsLandingPage} alt="" />
            )}
            <div
              className={
                [3, 4, 5].includes(firstLogin)
                  ? "titleLandingPage"
                  : "titleLandingPageInactive"
              }
            >
              My Terms
            </div>
          </div>
          <div
            className={
              [4, 5].includes(firstLogin)
                ? "sidebar3"
                : firstLogin === 3
                ? "sidebar2"
                : "sidebar1"
            }
          ></div>
        </div>

        <div
          className={
            firstLogin === 5
              ? "landingPageContainers3"
              : firstLogin === 4
              ? "landingPageContainers"
              : "landingPageContainers2"
          }
          onClick={handleClickFuture}
        >
          <div className="iconAndNameContainer">
            {[1, 2, 3].includes(firstLogin) ? (
              <img className="imgIcons_MT" src={FutureOrangeFaded} alt="" />
            ) : (
              <img className="imgIcons" src={FutureGoalsLandingPage} alt="" />
            )}
            <div
              className={
                [4, 5].includes(firstLogin)
                  ? "titleLandingPage"
                  : "titleLandingPageInactive"
              }
            >
              The Future{" "}
            </div>
          </div>
          <div
            className={
              firstLogin === 5
                ? "sidebar3"
                : firstLogin === 4
                ? "sidebar2"
                : "sidebar1"
            }
          ></div>
        </div>
        <div>
          <HelpButton questions={landingpageQuestions} />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
