import React, { useState, useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Axios from "axios";
import "./Profile.css";
import profileDP from "../../../images/placeholderdp.jpg";
import MyPlan from "../../MyPlanComponents/MyPlan";
import PersonDataDetails from "../../PersonalDataComponents/PersonDataDetails";
import PDFGeneration from "../LandingPage/pdfGeneration";
import HelpButton from "../../HelpButton/HelpButton";
import { fetchMyPlanUserInfo } from '../../MyPlanComponents/fetchMyPlanUserInfoHooks'
import DummyPdf from "../LandingPage/Skillur_CompetenceDummyProfile.pdf"
import {NotificationContext} from "../../Navbar/NotificationContext";
import { addTokenHeader } from '../../../utility/tokenUtility'


function Profile({ authenticate }) {
  const img = localStorage.getItem("img");
  const { addNotification } = useContext(NotificationContext);
  const [showDataPersonData, setShowDataPersonData] = useState(false);
  const [showDataMyplan, setShowDataMyplan] = useState(false);
  const [isPDFVisible, setPDFVisible] = useState(false);
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [myPlanInfo, setMyPlanInfo] = useState({})
  const [values, setValues] = useState([]);
  const previewSource = img === "null" ? profileDP : img;
  const api = process.env.REACT_APP_API_URL
  const user_id = localStorage.getItem("user_id");
  const [profileQuestions, setprofileQuestions]=useState([]);
  


  /// take first name and last name 
  useEffect(() => {
    
    Axios.get(`${api}api/datauserinfo`,{ params: { user_id: user_id },...addTokenHeader(), })
    .then((response) => {
      setValues(response.data);
    });
  }, []);
   /** Getting helpbutton data from backend **/
    useEffect(() => {
    const help_title = "profile";
    Axios.get(`${api}api/questions/${help_title}`,{...addTokenHeader()})
      .then((response) => {
        setprofileQuestions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  ////for myplan 
  useEffect(() => {
    // Call the API endpoint
    fetchMyPlanUserInfo(user_id)
    .then(data => {
        
        setMyPlanInfo(data.myPlanData ? data.myPlanData : "" )
        if(data.message === "plan end") {
          addNotification({  
            message: "Subscription expired: renew to continue enjoying our services."
          });
          setShowModalPlan(true)
      } else if (data.message === "Email reminder"){
        console.log("Email reminder")
        // Add a notification
        addNotification({  
          message: "Your current subscription will end soon."
        });
      }
        // console.log (data)
})
    .catch(error => {
        // Handle the error
        console.error(error);
    });
}, [showDataMyplan, user_id]);

  ////for pdf preview 
  const handleButtonPdf = ()=> {
    
    if (myPlanInfo.active === 1) {
      // console.log("download pdf")
      setPDFVisible((prevVisible) => !prevVisible);
      setShowDataPersonData(false)
      setShowDataMyplan(false)
    } else {
      setShowModal(true)
    }
    
  }

  const handleCloseModal = ()=> {
    setShowModal(false)
    setShowModalPlan(false)
  }

  


  return (
    <>
        <div className="main-container">
          <div className="name-profile">
            
              <div className="pic-logo">
                <img
                  src={previewSource}
                  alt="uploadpic"
                />
              </div>
            <div>
                <h3>{values?.first_name} {values?.last_name}</h3>
            </div>
          </div>
          <div className="person-link">
            <ul>
              <li>
                <div
                  onClick={() => {
                    setShowDataPersonData(
                      (prevShowDataPersonData) => !prevShowDataPersonData
                    );
                    setShowDataMyplan(false);
                    setPDFVisible(false)
                  }}
                >
                  {showDataPersonData ? (
                    <span className="active-line">Personal Data</span>
                  ) : (
                    "Personal Data"
                  )}
                </div>
                {showDataPersonData && <PersonDataDetails />}
              </li>

              <li>
                <div
                  onClick={() => {
                    setShowDataMyplan(
                      (prevShowDataMyplan) => !prevShowDataMyplan
                    );
                    setShowDataPersonData(false);
                    setPDFVisible(false)
                  }}
                >
                  {showDataMyplan ? (
                    <span className="active-line">My Plan</span>
                  ) : (
                    "My Plan"
                  )}
                </div>
                {showDataMyplan && <MyPlan myPlanInfo={myPlanInfo} />}
              </li>
              <li>
                <div>
                    <span>
                      <Link to="/intellgencereport">Intelligence Report </Link>
                    </span>
                </div>
              </li>
              <li>
                <div onClick={handleButtonPdf}> 
                {isPDFVisible ? (
                    <span className="active-line">Profile Preview</span>
                  ) : (
                    "Profile Preview"
                  )}
                </div>
                {isPDFVisible && <PDFGeneration />}
                <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                contentLabel="Payment Method"
                className="modal-pdf-view"
                overlayClassName="overlay"
                >
              <h2>Please subscribe to view and download the PDF </h2>
              <div className="pdf-btn">
              <Link to="/updateplan"><button  className="pdf-btn-plan">Explore plans</button></Link>
                <a href={DummyPdf} rel="noopener noreferrer" target="_blank"> <button  className="pdf-btn-dumpfile" >View dummy PDF</button></a></div>
              </Modal>
              </li>
            </ul>
          </div>
          <div><HelpButton questions={profileQuestions} /></div>
          <Modal 
            className="modal-plan-active"
            overlayClassName="overlay"
            isOpen={showModalPlan}
            onRequestClose={handleCloseModal}
            >
            <h2>"Oops! Your plan has expired. Renew it or choose a new plan on the Pricing page to continue accessing its features." </h2>
            <button  className="modal-plan-active-close" onClick={handleCloseModal}>OK</button>
            </Modal>

        </div>
       

    </>
  );
}

export default Profile;
