import React, { useState } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';

const InformModal = ({ showModal, closeModal }) => {
  const [showInformModal, setShowInformModal] = useState(showModal);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const email = localStorage.getItem("email");
  const api = process.env.REACT_APP_API_URL;
  
  const [msgObj, setMsgObj] = useState({
    email: email, // Set your default values here
    subject: '',
    message: '',
    user_id: user_id,
  });

  const handleCloseInformModal = () => {
    closeModal();
    setShowInformModal(false);
  };

  const handleCloseThanksModal = () => {
    closeModal();
    setShowThanksModal(false);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();

    if (msgObj.subject === '' || msgObj.message === '') {
      window.alert('Please fill in the competence details!');
      return;
    }
    
    try {
      const response = await Axios.post(`${api}api/submit-form`, msgObj);

      if (response.status === 200) {
        setMsgObj({
          email: email,
          subject: '',
          message: '',
          user_id: user_id,
        });
        setShowInformModal(false);
        setShowThanksModal(true);
      } else {
        console.log('Data not submitted successfully');
        setShowInformModal(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModalData = (event) => {
    setMsgObj((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <Modal
        isOpen={showInformModal}
        onRequestClose={handleCloseInformModal}
        contentLabel="Proof Upload"
        className="modal-competence-view"
        overlayClassName="overlay"
      >
        <h2>Inform Us</h2>
        <div className="inform-modal-content">
          <label>Competence to be added</label>
          <input
            type="text"
            className="inform-modal-input"
            placeholder="Fill Competence Title..."
            name="subject"
            value={msgObj.subject}
            maxLength={100}
            onChange={handleModalData}
            required
          />
        </div>
        <div className="inform-modal-content">
          <label>Description</label>
          <textarea
            type="text"
            className="inform-modal-textarea"
            placeholder="Fill Competence Description..."
            value={msgObj.message}
            name="message"
            rows="4"
            maxLength={200}
            required
            onChange={handleModalData}
          ></textarea>
        </div>
        <div className="competence-btn-modal">
          <button
            className="competence-btn-inform"
            onClick={handleSendMessage}
          >
            Send Message
          </button>
          <button
            className="competence-btn-inform"
            onClick={handleCloseInformModal}
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showThanksModal}
        onRequestClose={handleCloseThanksModal}
        contentLabel="Proof Upload"
        className="modal-thanks"
        overlayClassName="overlay"
      >
        <h2>
          Thanks for informing Skillur support team <br />
          about the missing competence!
          <br />
          <br />
          Skillur will review and will get back
          <br />
          to you soon in this regard!
        </h2>
        <div className="competence-btn-modal">
          <button
            className="competence-btn-inform"
            onClick={handleCloseThanksModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default InformModal;
