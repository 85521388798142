import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import DummyPdf from "./Skillur_CompetenceDummyProfile.pdf"
import './LandingPage.css';
import { useCustomEffect } from "./CustomHook";
import React, { useState, useEffect } from "react";
import { fetchMyPlanUserInfo } from '../../MyPlanComponents/fetchMyPlanUserInfoHooks'
import Modal from 'react-modal';

const LandingThanksModal = () => {

  const [myPlanInfo, setMyPlanInfo] = useState({})
  const [showModalFreePlan, setShowModalFreePlan] = useState(false);
  let navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const decoded = jwt_decode(token);
  const firstName = decoded['first_name'];

  const handleClickContinue = (e) => {
    e.preventDefault();
    navigate("/dashboard/profile");

  };

  useEffect(() => {
    // Call the API endpoint
    fetchMyPlanUserInfo(user_id)
    .then(data => {
        
        setMyPlanInfo(data.myPlanData ? data.myPlanData : "" )
        if(data.myPlanData.myplan_title === "Trial Plan") {

          setShowModalFreePlan(true)
        }
      })
    },[])

    const handleCloseModal = ()=> {
      setShowModalFreePlan(false)
    }

  //Calling the function from CustomHook.js to handle browser's 'Back' button.
  useCustomEffect();

  return (
    <div className="thanksModal-mainContainer">
      <div className="thanksModal-innerContainer">

      <h3>Hi {firstName}!</h3>
      <p>Thank you for sharing your details. If you wish to view a sample Competence Profile PDF,
        <br /> <br /> click on the Preview button else click on Continue button to skip .</p>

      <div className="thanksModal-button">
        <a href={DummyPdf} rel="noopener noreferrer" target="_blank">
          <button className="previewButton">
            Preview
          </button>
        </a>
        <button className="continueButton" onClick={handleClickContinue}>Continue</button>
      </div>
      <Modal 
            className="thanksModal-freePlan"
            overlayClassName="overlay"
            isOpen={showModalFreePlan}
            onRequestClose={handleCloseModal}
            >
          <div className="thanksModal-freePlan-container">
            <p>🎉 Congratulations! 🎉</p>
            {/* <p>You've completed competence mapping and we're excited to offer you a <span>{myPlanInfo.validity_duration}</span> free trial plan,
          giving you full access to our app.</p> */}
          <p>You've completed competence mapping and we're excited to offer you
          full access to our services.</p>
          <button onClick={handleCloseModal}>OK</button>
        </div>
        
          </Modal>

      <div className="thanksModal-note">
        <p>Note: The PDF generated is just for your reference. You can view your Competence Profile PDF in the dashboard.</p>
      </div>
      </div>
    </div>
  )
}
export default LandingThanksModal;