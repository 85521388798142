export const addTokenHeader = () => {
    const token = localStorage.getItem("token");
    
    if (token) {
        return {
        headers: {
            "x-access-token": token,
        },
        };
    }
    
    return {}; // Return an empty object if there's no token (e.g., for anonymous requests).
    };