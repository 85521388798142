import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function FaqList({ group_title }) {

  const [faqs, setFaqs] = useState([]);
  const api = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(`${api}api/faq/${group_title}`)
      .then(response => {
        // Adding an "isOpen" property to each FAQ item for collapse/expand functionality
        const faqsWithOpenState = response.data.map(faq => ({ ...faq, isOpen: false }));
        setFaqs(faqsWithOpenState);
      })
      .catch(error => console.error('Error fetching FAQs', error));
  }, [group_title]);


  return (
    <div className="faqList-container " >
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question-section">
            <div className={`faq-question ${faq.showAnswer ? "rotate" : ""}`}
              onClick={() => {
                // Toggle the display of the answer when the question is clicked
                const updatedFaqs = [...faqs];
                updatedFaqs[index].showAnswer = !updatedFaqs[index].showAnswer;
                setFaqs(updatedFaqs);
              }}
            >
              {faq.question}
                <FontAwesomeIcon icon={faAngleDown} className={`faq-arrow ${faq.showAnswer ? "rotate" : ""}`}
                />
            </div>
          </div>
          {faq.showAnswer && <div className={`faq-answer ${faq.showAnswer ? "open" : ""}`}>
            {faq.answer}
          </div>}
        </div>
      ))}
    </div>
  )

}
export default FaqList;