import React, { useState,useContext } from "react";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate, Link } from "react-router-dom";
import "./password.css"
import { NotificationContext } from "../../components/Navbar/NotificationContext";
import { addTokenHeader } from '../../utility/tokenUtility'

const ResetPassword = () => {
  let navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
  const [checkOldPassword, setCheckOldPassword] = useState("");
  const {addNotification } = useContext(NotificationContext);
  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);
  const user_id = decoded["user_id"];
  const email = decoded["email"];
  const api = process.env.REACT_APP_API_URL

  const handlePasswordChanges = (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setPasswordChangeMessage("Passwords do not match. Try again!");
      // navigate("/resetPassword");
    } else if (!newPassword || !confirmNewPassword) {
      setPasswordChangeMessage("Invalid password!");
    } else {
      setPasswordChangeMessage("");

      Axios.put(
        `${api}updatePassword`,
        {
          user_id: user_id,
          newPassword: newPassword,
          email: email,
          checkOldPassword: checkOldPassword,
        },
        {
          ...addTokenHeader()
        }
      )
        .then((response) => {
          if (response.data.message === "Invalid password") {
            setPasswordChangeMessage("Invalid password!");
          } else if (response.data.message === "Update successful!") {
            alert("The password has been updated!");
            navigate("dashboard/profile");
           // Add notification 
            addNotification({
              message: "The password has been updated.",
            });
          } else {
            alert("Unauthorized");
            navigate("dashboard/profile");
          }
        })
        .catch((error) => {
          console.log(error);
          alert("password not working.");
        });
    }
  };
  return (
    <div className="forgot-password-container" id="main-resetpassword">
      <div>
        <form
          action=""
          className="forgotpasswordpage"
          onSubmit={handlePasswordChanges}
        >
          <div className="forgot-password-form">
            <label className="label2">Existing Password:</label>
            <div>
              <input
                type="password"
                name="password"
                placeholder="Enter your existing password"
                id="password"
                autoComplete="off"
                className="forgot-input"
                value={checkOldPassword}
                onChange={(e) => setCheckOldPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="forgot-password-form">
            <label className="label2">New Password:</label>
            <div>
              <input
                type="password"
                name="password"
                placeholder="Enter new password"
                id="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}"
                title="NOTE : Password must contain at least one number, one uppercase and lowercase letter, and a minimum of 8 characters."
                autoComplete="off"
                className="forgot-input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <p className="passWord" id="pass-info">
              Password should contain atleast 8 characters, one Uppercase, one
              Lowercase, one Number and one Special character.
            </p>
          </div>
          <div className="forgot-password-form">
            <label className="label2">Confirm New Password:</label>
            <div>
              <input
                type="password"
                name="password"
                placeholder="Confirm new password"
                id="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}"
                title="NOTE : Password must contain at least one number, one uppercase and lowercase letter, and a minimum of 8 characters."
                autoComplete="off"
                className="forgot-input"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div id="error-password">
            <div>
              <h5>{passwordChangeMessage}</h5>
            </div>
            <div>
              <button
                className="reset-btn"
                id="btn-resetpassword"
                type="submit"
                value="submit"
              >
                Save
              </button>
              <Link to="/Settings">
              <p id="cancel-resetpassword">Cancel</p>
            </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
