import React from 'react'
import {  useState, useEffect } from 'react'
import { fetchMyPlanUserInfo } from './fetchMyPlanUserInfoHooks'
import "../../pages/style/Pricing.css"
import Plan from './Plan'
// import plans from '../../data/plansData'; // Import the plan data
import axios from 'axios'







function Updateplan() {

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [myPlanActive, setMyPlanActive] = useState("")
    const user_id = localStorage.getItem("user_id");
    const [plans, setPlans] = useState([]);
    const api = process.env.REACT_APP_API_URL;

    useEffect(() => {
    // Fetch plan data from the API
    axios.get(`${api}api/plan`)
        .then(response => {
        setPlans(response.data);
        }
        )
        .catch(error => {
        console.error('Error fetching plan data:', error);
        });
    }, []);
    



    useEffect(() => {
        // Call the API myplan 
        if (user_id){
        fetchMyPlanUserInfo(user_id)
        .then(data => {
            setMyPlanActive(data.active ? data.active : "" )
            // console.log (data)
        
        })
        .catch(error => {
            // Handle the error
            console.error(error);
            });
        }
        }, []);
    
    

    const handlePlanClick = (id) => {
        setSelectedPlan(id);
    
        };


    return (
    <>
        <div className='pricing-header'>
        <div className='pricing-header-text'>
            <p> We believe in making Skillur accessible to all.<br/>
            Our plan comes with the fantastic benefit of being <b>completely free!</b></p>
        </div>
        <div className='pricing-mainplan'>
        {plans?.map(plan => (

            plan.active === 1 ? 
            <Plan 
            key={plan.plan_id}
            id={plan.plan_id}
            title={plan.plan_title}
            price={plan.price}
            validityDuration = {plan.duration_in_numbers}
            validityName = {plan.duration_in_words}
            amount={plan.amount}
            image={plan.image_name}
            description={plan?.description}
            currency={plan.currency}
            banner={plan.banner}
            isSelected={selectedPlan === plan.plan_id}
            handleClick={() => handlePlanClick(plan.plan_id)}
            myPlanActive={myPlanActive}
            />
            : null
        ))}
        
        </div>
        </div>
    </>
    )
}

export default Updateplan