import React, { useEffect } from "react";
import "../stripe/CheckoutForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addTokenHeader } from '../utility/tokenUtility'


function ThanksCardPayment() {
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API_URL;
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");

    if (success === "true") {
      const planId = urlParams.get("planId");
      const title = urlParams.get("title");
      const price = urlParams.get("price");
      const email = urlParams.get("email");
      const validityDuration = Number(urlParams.get("validityDuration"));
      const validityName = urlParams.get("validityName");
      const amount = urlParams.get("amount");
      const paymentIntentId = urlParams.get("payment_intent");

      // Call sendData function with the retrieved data
      fetchUserInfo().then(({ firstName, lastName }) => {
        sendData(
          planId,
          title,
          email,
          price,
          firstName,
          lastName,
          validityDuration,
          validityName,
          amount,
          paymentIntentId
        );
      });
    }

    const redirectTimeout = setTimeout(() => {
      navigate("/"); // Replace with the actual home page path
    }, 4000);

    return () => clearTimeout(redirectTimeout); // Clear the timeout when the component unmounts
  }, []);

  async function fetchUserInfo() {
    try {
      const response = await axios.get(`${api}api/datauserinfo`, { params: { user_id: user_id },...addTokenHeader() });

      const userInfoData = response.data; // Access the data from the response

      const firstName = userInfoData.first_name;
      const lastName = userInfoData.last_name;

      return { firstName, lastName };
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }

  const sendData = async (
    planId,
    title,
    email,
    price,
    firstName,
    lastName,
    validityDuration,
    validityName,
    amount,
    paymentIntentId
  ) => {
    try {
      // Call your API to send data

      await axios
        .post(`${api}confirmPayment`, {
          user_id: user_id,
          email: email,
          first_name: firstName,
          last_name: lastName,
          planId: planId,
          title: title,
          price: price,
          validityDuration: validityDuration,
          validityName: validityName,
          amount: amount,
          paymentIntentId: paymentIntentId,
        },{...addTokenHeader()})
        .then((Response) => {
          if (Response.data) {
          }
        });

      // Handle the response if needed
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <>
      <div className="container-ThanksCardPayment">
        <div className="payment-text">
          <h2>
            <span>Thank you</span> for Subscribing with us. Invoice has been
            sent to your Email.
          </h2>
        </div>
      </div>
    </>
  );
}

export default ThanksCardPayment;
