import React from 'react'
import "./PromotionCode.css"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";

function PromotionCode() {


  return (
   
      <div className="popup-PromotionCode">
        <div className="popup-inner-PromotionCode">
          <div>
            <h2>
            
            Promotion Code{' '} 
            <FontAwesomeIcon icon={faGift} />
            </h2>
            <div>
              <div className="text-PromotionCode">
                <p className="boldText">
                Enter Your Promo Code
                </p>
              </div>
              <input type='text'></input>
            </div>
            <div className="ButtonsRight-PromotionCode">
              <Link to="/Settings">
                <p className="CancelButton">Cancel</p>
              </Link>
              <button type="submit" value="submit">
               activate
              </button>
            </div>
          </div>
        </div>
      </div>
    
  )
}

export default PromotionCode