import React, { useState,useContext} from "react";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import "./password.css"
import { NotificationContext } from "../../components/Navbar/NotificationContext";
function RetrievePassword() {
  let navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
  const api = process.env.REACT_APP_API_URL
  const location = useLocation()
  localStorage.setItem("user_id" ,location.state?.user_id)
  const user_id = localStorage.getItem("user_id")
  const { addNotification } = useContext(NotificationContext);

  const handlePasswordChanges = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setPasswordChangeMessage("Passwords do not match. Try again!");
      navigate("/RetrievePassword");
    } else if (!newPassword || !confirmNewPassword) {
      setPasswordChangeMessage(
        "Something is wrong. Please check the password you have entered."
      );
      navigate("/RetrievePassword");
    } else {
      setPasswordChangeMessage("");

      Axios.put(`${api}retrievePassword`, {
        user_id: user_id,
        newPassword: newPassword,
      })
        .then((response) => {
          if (response) {
            alert("Password has been updated!");
              // Add notification
        addNotification({
          message: "Password changed successfully. Your account is now updated.",
        });
            localStorage.clear();
            navigate("/loginform");
          } else {
            alert("Unauthorized");
            navigate("/");
          }
        })
        .catch((error) => {
          alert("Unauthorized");
          navigate("/");
        });
    }
  };

  return (
    <div className="retrive-password" >
    <div className='forgot-password-container' id="retrive-container">
      <div>
        <div className='logo-img'>
            <img src={logo} alt='logo of Skillur' className='skillur-logo-img' />
        </div>
        <form className='forgotpasswordpage' onSubmit={handlePasswordChanges}>
          <div className='forgot-password-form'>
            <label className='label2'>New Password:</label>
            <div>
              <input
                type='password'
                name='password'
                placeholder='Enter new password'
                id='password'
                pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}'
                title='NOTE : Password must contain at least one number, one uppercase and lowercase letter, and a minimum of 8 characters.'
                autoComplete='off'
                className='forgot-input'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <p className="passWord" id="pass-info">
                  Password should contain atleast 8 characters, one Uppercase,
                  one Lowercase, one Number and one Special character.
                </p>
          </div>
          <div className='forgot-password-form'>
            <label className='label2'>Confirm New Password:</label>
            <div>
              <input
                type='password'
                name='password'
                placeholder='Confirm new password'
                id='password'
                pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}'
                title='NOTE : Password must contain at least one number, one uppercase and lowercase letter, and a minimum of 8 characters.'
                autoComplete='off'
                className='forgot-input'
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div id='error-password'>
            <div>
              <h5>{passwordChangeMessage}</h5>
            </div>
            <div>
              <button className='reset-btn' id="btn-resetpassword" type='submit' value='submit'>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
}
export default RetrievePassword;
