import React from 'react'
import '../pages/style/About.css'
import skillur_intro from '../videos/skillur_intro_60sek.mp4'

export default function About() {
  return (
    <>
    <div>
      <h1 id='main-text'>About Skillur</h1>
      <div className='about'>
      <div className='about-text'>
      <p>
      Unlock your competence's potential in a new scene. Draw inspiration and showcase it when the time is right. 
      Your actions are driven by your own talent management, ensuring lifelong returns on your skills – we call it RoC, Return on Competence. 
      Our platform offers a space for your personal growth.
      </p>
      <p>
      Moreover, we empower you to market your skills locally or globally, with conditions set by you. We break down the barriers to your talents.
      </p>
      <p>
      Many face periods where their skills aren't recognized. Whether it's due to behaviour, structure, or biases, 
      we're committed to dismantling these obstacles. Backed by research and analysis, we guide all individuals. 
      Receive early warnings, gain insights, and take impactful actions.
      </p>
      </div>
      <div className='about-video'>
        <h1 id='main-text'>Find your rewarding path with <span>Skillur.</span></h1>
        <video className='skillur-intro-video' controls  >
        <source src={skillur_intro} type="video/mp4" />
        </video>
      </div>
      </div>
    </div>
    <div className='about-landing'>
      {/* <div className='about-landing-text'>
        <ul>
          <li>The steps are:</li>
          <li>You complete our Competence Map which is adapted to your situation.</li>
          <li>Skillur creates your Competence Profile and transforms it into a pdf document. </li>
          <li>You will receive an email where your Competence Profile is attached and free to use.</li>
          <li>Skillur delivers updates on your specific profile and competence in general.</li>
          <li>When Skillur activates more tools, you will receive a notification about it.</li>
        </ul>
      </div> */}
      </div>
    </>
  )
}
