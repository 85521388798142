import React from 'react';
import '../Competence-Pages/LandingPage/LandingPage.css';
import CompetenciesBlue from "../../images/Icons_Landingpage/Competencies_blue_landingPage.svg";
import MyTermsBlue from "../../images/Icons_Landingpage/My_terms_blue_landingPage.svg";
import ReferencesBlue from "../../images/Icons_Landingpage/References_blue_landingPage.svg";
import PersonalityBlue from "../../images/Icons_Landingpage/Personality_blue_landingPage.svg";
import ProfileBlue from "../../images/Icons_Landingpage/Profile_blue_landingPage.svg";
import TheFutureBlue from "../../images/Icons_Landingpage/The_future_blue_landingPage.svg";
import ValidationBlue from "../../images/Icons_Landingpage/Validation_blue_landingPage.svg";
import VideoBlue from "../../images/Icons_Landingpage/Video_blue_landingPage.svg";
import CollaborationBlue from "../../images/Icons_Landingpage/Collaboration_room_blue_landingPage.svg";
import {  Outlet} from 'react-router-dom'


const CompetenceIconsLandingPage = () => {
  return (
    <div className='comptenciesIcon-header'>
      <div className='competence-sub' >
    <div className='title-competence'>
      <h1>Competence Profile</h1>
    </div>
    <div className='iconsFadedContainer'>
    <img src={CompetenciesBlue} alt=""/>
    <img src={MyTermsBlue} alt="" />
    <img src={ReferencesBlue} alt=""/>
    <img src={PersonalityBlue} alt=""/>
    <img src={ProfileBlue} alt=""/>
    <img src={TheFutureBlue} alt=""/>
    <img src={ValidationBlue} alt=""/>
    <img src={VideoBlue} alt=""/>
    <img src={CollaborationBlue} alt=""/>
    </div>
    </div>
    <Outlet/>
  </div>
  )
}

export default CompetenceIconsLandingPage;