
import React from 'react'
import './CompetenceIcons.css'
import {ReactComponent as Competencies} from '../../images/iconsDashboard/Competencies_blue.svg'
import {ReactComponent as CollaborationRoom} from '../../images/iconsDashboard/Collaboration_room_blue.svg'
import {ReactComponent as Future} from '../../images/iconsDashboard/The_future_blue.svg'
import {ReactComponent as Myterms} from '../../images/iconsDashboard/My_terms_blue.svg'
import {ReactComponent as Personality} from '../../images/iconsDashboard/Personality_blue.svg'
import {ReactComponent as Profile} from '../../images/iconsDashboard/Profile_blue.svg'
import {ReactComponent as References} from '../../images/iconsDashboard/References_blue.svg'
import {ReactComponent as Validation} from '../../images/iconsDashboard/Validation_blue.svg'
import {ReactComponent as Video} from '../../images/iconsDashboard/Video_blue.svg'
import { Link, Outlet} from 'react-router-dom'
import { useState, useEffect,useLayoutEffect } from "react"
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const CompetenceIcons = () => {

  const location = useLocation();
  const { id } = useParams(); // Retrieve the color ID from the URL parameters
  const [svgOrange, setSvgOrange] = useState(null);
  const [svgdef,setSvgdef] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const first_login = localStorage.getItem('fLogin')
  const pageToIconMap = {
    '/dashboard/competencies': 1,
    '/dashboard/myTerms': 2,
    '/dashboard/references': 3,
    '/dashboard/personality': 4,
    '/dashboard/profile': 5,
    '/dashboard/theFuture': 6,
    '/dashboard/validation': 7,
    '/dashboard/video': 8,
    '/dashboard/collaborationRoom': 9
  };
  
/////to change color
  function handleClick(id) {
    setSvgOrange(id);
    setSvgdef(false)
    localStorage.setItem('selectedIcon', id); // Store the selected icon ID in local storage
  }
  useEffect(() => {
    const storedIcon = localStorage.getItem('selectedIcon');
    if (storedIcon) {
      setSvgOrange(parseInt(storedIcon));
      setSvgdef(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      setSvgOrange(parseInt(id));
      setSvgdef(false);
      localStorage.setItem('selectedIcon', id); // Update the selected icon ID in local storage
    } else {
      const iconId = pageToIconMap[location.pathname];
      if (iconId) {
      setSvgOrange(iconId);
      setSvgdef(false);
    }
    }
  }, [id, location.pathname]);

  
  
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth <= 769);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (isMobile) {
     // if the user is on mobile, navigate to /dashboard
    if (location.pathname === "/dashboard/profile") {
      if(first_login === "true"){
        navigate("/dashboard/landingpage");
      } else {
        navigate("/dashboard");
      }
    }

    } else {
    // if the user is on desktop, navigate to /dashboard/profile
    if (location.pathname === "/dashboard") {
      if(first_login === "true"){
        navigate("/dashboard/landingpage");
      } else {
        navigate("/dashboard/profile");
      }
    }
    }
    
    }, [isMobile, navigator]);

  return (
    <div>
        <div className='comptenciesIcon-header'>
        <div className='competence-sub'>
        <div className='title-competence'>
        <h1>Competence Profile</h1>
        </div>
        <div className='icons-flex-container'>
            <div className='comptenciesIcon'>
              <Link to = "competencies"><Competencies className={svgOrange === 1 ? 'svg-icon' : ''} onClick={() => handleClick(1)} /></Link> 
            </div>
            <div className='comptenciesIcon'>
              <Link to ="myTerms"> <Myterms  className={svgOrange === 2 ? 'svg-icon' : ''} onClick={() => handleClick(2)}/> </Link> 
            </div>
            <div className='comptenciesIcon'>
              <Link to ="references">  <References  className={svgOrange === 3 ? 'svg-icon' : ''} onClick={() => handleClick(3)}/> </Link>
            </div>
            <div className= 'comptenciesIcon'>
              <Link to ="personality">  <Personality  className={svgOrange === 4 ? 'svg-icon' : ''} onClick={() => handleClick(4)}/> </Link> 
            </div>
            <div className='comptenciesIcon'>
            <Link to ="profile">  <Profile className={svgOrange === 5 || svgdef ? 'svg-icon' : ''} onClick={() => handleClick(5)}/> </Link>  
            </div>
            <div className= 'comptenciesIcon'>
              <Link to ="theFuture"> <Future className={svgOrange === 6 ? 'svg-icon' : ''} onClick={() => handleClick(6)}/> </Link> 
            </div>
            <div className= 'comptenciesIcon'>
              <Link to ="validation"> <Validation className={svgOrange === 7 ? 'svg-icon' : ''} onClick={() => handleClick(7)}/> </Link> 
            </div>
            <div className= 'comptenciesIcon'>
              <Link to ="video">  <Video className={svgOrange === 8 ? 'svg-icon' : ''} onClick={() => handleClick(8)}/> </Link>  
            </div> 
            <div className= 'comptenciesIcon'>
              <Link to ="collaborationRoom"><CollaborationRoom className={svgOrange === 9 ? 'svg-icon' : ''} onClick={() => handleClick(9)}/> </Link>
            </div>
        </div>
      </div>
      <Outlet/>
      </div>
    </div>
  )
}

export default CompetenceIcons
