import React from 'react'

function CollaborationRoom() {
  return (
    <div className='main-container'>
      <div className='container-text'>
        <h1> Collaboration Room</h1>
        <p>The Collaboration Room section will be available in the coming months.</p>
      </div>
      
      </div>
  )
}

export default CollaborationRoom