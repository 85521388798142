import React from 'react'
import { Link } from 'react-router-dom'
import "./style/OurValues.css"

function OurValues() {
  return (
    <div>
    <h1 id='main-text'>Our values</h1>
    <div className='values'>
      <div className='values-text'>
      <p>Skillur is a platform that focuses on promoting competence and skills irrespective of age, gender, 
        or cultural background. The emphasis on valuing skills over external factors promotes inclusivity.
      </p>

      <p>At Skillur, users are called Competence Owners (CO). Skillur is committed to data privacy and CO control. 
        CO’s data being owned by them and the ability to choose what information goes public is crucial in today's digital age.
        </p>

        <p>
        Allocating competence globally in an efficient manner aligns well with the current trends in remote work and the global economy. 
        Connecting skilled individuals worldwide can lead to innovative collaborations and a more efficient use of expertise.
        </p>

        <p>
        Our commitment to contributing to sustainability through a competence-focused lens and alignment with the UN Sustainable Development Goals (SDGs) demonstrates a broader social responsibility, 
        indicating a forward-thinking and socially conscious approach to business.
        </p>
      
        {/* <Link to="/sustainability"><button className='btn-blue'>Read more</button></Link>  */}
      </div>
      
    </div>
    </div>
  )
}

export default OurValues