import React, { useState } from 'react';
import './TagsInput.css';
import tickmark from '../../../images/imagesCompetence/Tick mark.svg'

const TagsInput = ({ value, onChange, name, placeHolder, disabled }) => {
  const [tagInput, setTagInput] = useState('');

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    if (tagInput.trim() !== '') {
      const newValue = value ? [...value, tagInput.trim()] : [tagInput.trim()];
      onChange(newValue);
      setTagInput('');
    }
  };

  const removeTag = (tag) => {
    const newValue = value.filter((item) => item !== tag);
    onChange(newValue);
  };

  const handleSave = () => {
    // Perform your save action here, like saving the tags to the server or updating state.
    addTag();
  };

  return (
    <div className="tags-input-container">
      {value && value.map((tag, index) => (
        <span key={index} className="tag">
          <span className="tag-text">{tag}</span>
          <button onClick={() => removeTag(tag)} className="tag-remove-button" disabled={disabled}>
            x
          </button>
        </span>
      ))}
      <div className='tag-input-div'>
      <input
        type="text"
        value={tagInput}
        onChange={handleTagInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeHolder}
        disabled={disabled}
        className="tags-input" // Apply the input class
      />
      <img src={tickmark} onClick={handleSave} className="save-button-tags" disabled={disabled} alt='' />
      </div> 
      
    </div>
  );
};

export default TagsInput;
