import React, { useEffect, useState } from "react";
import Axios from "axios";
import Select from "react-select";
import InformModal from "./InformModal";
import AlertConfirmModal from "./AlertConfirmModal";
import ProofSection from "./ProofSection";
import "./Competencies.css";

import tickIcon from "./../../../images/imagesCompetence/Tick mark.svg";
import xIcon from "./../../../images/imagesCompetence/X mark.svg";
import upArrow from "./../../../images/imagesCompetence/upArrow.svg";
import downArrow from "./../../../images/imagesCompetence/downArrow.svg";
import Plus from "./../../../images/imagesCompetence/Plus.svg";
import Minus from "./../../../images/imagesCompetence/Minus.svg";
import ToggleOn from "./../../../images/imagesCompetence/Toggle.svg";
import ToggleOff from "./../../../images/imagesCompetence/ToggleOff.svg";
import Delete from "./../../../images/imagesCompetence/Delete.svg";
import HelpButton from "../../HelpButton/HelpButton";
import doubleDown from "./../../../images/imagesCompetence/Double-down.svg";
import doubleRightOrange from "./../../../images/imagesCompetence/Double-right-orange.svg";
import { addTokenHeader } from "../../../utility/tokenUtility";

const Competencies = () => {
  const [compoptions, setCompoptions] = useState([]);
  const [langoptions, setLangoptions] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState([]);

  const [skills, setSkills] = useState([]);
  const [selectedCompetences, setSelectedCompetences] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [allCompetences, setAllCompetences] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [allLicenses, setAllLicenses] = useState([]);
  const proof_default = {
    description: "",
    description2: "",
    validation_option: "",
    experience_months: 0,
    experience_years: 0,
    last_work_year: new Date(),
    doc1: { doc_name: "", doc_public_id: "", doc_url: "" },
    doc2: { doc_name: "", doc_public_id: "", doc_url: "" },
  };

  const [expandDesc, setExpandDesc] = useState({
    sec_index: -1,
    comp_index: -1,
    proof_index: -1,
  });
  const [showSaveBtn, setShowSaveBtn] = useState({
    Competences: false,
    Languages: false,
    Licenses: false,
  });
  const [compProof, setCompProof] = useState(proof_default);
  const [isOpenProof, setIsOpenProof] = useState(false);
  const [currentSection, setCurrentSection] = useState("None");
  const [selectedRole, setSelectedRole] = useState("");
  const [addToList, setAddToList] = useState(false);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const [competenceQuestions, setcompetenceQuestions] = useState([]);
  const [answer, setAnswer] = useState("");

  const user_id = localStorage.getItem("user_id");
  const space = <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  const api = process.env.REACT_APP_API_URL;

  ///Inform us Modal
  const [showModal, setShowModal] = useState(false);
  const [showAlertConfirmModal, setShowAlertConfirmModal] = useState(false);
  const [modalObj, setModalObj] = useState({});

  // Pagination for Competence options
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(500); // Adjust the items per page as needed
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const licenseOptionsList = [
    { value: "No License", label: "No License" },
    { value: "Motorcycle", label: "Motorcycle" },
    { value: "Passenger vehicle", label: "Passenger vehicle" },
    { value: "Large goods vehicle", label: "Large goods vehicle" },
    { value: "Bus", label: "Bus" },
    {
      value: "International driving permit",
      label: "International driving permit",
    },
  ];

  // Define an array of allowed file extensions
  const allowedFileExtensions = [
    "pdf",
    "doc",
    "docx",
    "jpg",
    "txt",
    "png",
    "pptx",
  ];
  const RESOURCE_TYPES = [
    "image",
    "raw",
    "raw",
    "image",
    "raw",
    "image",
    "raw",
  ];

  const empty_array = [];

  const modalTextArray = [
    {
      module: "toggle",
      title: "Show/Hide Competences",
      button1: "Yes",
      button2: "Cancel",
      text: "If you choose to hide the competence,\nit will not be listed in your Competence Profile.\n\nAre you sure you want to proceed?\n\n(Note: Hidden competence will be listed at the bottom of the list)",
      param1: "",
      param2: "",
    },
    {
      module: "deletedenial",
      title: "Can not be deleted",
      button1: "OK",
      button2: "",
      text: "Selected competence has attached descriptions/proofs\n\nDelete the descriptions/proofs first\n and then delete the competence",
      param1: "",
    },
    {
      module: "deleteconfirm",
      title: "Delete Confirmation",
      button1: "Yes",
      button2: "Cancel",
      text: "Are you sure to delete this competence?\nThis can not be undone!",
      param1: "",
      param2: "",
    },
    {
      module: "deleteconfirmproof",
      title: "Delete Confirmation",
      button1: "Yes",
      button2: "Cancel",
      text: "Are you sure to delete this proof description?\nThis can not be undone!",
      param1: "",
      param2: "",
      param3: "",
    },
  ];

  const fetchCompetences = async () => {
    try {
      const response = await Axios.get(`${api}api/getcompetence`, {
        params: { user_id: user_id },
        ...addTokenHeader(),
      });
      let tempState = response.data.respMessage;
      tempState.forEach((eachItem) => {
        eachItem.proof = eachItem.proof;
        if (eachItem.proof[0] === null) {
          eachItem.proof = empty_array;
        }
      });
      setAllCompetences(tempState);
    } catch (error) {
      console.log("Failed to fetch competences", error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await Axios.get(`${api}api/getlanguage`, {
        params: { user_id: user_id },
        ...addTokenHeader(),
      });
      let tempState = response.data.respMessage;
      tempState.forEach((eachItem) => {
        eachItem.proof = eachItem.proof;
        if (eachItem.proof[0] === null) {
          eachItem.proof = empty_array;
        }
      });
      setAllLanguages(tempState);
    } catch (error) {
      console.log("Failed to fetch languages", error);
    }
  };

  const fetchLicenses = async () => {
    try {
      const response = await Axios.get(`${api}api/getlicense`, {
        params: { user_id: user_id },
        ...addTokenHeader(),
      });
      let tempState = response.data.respMessage;
      tempState.forEach((eachItem) => {
        eachItem.proof = eachItem.proof;
        if (eachItem.proof[0] === null) {
          eachItem.proof = empty_array;
        }
      });
      setAllLicenses(response.data.respMessage);
    } catch (error) {
      console.log("Failed to fetch licenses", error);
    }
  };

  // Get languages list from the taxonomy table 'languages'
  const fillLanguageOptions = async (event) => {
    setSelectionChanged(true);

    Axios.get(
      `${api}api/getlanguageoptions`,
      { ...addTokenHeader() },
      langoptions
    ).then((response) => {
      if (response.data.respMessage) {
        let txmyLangs = response.data.respMessage;

        // remove languages that are in toRemove array that has the single column "label" from languages list
        let toremove = allLanguages.map((item) => {
          return item.txmy_skill_link_id;
        });
        let tempArray = txmyLangs.filter(
          (item) => !toremove.includes(item.label)
        );
        setLangoptions(tempArray);
      }
    });
  };

  // Filter License list
  const filterLicenseOptions = async (event) => {
    setSelectionChanged(true);
    // remove licenses that are in toRemove array that has the single column "label" from licenses list
    if (allLicenses.length > 0) {
      let toremove = allLicenses.map((item) => {
        return item.txmy_skill_link_id;
      });
      let tempArray = licenseOptionsList.filter(
        (item) =>
          !(toremove.includes(item.label) || item.label === "No License")
      );
      setLicenseOptions(tempArray);
    } else {
      setLicenseOptions(licenseOptionsList);
    }
  };

  const fillOptions = async (page, searchParam) => {
    try {
      const response = await Axios.get(
        `${api}api/getcompetencetxmy?page=${page}&pageSize=${itemsPerPage}&searchText=${searchParam}`,
        { ...addTokenHeader() },
        compoptions
      );
      if (page === 1 || searchParam !== "") {
        setCompoptions(response.data.respMessage);
      } else {
        setCompoptions((prevOptions) => [
          ...prevOptions,
          ...response.data.respMessage,
        ]);
      }
      setTotalItems(response.data.totalItems); // Update the total number of items
    } catch (error) {
      console.log("Failed to fetch competence list", error);
    }
  };

  useEffect(
    () => {
      fillOptions(currentPage, searchValue); // function call to populate the competence dropdown
    },
    [currentPage],
    [searchValue]
  );

  useEffect(() => {
    fetchCompetences(); // Function call to load the competences for the user
    fetchLanguages(); // Function call to load the languages for the user
    fetchLicenses(); // Function call to load the licenses for the user
  }, []);

  useEffect(
    () => {
      // this hook will get called every time states has changed
    },
    [allCompetences],
    [allLanguages],
    [allLicenses],
    [langoptions],
    [compProof],
    [skills]
  );

  const handleSearchValue = (event) => {
    setSearchValue(event);
    loadFilteredPage(event);
  };

  const loadFilteredPage = async (searchValue1) => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }

    setIsLoading(true);
    fillOptions(currentPage, searchValue1)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load page", error);
        setIsLoading(false);
      });
  };

  const handleScrollDown = () => {
    loadNextPage();
  };

  const handleScrollUp = () => {
    //      loadPrevPage();
  };

  const loadNextPage = () => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }

    setIsLoading(true);
    const nextPage = currentPage + 1;
    fillOptions(nextPage, searchValue)
      .then(() => {
        setCurrentPage(nextPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load next page", error);
        setIsLoading(false);
      });
  };

  const loadPrevPage = () => {
    if (isLoading || currentPage * itemsPerPage >= totalItems) {
      return;
    }

    setIsLoading(true);
    const nextPage = currentPage - 1;
    fillOptions(nextPage, searchValue)
      .then(() => {
        setCurrentPage(nextPage);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Failed to load previous page", error);
        setIsLoading(false);
      });
  };

  // Get the choices of subcategories from txmy_skill_link table
  const listSubCategories = async (event) => {
    setSelectionChanged(true);
    setSelectedRole(event);

    Axios.get(
      `${api}api/getskillstxmy`,
      { params: event, ...addTokenHeader() },
      skills
    )
      .then((response) => {
        setSkills(response.data.respMessage);
      })
      .catch((error) => {
        setSelectionChanged(false);
        console.log(error);
      });
  };

  // Save competence details in table 'competence'
  const saveCompetence = (event) => {
    let count = allCompetences.filter((temp) => temp.new_position < 999).length;
    Axios.post(
      `${api}api/insertcompdetails`,
      {
        params: {
          user_id: user_id,
          section: "Competences",
          complist: selectedCompetences,
          existing_count: count,
        },
      },
      { ...addTokenHeader() }
    )
      .then((response) => {
        console.log("post Save Competence call", response.data);
        if (response.data === "Competences details saved") {
          setAddToList(true);
          setSelectionChanged(true);
          setSelectedCompetences([]);
          fetchCompetences();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentSection("None");
  };

  // Save language details in table 'competence'
  const saveLanguage = (event) => {
    let count = allLanguages.filter((temp) => temp.new_position < 999).length;
    Axios.post(
      `${api}api/insertcompdetails`,
      {
        params: {
          user_id: user_id,
          section: "Languages",
          complist: selectedLanguages,
          existing_count: count,
        },
      },
      { ...addTokenHeader() }
    )
      .then((response) => {
        console.log("Post Save Language call", response.data);
        if (response.data === "Languages details saved") {
          fetchLanguages(); //Refresh list
          setAddToList(true);
          setSelectionChanged(true);
          setCurrentSection("None");
          setSelectedLanguages([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Save license details in table 'competence'
  const saveLicense = (event) => {
    let count = allLicenses.filter((temp) => temp.new_position < 999).length;
    if (
      [
        ...allLicenses.filter(
          (temp) => temp.new_position !== temp.show_position
        ),
      ].length > 0
    ) {
      updateCompPreferences("Licenses", allLicenses);
    }
    Axios.post(
      `${api}api/insertcompdetails`,
      {
        params: {
          user_id: user_id,
          section: "Licenses",
          complist: selectedLicenses,
          existing_count: count,
        },
      },
      { ...addTokenHeader() }
    )
      .then((response) => {
        console.log("Post Save License call", response.data);
        if (response.data === "Licenses details saved") {
          fetchLicenses(); //Refresh list
          setAddToList(true);
          setSelectionChanged(true);
          setCurrentSection("None");
          setSelectedLicenses([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add the selected main and sub competences to the selected list
  const getNext = (event) => {
    let newState = [];
    const skillsSubset = [...skills.filter((skill) => skill.checked)];

    setAddToList(true);
    setSelectionChanged(false);
    selectedCompetences.push(selectedRole);
    if (skillsSubset.length > 0) {
      selectedCompetences.push(...skillsSubset);
    }

    // Remove duplicates in the selection
    newState = [
      ...new Map(
        selectedCompetences.map((temp) => [temp.label.toLowerCase(), temp])
      ).values(),
    ];

    // remove items that are already saved in the database
    setSelectedCompetences([
      ...newState.filter(
        (x) =>
          ![...allCompetences.filter((y) => y.title_eng === x.label)].length
      ),
    ]);

    setSelectedRole("");
    setSkills([]);
  };

  const resetCompetence = (event) => {
    setSelectedRole("");
    setSkills([]);
    setCurrentSection("None");
    setAddToList(false);
    setSelectedCompetences([]);
  };

  const resetLanguage = (event) => {
    setCurrentSection("None");
    setAddToList(true);
    setSelectionChanged(false);
    setSelectedLanguages([]);
  };

  const resetLicense = (event) => {
    setCurrentSection("None");
    setAddToList(true);
    setSelectionChanged(false);
    setSelectedLicenses([]);
  };

  const revertLicense = (event) => {
    let resetState = [...allLicenses];
    resetState
      .filter((temp) => temp.new_position !== temp.show_position)
      .forEach((item, i) => {
        item.new_position = item.show_position;
      });
    setAllLicenses([...resetState]);
    resetLicense();
  };

  const updateCompProofList = (competenceId, compProofObj) => {
    // Create a new copy of the data array
    const newData = allCompetences.map((item) =>
      item.competence_id === competenceId
        ? { ...item, proof: [...item.proof, compProofObj], expand_proof: true }
        : item
    );

    // Update the state with the new data
    setAllCompetences(newData);
  };

  const updateLangProofList = (competenceId, compProofObj) => {
    // Create a new copy of the data array
    const newData = allLanguages.map((item) =>
      item.competence_id === competenceId
        ? { ...item, proof: [...item.proof, compProofObj], expand_proof: true }
        : item
    );

    // Update the state with the new data
    setAllLanguages(newData);
  };

  const updateLicenProofList = (competenceId, compProofObj) => {
    // Create a new copy of the data array
    const newData = allLicenses.map((item) =>
      item.competence_id === competenceId
        ? { ...item, proof: [...item.proof, compProofObj], expand_proof: true }
        : item
    );

    // Update the state with the new data
    setAllLicenses(newData);
  };

  const resetProof = (event) => {
    setCompProof(proof_default);
    setIsOpenProof(false);
    setExpandDesc({
      sec_index: -1,
      comp_index: -1,
      proof_index: -1,
    });
  };

  // Callback function to receive the answer from the Proof section component
  const handleAnswer = (proofAnswer, Section, currItem, currIndex) => {
    setAnswer(proofAnswer.answer);
    setCompProof(proofAnswer.objProof);
    switch (proofAnswer.answer) {
      case "Saved":
        switch (Section) {
          case "Competences":
            updateCompProofList(currItem.competence_id, proofAnswer.objProof);
            break;
          case "Languages":
            updateLangProofList(currItem.competence_id, proofAnswer.objProof);
            break;
          case "Licenses":
            updateLicenProofList(currItem.competence_id, proofAnswer.objProof);
            break;
          default:
            break;
        }
        resetProof();
        break;
      case "Updated":
        switch (Section) {
          case "Competences":
            fetchCompetences();
            break;
          case "Languages":
            fetchLanguages();
            break;
          case "Licenses":
            fetchLicenses();
            break;
          default:
            break;
        }
        resetProof();
        break;
      case "Cancel":
        switch (Section) {
          case "Competences":
            const newData1 = allCompetences.map((item) => ({
              ...item,
              expand_proof: true,
            }));
            setAllCompetences(newData1);
            break;
          case "Languages":
            const newData2 = allLanguages.map((item) => ({
              ...item,
              expand_proof: true,
            }));
            setAllLanguages(newData2);
            break;
          case "Licenses":
            const newData3 = allLicenses.map((item) => ({
              ...item,
              expand_proof: true,
            }));
            setAllLicenses(newData3);
            break;
          default:
            break;
        }
        resetProof();
        break;
      default:
        break;
    }
  };

  // event handler for language dropdown
  const handleLanguage = (event) => {
    setSelectedLanguages(event);
  };

  // event handler for license dropdown
  const handleLicense = async (event) => {
    if (event.length > 1) {
      let tempSelection = [
        ...event.filter((item) => item.label !== "No License"),
      ];
      setSelectedLicenses(tempSelection);
      let tempOptions = [
        ...licenseOptions.filter((item) => item.label !== "No License"),
      ];
      setLicenseOptions(tempOptions);
    } else if (event.length === 1) {
      let tempOptions = [
        ...licenseOptions.filter((item) => item.label !== "No License"),
      ];
      setLicenseOptions(tempOptions);
      setSelectedLicenses(event);
    } else if (event.length === 0) {
      setSelectedLicenses(event);
      setLicenseOptions(licenseOptionsList);
    }
    if (allLicenses.length + event.length > 1 && allLicenses.length > 0) {
      let newState = [...allLicenses];
      let index = newState.findIndex(
        (item) => item.txmy_skill_link_id === "No License"
      );
      if (index >= 0 && newState[index].new_position !== 1000) {
        newState[index].new_position = 1000;
        newState
          .filter((temp) => temp.new_position < 999)
          .sort((a, b) => a.new_position - b.new_position)
          .forEach((item, i) => {
            item.new_position = i;
          });
        setAllLicenses([...newState]);
      }
    }
  };

  // event handler for subcategory checkboxes
  const handleChecked = (event) => {
    setSkills(
      skills.map((skill) =>
        skill.value === event.target.value
          ? { ...skill, checked: event.target.checked }
          : skill
      )
    );
  };

  const chkOrderChanged = (chkSection, listToCheck) => {
    let tempState;
    let count = [
      ...listToCheck.filter((temp) => temp.new_position !== temp.show_position),
    ].length;
    if (count !== 0) {
      tempState = { ...showSaveBtn, [[chkSection]]: true };
    } else {
      tempState = { ...showSaveBtn, [[chkSection]]: false };
    }
    setShowSaveBtn(tempState);
  };

  const toggleShowHide = (Section, index) => () => {
    let newState = [];
    switch (Section) {
      case "Competences":
        newState = [...allCompetences];
        break;
      case "Languages":
        newState = [...allLanguages];
        break;
      case "Licenses":
        newState = [...allLicenses];
        break;
      default:
        break;
    }
    let count = newState.filter((temp) => temp.new_position < 999).length;

    if (newState[index].new_position < 999) {
      newState[index].new_position = 999;
      newState
        .filter((temp) => temp.new_position < 999)
        .sort((a, b) => a.new_position - b.new_position)
        .forEach((item, i) => {
          item.new_position = i;
        });
    } else if (newState[index].new_position === 999) {
      newState[index].new_position = count;
    }

    chkOrderChanged(Section, newState);

    switch (Section) {
      case "Competences":
        setAllCompetences(
          newState.sort((a, b) => a.new_position - b.new_position)
        );
        resetCompetence();
        break;
      case "Languages":
        setAllLanguages(
          newState.sort((a, b) => a.new_position - b.new_position)
        );
        resetLanguage();
        break;
      case "Licenses":
        setAllLicenses(
          newState.sort((a, b) => a.new_position - b.new_position)
        );
        resetLicense();
        break;
      default:
        break;
    }
  };

  const resetExpandProof = (resetSection) => {
    if (resetSection !== "Competences") {
      const newData1 = allCompetences.map((item) => ({
        ...item,
        expand_proof: true,
      }));
      setAllCompetences(newData1);
    }
    if (resetSection !== "Languages") {
      const newData2 = allLanguages.map((item) => ({
        ...item,
        expand_proof: true,
      }));
      setAllLanguages(newData2);
    }
    if (resetSection !== "Licenses") {
      const newData3 = allLicenses.map((item) => ({
        ...item,
        expand_proof: true,
      }));
      setAllLicenses(newData3);
    }
    setIsOpenProof(false);
  };

  // After add proof (+) button click
  const addProof = (Section, index) => () => {
    resetExpandProof(Section);
    let newState = [];
    switch (Section) {
      case "Competences":
        resetCompetence();
        resetProof();
        newState = [...allCompetences];
        newState.forEach((item, i) => {
          if (i === index) {
            item.expand_proof = !item.expand_proof;
            if (!item.expand_proof) {
              setIsOpenProof(true);
            }
          } else {
            item.expand_proof = true;
          }
        });
        setAllCompetences(newState);
        break;
      case "Languages":
        resetLanguage();
        resetProof();
        newState = [...allLanguages];
        newState.forEach((item, i) => {
          if (i === index) {
            item.expand_proof = !item.expand_proof;
            if (!item.expand_proof) {
              setIsOpenProof(true);
            }
          } else {
            item.expand_proof = true;
          }
        });
        setAllLanguages(newState);
        break;
      case "Licenses":
        resetLicense();
        resetProof();
        newState = [...allLicenses];
        newState.forEach((item, i) => {
          if (i === index) {
            item.expand_proof = !item.expand_proof;
            if (!item.expand_proof) {
              setIsOpenProof(true);
            }
          } else {
            item.expand_proof = true;
          }
        });
        setAllLicenses(newState);
        break;
      default:
        break;
    }
  };

  // Interchange two items in a list and change new_position for each item
  const swapItems = (listToAlter, index1, index2) => {
    [listToAlter[index1], listToAlter[index2]] = [
      listToAlter[index2],
      listToAlter[index1],
    ];
    listToAlter[index1].new_position = index1;
    listToAlter[index2].new_position = index2;
    return listToAlter;
  };

  // After up arrow button click
  // swap values at found index and index - 1
  const moveUp = (Section, index) => async (event) => {
    if (index > 0) {
      let newState = []; /* temp for comp/lang/licen list */

      switch (Section) {
        case "Competences":
          if (
            allCompetences[index].new_position < 999 &&
            allCompetences[index - 1].new_position < 999
          ) {
            newState = swapItems([...allCompetences], index, index - 1);
            setAllCompetences(newState);
          }
          break;

        case "Languages":
          if (
            allLanguages[index].new_position < 999 &&
            allLanguages[index - 1].new_position < 999
          ) {
            newState = swapItems([...allLanguages], index, index - 1);
            setAllLanguages(newState);
          }
          break;

        case "Licenses":
          if (
            allLicenses[index].new_position < 999 &&
            allLicenses[index - 1].new_position < 999
          ) {
            newState = swapItems([...allLicenses], index, index - 1);
            setAllLicenses(newState);
          }
          break;

        default:
          break;
      }
      chkOrderChanged(Section, newState);
    }
  };

  // After down arrow button click
  // swap values at found index and index + 1
  const moveDown = (Section, index) => async (event) => {
    let newState = [];

    switch (Section) {
      case "Competences":
        if (
          index < allCompetences.length - 1 &&
          allCompetences[index].new_position < 999 &&
          allCompetences[index + 1].new_position < 999
        ) {
          newState = [...allCompetences];
          newState = swapItems(newState, index, index + 1);
          setAllCompetences(newState);
          chkOrderChanged(Section, newState);
        }
        break;

      case "Languages":
        if (
          index < allLanguages.length - 1 &&
          allLanguages[index].new_position < 999 &&
          allLanguages[index + 1].new_position < 999
        ) {
          newState = [...allLanguages];
          newState = swapItems(newState, index, index + 1);
          setAllLanguages(newState);
          chkOrderChanged(Section, newState);
        }
        break;

      case "Licenses":
        if (
          index < allLicenses.length - 1 &&
          allLicenses[index].new_position < 999 &&
          allLicenses[index + 1].new_position < 999
        ) {
          newState = [...allLicenses];
          newState = swapItems(newState, index, index + 1);
          setAllLicenses(newState);
          chkOrderChanged(Section, newState);
        }
        break;

      default:
        break;
    }
  };

  const deleteItem = (Section, index) => async () => {
    let newState = [];
    switch (Section) {
      case "Competences":
        newState = [...allCompetences];
        break;
      case "Languages":
        newState = [...allLanguages];
        break;
      case "Licenses":
        newState = [...allLicenses];
        break;
      default:
        break;
    }
    if (deleteCompByID(Section, newState[index].competence_id) === "Failure") {
      return;
    }
    newState = newState.filter((item, itemIndex) => itemIndex !== index);

    switch (Section) {
      case "Competences":
        setAllCompetences(newState);
        break;
      case "Languages":
        setAllLanguages(newState);
        break;
      case "Licenses":
        if (
          newState.length === 1 &&
          newState[0].txmy_skill_link_id === "No License"
        ) {
          newState[0].new_position = 0;
          updateCompPreferences("Licenses", newState);
          newState[0].show_position = 0;
        }
        setAllLicenses([...newState]);
        break;
      default:
        break;
    }
  };

  const updateCompPreferences = (Section, listToUpdate) => {
    Axios.post(
      `${api}api/updatecompdetails`,
      {
        params: { complist: listToUpdate },
      },
      { ...addTokenHeader() }
    )
      .then((response) => {
        console.log("post Update Competence Preferences call");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCompByID = (Section, competence_id) => {
    /* Delete competence details */
    Axios.post(`${api}api/deletecompdetails`, {
      params: { section: Section, competence_id: competence_id },
    })
      .then((response) => {
        console.log("post Delete Competence details call");
        return "Success";
      })
      .catch((error) => {
        console.log(error);
        return "Failure";
      });
  };

  const deleteInCloud = (publicId, file_name) => {
    //// delete document from cloudinary
    //// delete document from cloudinary
    let file_type =
      RESOURCE_TYPES[allowedFileExtensions.indexOf(file_name.split(".").pop())];

    Axios.post(`${api}api/deleteCloudDocs`, { publicId, file_type })
      .then((response) => {
        console.log(
          "Deleted document in cloudinary with the Public ID ",
          publicId
        );
        return "Success";
      })
      .catch((error) => {
        return "Failure";
      });
  };

  const deleteProofByID = async (proofToDelete) => {
    /* Delete proof details and documents associated with the competence */
    if (
      proofToDelete?.doc1?.doc_public_id !== "" &&
      proofToDelete?.doc1?.doc_public_id !== null
    ) {
      if (
        deleteInCloud(
          proofToDelete.doc1.doc_public_id,
          proofToDelete.doc1.doc_name
        ) === "Failure"
      ) {
        return;
      }
    }
    if (
      proofToDelete.doc2.doc_public_id !== "" &&
      proofToDelete.doc2.doc_public_id !== null
    ) {
      if (
        deleteInCloud(
          proofToDelete.doc2.doc_public_id,
          proofToDelete.doc2.doc_name
        ) === "Failure"
      ) {
        return;
      }
    }

    await Axios.post(`${api}api/deleteproofdetails`, {
      params: { proof_id: proofToDelete.proof_id },
    })
      .then((response) => {
        resetProof();
        console.log("post Delete Proof details call", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshCompProofList = (competenceId, proofId) => {
    setAllCompetences((prevList) =>
      prevList.map((item) => {
        if (item.competence_id === competenceId) {
          const updatedProof = item.proof.filter(
            (proofItem) => proofItem.proof_id !== proofId
          );
          return { ...item, proof: updatedProof };
        }
        return item;
      })
    );
  };

  const refreshLangProofList = (competenceId, proofId) => {
    setAllLanguages((prevList) =>
      prevList.map((item) => {
        if (item.competence_id === competenceId) {
          const updatedProof = item.proof.filter(
            (proofItem) => proofItem.proof_id !== proofId
          );
          return { ...item, proof: updatedProof };
        }
        return item;
      })
    );
  };

  const refreshLicenProofList = (competenceId, proofId) => {
    setAllLicenses((prevList) =>
      prevList.map((item) => {
        if (item.competence_id === competenceId) {
          const updatedProof = item.proof.filter(
            (proofItem) => proofItem.proof_id !== proofId
          );
          return { ...item, proof: updatedProof };
        }
        return item;
      })
    );
  };

  // When 'Save Preferences' button is clicked save the visibility and priority preferences
  const btnSaveClick = (Section, listToUpdate) => async (event) => {
    updateCompPreferences(Section, listToUpdate); // Function call to update competence table
    listToUpdate.forEach((eachCompetence) => {
      if (eachCompetence.show_position !== eachCompetence.new_position) {
        eachCompetence.show_position = eachCompetence.new_position;
      }
    });

    let tempState;
    switch (Section) {
      case "Competences":
        setAllCompetences(listToUpdate);
        tempState = { ...showSaveBtn, Competences: false };
        break;
      case "Languages":
        setAllLanguages(listToUpdate);
        tempState = { ...showSaveBtn, Languages: false };
        break;
      case "Licenses":
        setAllLicenses(listToUpdate);
        tempState = { ...showSaveBtn, Licenses: false };
        break;
      default:
        break;
    }
    setShowSaveBtn(tempState);
  };

  // When 'Attach Proof'button is clicked, open modal for attaching files
  const getSubstring = (ip_string) => {
    return ip_string === null ? null : ip_string.substring(0, 40);
  };

  // After form submit stay in same page
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      // width: "300px",
      minHeight: "1px",
      // height: "28px",
      textAlign: "left",
      border: "none",
      lineHeight: "normal",
    }),
    control: (provided) => ({
      ...provided,
      alignItems: "self-start",
      border: "1px solid #D4D4D4",
      borderRadius: "5px",
      minHeight: "1px",
      height: "28px",
      color: "#D4D4D4",
      margin: "5px",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingTop: "0",
      paddingBottom: "0",
      color: "#D4D4D4",
      padding: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // width: "27px",
      transition: "none",
      height: "25px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: "0px",
      transition: "none",
      width: "20px",
      height: "20px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: "1px",
      marginTop: "3px",
      height: "21px",
      transition: "none",
    }),
    Svg: (provided) => ({
      ...provided,
      width: "10px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      // minHeight: "1px",
      // height: "25px",
      paddingLeft: "5px",
      paddingTop: "0",
      paddingBottom: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingBottom: "1px",
      color: "#808080",
      marginLeft: "0px",
    }),
  };

  const customStyles2 = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      // width: "300px",
      // minHeight: "20px",
      // height: "25px",
      textAlign: "left",
      border: "none",
      lineHeight: "normal",
    }),
    control: (provided) => ({
      ...provided,
      alignItems: "self-start",
      border: "1px solid #D4D4D4",
      borderRadius: "5px",
      minHeight: "25px",
      // height: "28px",
      color: "#D4D4D4",
      margin: "5px",
      // fontSize:"12px"
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      transition: "none",
      padding: "0",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#D4D4D4",
      padding: "5px",
    }),
  };

  const addCompetence = (event) => {
    setSelectionChanged(false);
    setAddToList(true);
    setCurrentSection("AddCompetences");
  };

  const addLanguage = (event) => {
    setSelectionChanged(true);
    setAddToList(true);
    setCurrentSection("AddLanguages");
    fillLanguageOptions();
  };

  const addLicense = (event) => {
    setSelectionChanged(true);
    setAddToList(true);
    setCurrentSection("AddLicenses");
    filterLicenseOptions();
  };
  /** Getting video belpbutton data from backend **/
  useEffect(() => {
    const help_title = "competence";
    Axios.get(`${api}api/questions/${help_title}`, { ...addTokenHeader() })
      .then((response) => {
        setcompetenceQuestions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleInformClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="main-container">
        <div className="competence">
          <h2>Competence</h2>
        </div>

        <form className="form-competenceDetails" onSubmit={handleSubmit}>
          <div>
            {/* Header */}
            <div className="selected-list-container">
              <span className="competence-name">
                Your current competence list
              </span>
              <span className="span-small">
                Proof{space}Show{space}Priority
              </span>
            </div>

            {/* Selected Competences section*/}
            <div className="selected-list-container">
              Selected Competences
              <span>
                <button
                  className="button-add"
                  onClick={addCompetence}
                  disabled={currentSection === "AddCompetences" ? true : false}
                >
                  Add
                </button>
              </span>
              {/* Add Competences modal*/}
              {currentSection === "AddCompetences" && (
                <div className="add-container">
                  <div className="text-competence">
                    <label className="label-competence">
                      Select Competence
                    </label>
                    <div className="container-group">
                      <Select
                        id="role1"
                        styles={customStyles}
                        value={selectedRole}
                        onChange={listSubCategories}
                        options={compoptions}
                        placeholder="Enter your search"
                        onInputChange={handleSearchValue}
                        onMenuScrollToBottom={handleScrollDown}
                        noOptionsMessage={() =>
                          "Please check spelling or try alternate competences!"
                        }
                        //                        onMenuScrollToTop={handleScrollUp}
                        isMulti={false}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#F7942C",
                            primary: "#003399",
                          },
                        })}
                      />
                      <div className="container-TickX">
                        <button
                          className="button-icon"
                          onClick={getNext}
                          style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
                        >
                          <img src={tickIcon} alt="Next" />
                        </button>
                        <button
                          className="button-icon"
                          /*                           style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
 */
                        >
                          <img
                            src={xIcon}
                            onClick={resetCompetence}
                            alt="Cancel"
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className={
                        selectedRole === ""
                          ? "inform-modal"
                          : "inform-modal-inactive"
                      }
                      disabled={selectedRole !== ""}
                      onClick={handleInformClick}
                    >
                      If your can't find your competence in the list, <br />
                      please click here to inform us!
                    </button>
                    {showModal && (
                      <InformModal
                        showModal={showModal}
                        closeModal={() => setShowModal(false)}
                      />
                    )}
                  </div>

                  {{ selectionChanged: true } && (
                    <div className="text-competence-sub">
                      {skills.map((eachSkill, itemno) => (
                        <div className="container-checkbox" key={itemno}>
                          <input
                            className="checkbox-label"
                            type="checkbox"
                            value={eachSkill?.value}
                            checked={eachSkill?.checked}
                            onChange={handleChecked}
                          />
                          <label className="checkbox-label">
                            {eachSkill?.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* Selected Competences list*/}
              {addToList && selectedCompetences.length > 0 && (
                <div className="font-orange">
                  <div className="added-competence-title">
                    Added Competence(s)
                  </div>

                  {selectedCompetences.map((eachCompetence, index) => (
                    <div
                      className="competence-name selected-list-item"
                      key={index}
                    >
                      {eachCompetence?.label}
                    </div>
                  ))}

                  <button
                    style={{ visibility: "visible" }}
                    className="cancel-save-button"
                    onClick={saveCompetence}
                    id="save-orange-button-c"
                  >
                    Save
                  </button>
                  <button
                    className="cancel-save-button"
                    onClick={resetCompetence}
                  >
                    Cancel
                  </button>
                </div>
              )}
              <div className="selected-items-container">
                {allCompetences
                  .filter((item) => item.new_position >= 0)
                  .map((eachCompetence, index) => (
                    <div className="selected-list-item" key={index}>
                      <span className="competence-name">
                        {eachCompetence?.title_eng}
                      </span>
                      <button
                        className="button-icon1"
                        disabled={expandDesc.proof_index < 0 ? false : true}
                        onClick={addProof("Competences", index)}
                      >
                        <img
                          src={eachCompetence?.expand_proof ? Plus : Minus}
                          alt="Add Proof"
                        />
                      </button>

                      <button
                        className="button-icon1"
                        onClick={() => {
                          if (eachCompetence?.new_position !== 999) {
                            setModalObj({
                              ...modalTextArray[
                                modalTextArray.findIndex(
                                  (item) => item.module === "toggle"
                                )
                              ],
                              param1: "Competences",
                              param2: index,
                            });
                            setShowAlertConfirmModal(true);
                          } else {
                            toggleShowHide("Competences", index)();
                          }
                        }}
                      >
                        <img
                          src={
                            eachCompetence?.new_position === 999
                              ? ToggleOff
                              : ToggleOn
                          }
                          alt="Show/Hide"
                        />
                      </button>

                      {eachCompetence?.new_position !== 999 && (
                        <button className="button-icon2">
                          <img
                            src={upArrow}
                            alt="Move up"
                            onClick={moveUp("Competences", index)}
                          />
                          <img
                            src={downArrow}
                            alt="Move down"
                            onClick={moveDown("Competences", index)}
                          />
                        </button>
                      )}
                      {eachCompetence?.show_position === 999 &&
                        eachCompetence?.new_position === 999 && (
                          <button
                            className="button-delete"
                            onClick={() => {
                              if (eachCompetence?.proof.length > 0) {
                                setModalObj(
                                  modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deletedenial"
                                    )
                                  ]
                                );
                                setShowAlertConfirmModal(true);
                              } else {
                                setModalObj({
                                  ...modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deleteconfirm"
                                    )
                                  ],
                                  param1: "Competences",
                                  param2: index,
                                });
                                setShowAlertConfirmModal(true);
                              }
                            }}
                          >
                            <img src={Delete} alt="Delete" />
                          </button>
                        )}

                      {eachCompetence?.proof.length > 0 &&
                        eachCompetence?.proof.map((eachProof, ind) => (
                          <div key={ind}>
                            {!(
                              expandDesc.sec_index === 1 &&
                              expandDesc.comp_index === index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0
                            ) && (
                              <button
                                className="button-expand"
                                disabled={isOpenProof}
                                onClick={() => {
                                  setCompProof(eachProof);
                                  setExpandDesc({
                                    sec_index: -1,
                                    comp_index: -1,
                                    proof_index: -1,
                                  });
                                  setExpandDesc({
                                    sec_index: 1,
                                    comp_index: index,
                                    proof_index: ind,
                                  });
                                }}
                              >
                                <img src={doubleDown} alt="Show Details" />
                              </button>
                            )}
                            {expandDesc.sec_index === 1 &&
                              expandDesc.comp_index === index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0 && (
                                <button
                                  className="button-expand"
                                  onClick={() => {
                                    setCompProof(proof_default);
                                    setExpandDesc({
                                      sec_index: -1,
                                      comp_index: -1,
                                      proof_index: -1,
                                    });
                                  }}
                                >
                                  <img
                                    src={doubleRightOrange}
                                    alt="Show Details"
                                  />
                                </button>
                              )}
                            <span className="competence-proof-list">
                              {getSubstring(eachProof?.description)}
                            </span>
                            <span>
                              {eachProof?.description !== "" &&
                                eachProof?.description !== null && (
                                  <button
                                    className="button-delete"
                                    onClick={() => {
                                      setModalObj({
                                        ...modalTextArray[
                                          modalTextArray.findIndex(
                                            (item) =>
                                              item.module ===
                                              "deleteconfirmproof"
                                          )
                                        ],
                                        param1: eachProof,
                                        param2: eachCompetence?.competence_id,
                                        param3: "Competences",
                                      });
                                      setShowAlertConfirmModal(true);
                                    }}
                                  >
                                    <img src={Delete} alt="Delete" />
                                  </button>
                                )}
                            </span>
                          </div>
                        ))}
                      {(!eachCompetence?.expand_proof ||
                        (expandDesc.sec_index === 1 &&
                          index === expandDesc.comp_index &&
                          expandDesc.proof_index >= 0)) && (
                        <ProofSection
                          competence_id={eachCompetence?.competence_id}
                          compProofIP={compProof}
                          onAnswer={(proofAnswer) =>
                            handleAnswer(
                              proofAnswer,
                              "Competences",
                              eachCompetence,
                              index
                            )
                          }
                        />
                      )}
                    </div>
                  ))}

                {showSaveBtn.Competences && (
                  <div className="container-btn-save">
                    <button
                      className="btn-save"
                      onClick={btnSaveClick("Competences", allCompetences)}
                    >
                      Save<br></br> Preferences
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Selected Languages section */}
            <div className="selected-list-container">
              Selected Language(s)
              <span>
                <button
                  className="button-add"
                  onClick={addLanguage}
                  disabled={currentSection === "AddLanguages" ? true : false}
                >
                  Add
                </button>
              </span>
              {/* Add Languages Section */}
              {currentSection === "AddLanguages" && (
                <div className="add-container">
                  <div className="text-competence">
                    <label className="label-competence">
                      Select language - minimum one
                    </label>
                    <div className="container-group">
                      <Select
                        options={langoptions}
                        onChange={handleLanguage}
                        id="role"
                        styles={customStyles2}
                        placeholder="Enter language you can handle when working"
                        isMulti={true}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#F7942C",
                            primary: "#003399",
                          },
                        })}
                      />
                      <div className="container-TickX">
                        <button
                          className="button-icon"
                          onClick={saveLanguage}
                          style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
                        >
                          <img src={tickIcon} alt="Next" />
                        </button>
                        <button
                          className="button-icon"
                          style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
                        >
                          <img
                            src={xIcon}
                            onClick={resetLanguage}
                            alt="Cancel"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Selected Languages List */}
              <div className="selected-items-container">
                {allLanguages
                  .filter((item) => item.new_position >= 0)
                  .map((eachLanguage, index) => (
                    <div className="selected-list-item" key={index}>
                      <span className="competence-name">
                        {eachLanguage.txmy_skill_link_id}
                      </span>
                      <button
                        className="button-icon1"
                        disabled={expandDesc.proof_index < 0 ? false : true}
                        onClick={addProof("Languages", index)}
                      >
                        <img
                          src={eachLanguage?.expand_proof ? Plus : Minus}
                          alt="Add Proof"
                        />
                      </button>

                      <button
                        className="button-icon1"
                        onClick={() => {
                          if (eachLanguage?.new_position !== 999) {
                            setModalObj({
                              ...modalTextArray[
                                modalTextArray.findIndex(
                                  (item) => item.module === "toggle"
                                )
                              ],
                              param1: "Languages",
                              param2: index,
                            });
                            setShowAlertConfirmModal(true);
                          } else {
                            toggleShowHide("Languages", index)();
                          }
                        }}
                      >
                        <img
                          src={
                            eachLanguage?.new_position === 999
                              ? ToggleOff
                              : ToggleOn
                          }
                          alt="Show/Hide"
                        />
                      </button>
                      {eachLanguage?.new_position < 999 && (
                        <button className="button-icon2">
                          <img
                            src={upArrow}
                            alt="Move up"
                            onClick={moveUp("Languages", index)}
                          />
                          <img
                            src={downArrow}
                            alt="Move down"
                            onClick={moveDown("Languages", index)}
                          />
                        </button>
                      )}
                      {eachLanguage?.show_position === 999 &&
                        eachLanguage?.new_position === 999 && (
                          <button
                            className="button-delete"
                            onClick={() => {
                              if (eachLanguage?.proof.length > 0) {
                                setModalObj(
                                  modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deletedenial"
                                    )
                                  ]
                                );
                                setShowAlertConfirmModal(true);
                              } else {
                                setModalObj({
                                  ...modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deleteconfirm"
                                    )
                                  ],
                                  param1: "Languages",
                                  param2: index,
                                });
                                setShowAlertConfirmModal(true);
                              }
                            }}
                          >
                            <img src={Delete} alt="Delete" />
                          </button>
                        )}

                      {eachLanguage?.proof.length > 0 &&
                        eachLanguage?.proof.map((eachProof, ind) => (
                          <div key={ind}>
                            {!(
                              expandDesc.sec_index === 2 &&
                              expandDesc.comp_index === index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0
                            ) && (
                              <button
                                className="button-expand"
                                disabled={isOpenProof}
                                onClick={() => {
                                  setCompProof(eachProof);
                                  setExpandDesc({
                                    sec_index: -1,
                                    comp_index: -1,
                                    proof_index: -1,
                                  });
                                  setExpandDesc({
                                    sec_index: 2,
                                    comp_index: index,
                                    proof_index: ind,
                                  });
                                }}
                              >
                                <img src={doubleDown} alt="Show Details" />
                              </button>
                            )}
                            {expandDesc.sec_index === 2 &&
                              index === expandDesc.comp_index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0 && (
                                <button
                                  className="button-expand"
                                  onClick={() => {
                                    setCompProof(proof_default);
                                    setExpandDesc({
                                      sec_index: -1,
                                      comp_index: -1,
                                      proof_index: -1,
                                    });
                                  }}
                                >
                                  <img
                                    src={doubleRightOrange}
                                    alt="Show Details"
                                  />
                                </button>
                              )}
                            <span className="competence-proof-list">
                              {getSubstring(eachProof?.description)}
                            </span>
                            <span>
                              {eachProof?.description !== "" &&
                                eachProof?.description !== null && (
                                  <button
                                    className="button-delete"
                                    onClick={() => {
                                      setModalObj({
                                        ...modalTextArray[
                                          modalTextArray.findIndex(
                                            (item) =>
                                              item.module ===
                                              "deleteconfirmproof"
                                          )
                                        ],
                                        param1: eachProof,
                                        param2: eachLanguage?.competence_id,
                                        param3: "Languages",
                                      });
                                      setShowAlertConfirmModal(true);
                                    }}
                                  >
                                    <img src={Delete} alt="Delete" />
                                  </button>
                                )}
                            </span>
                          </div>
                        ))}

                      {(!eachLanguage?.expand_proof ||
                        (expandDesc.sec_index === 2 &&
                          index === expandDesc.comp_index &&
                          expandDesc.proof_index >= 0)) && (
                        <ProofSection
                          competence_id={eachLanguage?.competence_id}
                          compProofIP={compProof}
                          onAnswer={(proofAnswer) =>
                            handleAnswer(
                              proofAnswer,
                              "Languages",
                              eachLanguage,
                              index
                            )
                          }
                        />
                      )}
                    </div>
                  ))}

                {showSaveBtn.Languages && (
                  <div className="container-btn-save">
                    <button
                      className="btn-save"
                      onClick={btnSaveClick("Languages", allLanguages)}
                    >
                      Save<br></br> Preferences
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Selected Licenses section */}
            <div className="selected-list-container">
              Selected Driving License(s)
              <span>
                <button
                  className="button-add"
                  onClick={addLicense}
                  disabled={currentSection === "AddLicenses" ? true : false}
                >
                  Add
                </button>
              </span>
              {/* Add License Section */}
              {currentSection === "AddLicenses" && (
                <div className="add-container">
                  <div className="text-competence">
                    <label className="label-competence">
                      Select driving license
                    </label>
                    <div className="container-group">
                      <Select
                        options={licenseOptions}
                        onChange={handleLicense}
                        className="select-box"
                        id="role"
                        styles={customStyles2}
                        // placeholder="Enter driving licenses you hold"
                        value={selectedLicenses}
                        isMulti={true}
                        menuPlacement="top"
                      />
                      <div className="container-TickX">
                        <button
                          className="button-icon"
                          onClick={saveLicense}
                          style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
                        >
                          <img src={tickIcon} alt="Next" />
                        </button>
                        <button
                          className="button-icon"
                          style={{
                            visibility: selectionChanged ? "visible" : "hidden",
                          }}
                        >
                          <img
                            src={xIcon}
                            onClick={revertLicense}
                            alt="Cancel"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Selected Licenses List */}
              <div className="selected-items-container">
                {allLicenses
                  .filter((item) => item.new_position !== 1000)
                  .map((eachLicense, index) => (
                    <div className="selected-list-item" key={index}>
                      <span className="competence-name">
                        {eachLicense.txmy_skill_link_id}
                      </span>
                      <button
                        className="button-icon1"
                        disabled={expandDesc.proof_index < 0 ? false : true}
                        onClick={addProof("Licenses", index)}
                      >
                        <img
                          src={eachLicense?.expand_proof ? Plus : Minus}
                          alt="Add Proof"
                        />
                      </button>
                      <button
                        className="button-icon1"
                        onClick={() => {
                          if (eachLicense?.new_position !== 999) {
                            setModalObj({
                              ...modalTextArray[
                                modalTextArray.findIndex(
                                  (item) => item.module === "toggle"
                                )
                              ],
                              param1: "Licenses",
                              param2: index,
                            });
                            setShowAlertConfirmModal(true);
                          } else {
                            toggleShowHide("Licenses", index)();
                          }
                        }}
                      >
                        <img
                          src={
                            eachLicense?.new_position === 999
                              ? ToggleOff
                              : ToggleOn
                          }
                          alt="Show/Hide"
                        />
                      </button>
                      {eachLicense?.new_position < 999 && (
                        <button className="button-icon2">
                          <img
                            src={upArrow}
                            alt="Move up"
                            onClick={moveUp("Licenses", index)}
                          />
                          <img
                            src={downArrow}
                            alt="Move down"
                            onClick={moveDown("Licenses", index)}
                          />
                        </button>
                      )}
                      {eachLicense?.show_position === 999 &&
                        eachLicense?.new_position === 999 && (
                          <button
                            className="button-delete"
                            onClick={() => {
                              if (eachLicense?.proof.length > 0) {
                                setModalObj(
                                  modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deletedenial"
                                    )
                                  ]
                                );
                                setShowAlertConfirmModal(true);
                              } else {
                                setModalObj({
                                  ...modalTextArray[
                                    modalTextArray.findIndex(
                                      (item) => item.module === "deleteconfirm"
                                    )
                                  ],
                                  param1: "Licenses",
                                  param2: index,
                                });
                                setShowAlertConfirmModal(true);
                              }
                            }}
                          >
                            <img src={Delete} alt="Delete" />
                          </button>
                        )}
                      {eachLicense?.proof.length > 0 &&
                        eachLicense?.proof.map((eachProof, ind) => (
                          <div key={ind}>
                            {!(
                              expandDesc.sec_index === 3 &&
                              expandDesc.comp_index === index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0
                            ) && (
                              <button
                                className="button-expand"
                                disabled={isOpenProof}
                                onClick={() => {
                                  /*                                   if (compProof !== proof_default) {
                                    window.alert("Save your details before switching");
                                  } */
                                  setCompProof(eachProof);
                                  setExpandDesc({
                                    sec_index: -1,
                                    comp_index: -1,
                                    proof_index: -1,
                                  });
                                  setExpandDesc({
                                    sec_index: 3,
                                    comp_index: index,
                                    proof_index: ind,
                                  });
                                }}
                              >
                                <img src={doubleDown} alt="Show Details" />
                              </button>
                            )}
                            {expandDesc.sec_index === 3 &&
                              expandDesc.comp_index === index &&
                              expandDesc.proof_index === ind &&
                              expandDesc.proof_index >= 0 && (
                                <button
                                  className="button-expand"
                                  onClick={() => {
                                    setCompProof(proof_default);
                                    setExpandDesc({
                                      sec_index: -1,
                                      comp_index: -1,
                                      proof_index: -1,
                                    });
                                  }}
                                >
                                  <img
                                    src={doubleRightOrange}
                                    alt="Show Details"
                                  />
                                </button>
                              )}
                            <span className="competence-proof-list">
                              {getSubstring(eachProof?.description)}
                            </span>
                            <span>
                              {eachProof?.description !== "" &&
                                eachProof?.description !== null && (
                                  <button
                                    className="button-delete"
                                    onClick={() => {
                                      setModalObj({
                                        ...modalTextArray[
                                          modalTextArray.findIndex(
                                            (item) =>
                                              item.module ===
                                              "deleteconfirmproof"
                                          )
                                        ],
                                        param1: eachProof,
                                        param2: eachLicense?.competence_id,
                                        param3: "Licenses",
                                      });
                                      setShowAlertConfirmModal(true);
                                    }}
                                  >
                                    <img src={Delete} alt="Delete" />
                                  </button>
                                )}
                            </span>
                          </div>
                        ))}

                      {(!eachLicense?.expand_proof ||
                        (expandDesc.sec_index === 3 &&
                          index === expandDesc.comp_index &&
                          expandDesc.proof_index >= 0)) && (
                        <ProofSection
                          competence_id={eachLicense?.competence_id}
                          compProofIP={compProof}
                          onAnswer={(proofAnswer) =>
                            handleAnswer(
                              proofAnswer,
                              "Licenses",
                              eachLicense,
                              index
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                {showSaveBtn.Licenses && (
                  <div className="container-btn-save">
                    <button
                      className="btn-save"
                      onClick={btnSaveClick("Licenses", allLicenses)}
                    >
                      Save<br></br> Preferences
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <HelpButton questions={competenceQuestions} />
          </div>
          {showAlertConfirmModal && (
            <AlertConfirmModal
              messageObj={modalObj}
              onButton1={() => {
                switch (modalObj.module) {
                  case "toggle":
                    toggleShowHide(modalObj.param1, modalObj.param2)();
                    break;
                  case "deleteconfirm":
                    deleteItem(modalObj.param1, modalObj.param2)();
                    break;
                  case "deleteconfirmproof":
                    deleteProofByID(modalObj.param1);
                    switch (modalObj.param3) {
                      case "Competences":
                        refreshCompProofList(
                          modalObj.param2,
                          modalObj.param1.proof_id
                        );
                        break;
                      case "Languages":
                        refreshLangProofList(
                          modalObj.param2,
                          modalObj.param1.proof_id
                        );
                        break;
                      case "Licenses":
                        refreshLicenProofList(
                          modalObj.param2,
                          modalObj.param1.proof_id
                        );
                        break;
                      default:
                        break;
                    }
                    break;
                  default:
                    break;
                }
                setShowAlertConfirmModal(false);
                setModalObj({});
              }}
              onButton2={() => {
                setShowAlertConfirmModal(false);
                setModalObj({});
              }}
            />
          )}
        </form>
      </div>
    </>
  );
};

export default Competencies;
